export const images = {
  printer: {
    note: require('./Printer/Note.png'),
    checkIcon: require('./Printer/checkIcon.png'),
    square: require('./Printer/square.png'),
    doubleSquare: require('./Printer/doubleSquare.png'),
    squares: require('./Printer/squares.png'),
    pdf: require('./Printer/pdf.png'),
    templates: {
      cmf: require('./Printer/templates/no-cmf1.png'),
      cmf2: require('./Printer/templates/no-cmf2.png'),
      cmf3: require('./Printer/templates/no-cmf3.png'),
      novey: {
        special1: require('./Printer/templates/special1.png'),
        special2: require('./Printer/templates/special2.png'),
        regular1: require('./Printer/templates/no-regular-1.png'),
        regular2: require('./Printer/templates/no-regular-2.png'),
        regular3: require('./Printer/templates/no-regular-3.png'),
        offer1: require('./Printer/templates/no-offer1.png'),
        offer2: require('./Printer/templates/no-offer2.png'),
        offer3: require('./Printer/templates/no-offer3.png'),
        cmfOffer1: require('./Printer/templates/no-cmf-offer1.png'),
        cmfOffer2: require('./Printer/templates/no-cmf-offer2.png'),
        cmfOffer3: require('./Printer/templates/no-cmf-offer3.png'),
      },
      cochez: {
        special1: require('./Printer/templates/special1.png'),
        special2: require('./Printer/templates/special2.png'),
        regular1: require('./Printer/templates/co-regular1.png'),
        regular2: require('./Printer/templates/co-regular2.png'),
        regular3: require('./Printer/templates/co-regular3.png'),
        offer1: require('./Printer/templates/co-offer1.png'),
        offer2: require('./Printer/templates/co-offer2.png'),
        offer3: require('./Printer/templates/co-offer3.png'),
        cmfOffer1: require('./Printer/templates/co-cmf-offer1.png'),
        cmfOffer2: require('./Printer/templates/co-cmf-offer2.png'),
        cmfOffer3: require('./Printer/templates/co-cmf-offer3.png'),
        cmf1: require('./Printer/templates/co-cmf1.png'),
        cmf2: require('./Printer/templates/co-cmf2.png'),
        cmf3: require('./Printer/templates/co-cmf3.png'),
      },
    },
  },
}
