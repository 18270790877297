import React from 'react'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'

export const AddMoreTemplatesButton = ({ handleShow }) => {
  return (
    <button
      onClick={handleShow}
      className="printer-modal-content-option-plus template-terms-wrapper-to-remove">
      <AddCircleOutlinedIcon style={{ width: 30, height: 30 }} />
    </button>
  )
}
