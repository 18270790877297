import React from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { FormatMoney } from 'Utils/functions'
import { disclaimers } from 'Views/PrinterPreview/constants'

const DEFAULT_VALUE = disclaimers.regularCmf

export const Square1ToPrint = ({ products, i }) => {
  const { templateRef } = usePrinterContext()

  const prod = products?.content?.products?.[0]
  const terms = products?.content?.terms || DEFAULT_VALUE
  const prodBasePrice = FormatMoney(prod?.BasePrice || 0)

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        id="printer-preview-left-canvas"
        data-testid="printer-preview-left-canvas-1"
        className="printer-preview-left-canvas">
        <div className="printer-preview-left-content-body">
          <p className="printer-preview-left-content-body-title">
            {prod?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-sku">
            {prod?.Sku || ''}
          </p>
          {prod?.BasePrice && (
            <div className="printer-preview-left-content-body-price-wrapper">
              <p className="printer-preview-mini-text">$</p>
              <p className="printer-preview-left-content-body-price">
                {prodBasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p className="printer-preview-mini-text">
                {prodBasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div className="printer-preview-left-content-footer">
          <div className="printer-preview-left-content-footer-quotes">
            <p></p>
            <p className="printer-preview-left-content-footer-quote-text">
              36 <br /> cuotas
            </p>
            <p className="printer-preview-left-content-footer-quote-text">
              ${FormatMoney(prod?.cmfCredit || 0) || ''}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
            <p
              className="printer-preview-left-content-footer-legal-1"
              style={{ border: 'none', height: '30px' }}>
              {terms}
            </p>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.cmf}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
