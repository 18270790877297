import React, { useEffect } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { CircularProgress } from '@mui/material'

export const CustomSelector = ({
  placeholder,
  options,
  disabled = false,
  handleOption,
  selectedOption,
  styles = {},
  loading = false,
}) => {
  const subMenuRef = React.useRef()
  const [showOptions, setShowOptions] = React.useState(false)

  useEffect(() => {
    const handler = e => {
      if (!subMenuRef.current.contains(e.target)) setShowOptions(false)
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [])

  return (
    <button
      ref={subMenuRef}
      className="printer-option template-terms-wrapper-to-remove"
      disabled={loading || disabled}
      style={styles}
      onClick={() => setShowOptions(!showOptions)}>
      <p className="printer-option-text">
        {selectedOption.value || placeholder}
      </p>
      {loading && (
        <CircularProgress
          sx={{
            '& .MuiCircularProgress-svg': { color: '#003082' },
          }}
          size={16}
        />
      )}
      {!loading && !showOptions && (
        <KeyboardArrowDownIcon style={{ width: 30, height: 30 }} />
      )}
      {!loading && showOptions && (
        <KeyboardArrowUpIcon style={{ width: 30, height: 30 }} />
      )}
      {!loading && showOptions && (
        <div className="printer-options">
          {options.map(option => (
            <button
              key={option}
              className={`printer-options-value ${
                selectedOption?.value === option?.value ? 'selected' : ''
              }`}
              style={styles}
              disabled={selectedOption?.value === option?.value}
              onClick={() => handleOption(option)}>
              <p className="printer-option-text">{option.value}</p>
            </button>
          ))}
        </div>
      )}
    </button>
  )
}
