import React, { useState, useRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import './verticalNav.css'
import { ROUTES, elements } from '../../Utils/constants'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import CalculatorModal from 'Components/CalculatorModal'

function VerticalNav(props) {
  const location = useLocation()
  const [openCalc, setOpenCalc] = useState(false)
  const revealRefs = useRef([])

  revealRefs.current = []
  const isCustomer = useSelector(state => state.login.isCustomer)

  const handleModal = () => {
    setOpenCalc(!openCalc)
  }

  const textCheck = () => {
    if (props.isOpen) {
      revealRefs.current.map(element => {
        return (element.style.display = 'block')
      })
    } else {
      revealRefs.current.map(element => {
        return (element.style.display = 'none')
      })
    }
  }

  const addToRef = el => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  const handleMenuOpenItem = (e, item) => {
    props.setSelectedOption(item)
    props.setIsOpen(false)

    e.preventDefault()
    if (props.menuOpenItem.includes(item.title)) {
      props.setMenuOpenItem(props.menuOpenItem.filter(i => i !== item.title))
    } else {
      props.setMenuOpenItem([...props.menuOpenItem, item.title])
    }
  }

  useEffect(() => {
    textCheck()
  }, [props.isOpen])

  // get breakpoints frpm material ui

  return (
    <div data-testid="vertical-nav-wrapper">
      <Box
        className="vertical-navbar"
        component="nav"
        sx={{
          width: {
            xs: props.isOpen ? '200px' : '0px',
            sm: !props.isOpen ? '70px' : '320px',
          },
          display: { xs: props.isOpen ? 'block' : 'none', sm: 'block' },
        }}>
        <ul className="nav-icons-container">
          <Box
            display="flex"
            justifyContent={props.isOpen ? 'flex-end' : 'center'}
            onClick={props.handleDrawer}
            className="v-nav-icon">
            <FontAwesomeIcon
              className="sp open"
              icon={props.isOpen ? faAnglesLeft : faAnglesRight}
            />
          </Box>
          {elements.map(element => {
            if (isCustomer && element.hiddenByClient) return null
            return !element.isCalculator ? (
              <Link
                key={element.title}
                {...(element.link ? { to: element.link } : {})}
                className="v-nav-icon"
                {...(!element.link
                  ? { onClick: e => handleMenuOpenItem(e, element) }
                  : {})}
                style={{
                  backgroundColor:
                    element.link === location.pathname ||
                    (element.link?.pathname === ROUTES.createQuote &&
                      location.pathname === ROUTES.createQuote)
                      ? '#022767'
                      : 'transparent',
                }}>
                <li
                  className={
                    props.isOpen
                      ? 'v-nav-icon-content-open'
                      : 'v-nav-icon-content'
                  }>
                  <img
                    src={element.image}
                    alt="nav"
                    style={{
                      filter:
                        element.link === location.pathname ||
                        (element.link?.pathname === ROUTES.createQuote &&
                          location.pathname === ROUTES.createQuote)
                          ? 'invert(53%) sepia(86%) saturate(459%) hue-rotate(140deg) brightness(107%) contrast(80%)'
                          : 'grayscale(100%)',
                    }}
                  />
                  <p
                    className="link-text"
                    ref={addToRef}
                    style={{
                      color:
                        (element.link === location.pathname && '#21C5DF') ||
                        (element.link?.pathname === ROUTES.createQuote &&
                          location.pathname === ROUTES.createQuote &&
                          '#21C5DF'),
                    }}>
                    {element.title}
                  </p>
                  {element.title === 'Plantilla de precios' &&
                  props.isOpen &&
                  (props.menuOpenItem.length > 0 || props.isOpen) ? (
                    <p className="badge-color">Nuevo</p>
                  ) : null}
                  {element.title === 'Reportes' &&
                  (props.menuOpenItem.length > 0 || props.isOpen) ? (
                    <p
                      className="title-new"
                      style={{
                        color: openCalc && '#21C5DF',
                      }}>
                      Nuevo
                    </p>
                  ) : null}
                </li>
              </Link>
            ) : (
              <div
                key={element.title}
                to={element.link}
                className="v-nav-icon"
                style={{
                  backgroundColor: openCalc ? '#022767' : 'transparent',
                }}
                onClick={handleModal}>
                <li
                  className={
                    props.isOpen
                      ? 'v-nav-icon-content-open'
                      : 'v-nav-icon-content'
                  }>
                  <img
                    src={element.image}
                    alt="nav"
                    style={{
                      filter: openCalc
                        ? 'invert(53%) sepia(86%) saturate(459%) hue-rotate(140deg) brightness(107%) contrast(80%)'
                        : 'grayscale(100%)',
                    }}
                  />
                  <p
                    className="link-text"
                    ref={addToRef}
                    style={{
                      color: openCalc && '#21C5DF',
                    }}>
                    {element.title}
                  </p>
                </li>
              </div>
            )
          })}
        </ul>
      </Box>
      {props.menuOpenItem.includes(props.selectedOption.title) && (
        <Box
          className="vertical-navbar-submenu"
          component="nav"
          style={{ left: props.isOpen ? '300px' : '70px' }}
          sx={{
            width: {
              xs: props.isOpen ? '200px' : '200px',
              sm: props.isOpen ? props.drawerWidth : '320px',
            },
          }}>
          {elements.map(
            element =>
              element.children &&
              element.children.length > 0 && (
                <div key={element.title} className="v-nav-icon-submenu">
                  <li className="v-nav-icon-content-open">
                    <p className="link-text-submenu">{element.title}</p>
                  </li>
                  <hr className="line-separator"></hr>
                  <ul
                    className="v-nav-icon-children-submenu"
                    style={{
                      // check if the item is open and if is included in the props.menuOpenItem array
                      display: props.menuOpenItem.includes(element.title)
                        ? 'block'
                        : 'none',
                    }}>
                    {element.children.map(child => {
                      return (
                        <p
                          key={child.title}
                          className="v-nav-icon-submenu-children">
                          <li className="v-nav-icon-content-open-submenu">
                            <p className="link-text-submenu-children">
                              {child.title}
                            </p>
                            {child.grandChildren?.length > 0
                              ? child.grandChildren.map(grandChild => (
                                  <Link
                                    key={grandChild.title}
                                    to={grandChild.link}
                                    className="v-nav-icon-submenu-grandchildren"
                                    style={{
                                      backgroundColor:
                                        grandChild.link === location.pathname
                                          ? '#003082'
                                          : undefined,
                                    }}>
                                    <li className="v-nav-icon-content-open-submenu-grandchildren">
                                      <img
                                        src={grandChild.image}
                                        alt="nav"
                                        className={
                                          grandChild.link === location.pathname
                                            ? 'img-invert'
                                            : ''
                                        }
                                      />
                                      <p
                                        className="link-text-submenu-grandchildren"
                                        style={{
                                          color:
                                            grandChild.link ===
                                            location.pathname
                                              ? '#FFF'
                                              : undefined,
                                        }}>
                                        {grandChild.title}
                                      </p>
                                    </li>
                                  </Link>
                                ))
                              : null}
                          </li>
                        </p>
                      )
                    })}
                  </ul>
                </div>
              )
          )}
        </Box>
      )}
      <CalculatorModal
        openCalc={openCalc}
        handleModal={handleModal}
        isModal={true}
      />
    </div>
  )
}

export default VerticalNav
