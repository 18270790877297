import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { images } from 'Assets/Images'
import React, { useEffect } from 'react'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { useGetCmfCredit } from '../../../hooks/useGetCmfCredit'
import { FormatMoney } from 'Utils/functions'
import { TermsTextArea } from '../../TermsTextArea'

const defTermValue = date =>
  `La tarjeta digital CMF es emitida por CM Financiera, S.A. (CMF).  Accede a www.cmfinanciera.com para más información. La responsabilidad de CMF se limita a los aspectos del crédito. Promociones válidas del X al XX de X del 2024. No aplica con otras promociones ni descuentos, precios no incluyen ITBMS, promoción aplica para productos seleccionados pagando únicamente con la tarjeta digital CMF.`

export const Square2Special = ({ products, i }) => {
  const { terms } = usePrinterContext()

  const { fetchCmfCredit, loading } = useGetCmfCredit()

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const terms1 =
    terms?.find(term => term?.prodId === prod1?.ProductId)?.value ||
    defTermValue(prod1?.FinishDate)
  const terms2 =
    terms?.find(term => term?.prodId === prod2?.ProductId)?.value ||
    defTermValue(prod2?.FinishDate)
  const cmfCredit1 = FormatMoney(prod1?.cmfCredit || 0)
  const cmfCredit2 = FormatMoney(prod2?.cmfCredit || 0)
  const prodBasePrice1 = FormatMoney(prod1?.BasePrice || 0)
  const prodBasePrice2 = FormatMoney(prod2?.BasePrice || 0)
  const prodPrice1 = FormatMoney(prod1?.Price || 0)
  const prodPrice2 = FormatMoney(prod2?.Price || 0)

  useEffect(() => {
    if (prod1?.Sku && !prod1?.cmfCredit) {
      fetchCmfCredit(prod1?.Price, prod1?.Sku)
    }
    if (prod2?.Sku && !prod2?.cmfCredit) {
      fetchCmfCredit(prod2?.Price, prod2?.Sku)
    }
  }, [prod1?.Sku, prod2?.Sku])

  return (
    <div
      key={products.page}
      data-testid="printer-preview-left-canvas-2"
      id="printer-preview-left-canvas-square-2"
      className="printer-preview-left-canvas-square-2">
      <div
        className="printer-preview-left-canvas-square-2-box"
        style={{
          height: '100%',
          top: '0',
        }}>
        <div
          className="printer-preview-left-content-body-square-2"
          style={{ top: '106px', maxWidth: 'calc(72% - 40px)' }}>
          <p
            className="printer-preview-left-content-body-title"
            style={{ fontSize: '16px', lineHeight: '14px' }}>
            {prod1?.ProductName || ''}
          </p>
          <p
            className="printer-preview-left-content-body-sku"
            style={{ marginTop: '-8px', fontSize: '14px' }}>
            {prod1?.Sku || ''}
          </p>
          {prod1?.Price && (
            <div
              className="printer-preview-left-content-body-price-wrapper"
              style={{
                position: 'absolute',
                width: '100%',
                top: '36px',
              }}>
              <p
                className="printer-preview-mini-text"
                style={{
                  color: '#D9000C',
                  fontSize: '22px',
                  marginTop: '-4px',
                }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-price"
                style={{ color: '#D9000C', fontSize: '68px' }}>
                {prodPrice1?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text"
                style={{
                  color: '#D9000C',
                  fontSize: '22px',
                  marginTop: '-4px',
                }}>
                {prodPrice1?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
          {prod1?.BasePrice && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '4px',
                position: 'absolute',
                right: '-209px',
                width: '100%',
                top: '50px',
              }}>
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  marginBottom: '2px',
                  color: '#000',
                }}>
                Precio Regular
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#000',
                    fontSize: '15px',
                    marginTop: '-13px',
                  }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-price"
                  style={{ color: '#000', fontSize: '34px', marginTop: 0 }}>
                  {prodBasePrice1?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#000',
                    fontSize: '15px',
                    marginTop: '-13px',
                  }}>
                  {prodBasePrice1?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer-square-2"
          style={{ top: 'calc(50% - 62px)', left: '16px' }}>
          <div
            className="printer-preview-left-content-footer-quotes"
            style={{
              gridTemplateColumns: '0.9fr 0.8fr 0.8fr',
            }}>
            <p
              className="printer-preview-left-content-footer-quote-text"
              style={{ color: '#D9000C', fontSize: '22px' }}>
              36 cuotas
            </p>
            <p
              className="printer-preview-left-content-footer-quote-text"
              style={{
                color: '#D9000C',
                textAlign: 'start',
                paddingLeft: '8px',
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '1px',
              }}>
              {!loading && prod1?.cmfCredit && (
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#D9000C',
                    fontSize: '14px',
                    marginTop: '-4px',
                  }}>
                  $
                </p>
              )}
              {!loading && prod1?.cmfCredit && (
                <p
                  className="printer-preview-left-content-body-price"
                  style={{
                    color: '#D9000C',
                    fontSize: '22px',
                    marginTop: 0,
                    height: 'auto',
                  }}>
                  {cmfCredit1?.toString()?.split('.')?.[0] || 0 || ''}.
                </p>
              )}
              {!loading && prod1?.cmfCredit && (
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#D9000C',
                    fontSize: '14px',
                    marginTop: '-4px',
                  }}>
                  {cmfCredit1?.toString()?.split('.')?.[1] || '00'}
                </p>
              )}
              {/* {!loading && '$' + (prod?.cmfCredit || '')} */}
              {loading && (
                <FontAwesomeIcon
                  data-testid="spinner"
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 14 }}
                />
              )}
            </p>
            <div />
          </div>
          <div
            className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove"
            style={{ position: 'absolute', bottom: '-22px' }}>
            <TermsTextArea rows={3} prod={prod1} defaultValue={terms1} />
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-2-box"
        style={{
          height: '50%',
          top: '50%',
        }}>
        <div
          className="printer-preview-left-content-body-square-2-2"
          style={{ top: '105px', maxWidth: 'calc(72% - 40px)' }}>
          <p
            className="printer-preview-left-content-body-title"
            style={{ fontSize: '14px', lineHeight: '15px' }}>
            {prod2?.ProductName || ''}
          </p>
          <p
            className="printer-preview-left-content-body-sku"
            style={{ marginTop: '-8px', fontSize: '14px' }}>
            {prod2?.Sku || ''}
          </p>
          {prod2?.Price && (
            <div
              className="printer-preview-left-content-body-price-wrapper"
              style={{
                position: 'absolute',
                width: '100%',
                top: '36px',
              }}>
              <p
                className="printer-preview-mini-text"
                style={{
                  color: '#D9000C',
                  fontSize: '22px',
                  marginTop: '-4px',
                }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-price"
                style={{ color: '#D9000C', fontSize: '68px' }}>
                {prodPrice2?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text"
                style={{
                  color: '#D9000C',
                  fontSize: '22px',
                  marginTop: '-4px',
                }}>
                {prodPrice2?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
          {prod2?.BasePrice && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '4px',
                position: 'absolute',
                right: '-209px',
                width: '100%',
                top: '50px',
              }}>
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  marginBottom: '2px',
                  color: '#000',
                }}>
                Precio Regular
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#000',
                    fontSize: '15px',
                    marginTop: '-13px',
                  }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-price"
                  style={{ color: '#000', fontSize: '34px', marginTop: 0 }}>
                  {prodBasePrice2?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#000',
                    fontSize: '15px',
                    marginTop: '-13px',
                  }}>
                  {prodBasePrice2?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer-square-2-2"
          style={{ top: 'calc(100% - 64px)' }}>
          <div
            className="printer-preview-left-content-footer-quotes"
            style={{
              gridTemplateColumns: '0.9fr 0.8fr 0.8fr',
            }}>
            <p
              className="printer-preview-left-content-footer-quote-text"
              style={{ color: '#D9000C', fontSize: '22px' }}>
              36 cuotas
            </p>
            <p
              className="printer-preview-left-content-footer-quote-text"
              style={{
                color: '#D9000C',
                textAlign: 'start',
                paddingLeft: '8px',
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '1px',
              }}>
              {!loading && prod2?.cmfCredit && (
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#D9000C',
                    fontSize: '14px',
                    marginTop: '-4px',
                  }}>
                  $
                </p>
              )}
              {!loading && prod2?.cmfCredit && (
                <p
                  className="printer-preview-left-content-body-price"
                  style={{
                    color: '#D9000C',
                    fontSize: '22px',
                    marginTop: 0,
                    height: 'auto',
                  }}>
                  {cmfCredit2?.toString()?.split('.')?.[0] || 0 || ''}.
                </p>
              )}
              {!loading && prod2?.cmfCredit && (
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#D9000C',
                    fontSize: '14px',
                    marginTop: '-4px',
                  }}>
                  {cmfCredit2?.toString()?.split('.')?.[1] || '00'}
                </p>
              )}
              {/* {!loading && '$' + (prod?.cmfCredit || '')} */}
              {loading && (
                <FontAwesomeIcon
                  data-testid="spinner"
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 14 }}
                />
              )}
            </p>
            <div />
          </div>
          <div
            className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove"
            style={{ position: 'absolute', bottom: '-22px' }}>
            <TermsTextArea rows={3} prod={prod2} defaultValue={terms2} />
          </div>
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.cochez.special2}
        alt="template-preview"
      />
    </div>
  )
}
