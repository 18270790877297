import React from 'react'
import NoImage from '../../../../../Assets/Images/Logos/no-image.png'

export const TableImage = ({ image }) => {
  const imageRef = React.useRef()
  return (
    <img
      ref={imageRef}
      src={image || NoImage}
      className="print-table-product-image"
      onError={() => {
        imageRef.current.src = NoImage
      }}
    />
  )
}
