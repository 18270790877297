import CommonSearchDocument from 'Components/CommonSearchDocument/CommonSearchDocument'
import { ROUTES, documentTypeId, statusFilter } from 'Utils/constants'
import { FormatMoneyTable } from 'Utils/functions'
import { t } from 'i18next'

function SearchQuote() {
  const setTableData = data => {
    const quotes = data?.length
      ? data.map(quote => {
          return {
            nombre: quote?.CustomerNameDocument || t('UNKNOWN'),
            numero: quote?.DocumentNumber,
            amount: `$ ${FormatMoneyTable(
              quote?.AmountWithTax,
              false,
              true,
              true
            )}`,
            salesOrder:
              quote?.ODVDocumentNumber?.[0]?.DocumentNumber || t('UNKNOWN'),
            origen: quote?.Origin === 'AB' ? 'Web' : 'VA' || t('UNKNOWN'),
            fecha: quote?.CreatedDate.slice(0, 10),
            estatus: quote?.DocHeaderStatusDescription,
            docHeaderId: quote?.DocHeaderId,
            email: quote?.EmailAddress,
            route: `${ROUTES.editQuoteHeaderId}${quote.DocHeaderId}/${quote?.DocumentNumber}`,
          }
        })
      : []
    return quotes
  }

  const objColumns = [
    {
      Header: t('SEARCH_QUOTATION_T.CLIENT_NAME'),
      accessor: 'nombre',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.QUOTATION_NUMBER_LABEL'),
      accessor: 'numero',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.QUOTATION_AMOUNT_LABEL'),
      accessor: 'amount',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.SELL_ORDER_NUMBER_LABEL'),
      accessor: 'salesOrder',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.DATE_QUOTATION_LABEL'),
      accessor: 'fecha',
      hasFilter: true,
      filterType: 'date',
    },
    {
      Header: t('SEARCH_QUOTATION_T.ORIGIN_QUOTATION_LABEL'),
      accessor: 'origen',
      hasFilter: true,
    },
    {
      Header: t('SEARCH_QUOTATION_T.STATUS_LABEL'),
      accessor: 'estatus',
      hasFilter: true,
      filterType: 'status',
    },
  ]

  return CommonSearchDocument({
    title: 'SEARCH_QUOTATION_T.CREATE_OR_SEARCH_QUOTATION_LABEL',
    objColumns,
    filterOptions: statusFilter.quote,
    setTableData,
    placeholder: 'ACTION_TABLE_T.ADD_QUOTATION_PLACEHOLDER',
    documentTypeIdDocument: documentTypeId.QUOTATION,
  })
}

export default SearchQuote
