import CommonSearchDocument from 'Components/CommonSearchDocument/CommonSearchDocument'
import { ROUTES, documentTypeId, statusFilter } from 'Utils/constants'
import { t } from 'i18next'

function SearchDispatch() {
  const setTableData = data => {
    const dispatches = data?.map(dispatch => {
      return {
        nombre: dispatch?.SalesOrder?.[0]?.CustomerNameDocument || t('UNKNOWN'),
        numero: dispatch?.DocumentNumber,
        salesOrder: dispatch?.SalesOrder?.[0]?.DocumentNumber,
        numero_orden_compra: dispatch?.OrderNumber || t('UNKNOWN'),
        invoiceNumber:
          dispatch?.InvoiceDocumentNumber?.[0]?.DocumentNumber || t('UNKNOWN'),
        origen: dispatch?.Origin === 'AB' ? 'Web' : 'VA' || t('UNKNOWN'),
        fecha: dispatch?.CreatedDate?.slice(0, 10),
        estatus: dispatch?.Description,
        docHeaderId: dispatch?.DocHeaderId,
        email: dispatch?.EmailAddress,
        route: `${ROUTES.orderDispatch}${dispatch?.DocHeaderId}`,
      }
    })
    return dispatches || []
  }

  const objColumns = [
    {
      Header: t('SEARCH_QUOTATION_T.CLIENT_NAME'),
      accessor: 'nombre',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.NUMBER_DESPACHO_LABEL'),
      accessor: 'numero',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.NUMBER_ORDER_LABEL'),
      accessor: 'numero_orden_compra',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.SELL_ORDER_NUMBER_LABEL'),
      accessor: 'salesOrder',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.INVOICE_NUMBER'),
      accessor: 'invoiceNumber',
      hasFilter: false,
    },
    {
      Header: t('SEARCH_QUOTATION_T.ORIGIN_QUOTATION_LABEL'),
      accessor: 'origen',
      hasFilter: true,
    },
    {
      Header: t('SEARCH_QUOTATION_T.DATE_QUOTATION_LABEL'),
      accessor: 'fecha',
      hasFilter: true,
      filterType: 'date',
    },
    {
      Header: t('SEARCH_QUOTATION_T.STATUS_LABEL'),
      accessor: 'estatus',
      hasFilter: true,
      filterType: 'status',
    },
  ]

  return CommonSearchDocument({
    title: 'DISPATCH_ORDER_T.DISPATCH_TITLE',
    objColumns,
    filterOptions: statusFilter.dispatch,
    setTableData,
    placeholder: 'ACTION_TABLE_T.ADD_DISPATCH_PLACEHOLDER',
    documentTypeIdDocument: documentTypeId.SD,
  })
}

export default SearchDispatch
