import React, { useState } from 'react'
import { useTable, useGlobalFilter } from 'react-table'
import './tableProductListPrinter.css'
import Pagination from 'Components/Pagination'
import { t } from 'i18next'
import { ModalPrinterTemplates } from '../ModalPrinterTemplates'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { DatePickerFilter } from './components/DatePickerFilter'
import { CustomCheckBox } from 'Components/CustomCheckBox'
import { DepartmentFilter } from './components/DepartmentFilter'

export function TableProductListPrinter({
  data,
  columns,
  handleGetData,
  pageSize,
  selectedOption,
  setPageSize,
  selectedDepartment,
  departmentState,
}) {
  const { setSelectedProducts, selectedProducts } = usePrinterContext()

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: [],
    },
    useGlobalFilter
  )
  const formatValue = (value, index, column) => {
    if (column === 'BasePrice') {
      return parseFloat(value).toFixed(2)
    } else {
      return value
    }
  }
  const [openTemplateModal, setOpenTemplateModal] = React.useState(false)

  const [currentPage, setCurrentPage] = useState(data?.[0]?.currentPage || 1)
  const [firstIndex, setFirstIndex] = useState(currentPage - 1 || 0)
  const [lastIndex, setLastIndex] = useState(currentPage + 2 || 3)
  const pageNums = []
  const totalPages = Math.ceil(data?.[0]?.TotalRegisters / pageSize)

  for (let i = 1; i <= totalPages; i++) {
    pageNums.push(i)
  }

  const handleClose = () => setOpenTemplateModal(false)
  const handleShow = () => setOpenTemplateModal(true)
  const handleChangePage = page => {
    setCurrentPage(page)
    handleGetData({ pageSize, currentPage: page })
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  const isHeaderVisible = (headerId, headerGroups) => {
    const header = headerGroups.headers.find(header => header.id === headerId)
    return header.isVisible
  }

  return (
    <div className="printer-table-wrapper-table">
      <table
        className="printer-table table table-responsive printer-table-wrapper-table-body"
        {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, indez) => (
                <>
                  <th
                    className="row-center-text blod-font"
                    {...column.getHeaderProps()}
                    colSpan={
                      column?.children && column?.children?.length > 0
                        ? column.children?.length
                        : 1
                    }
                    key={'m' + indez}>
                    {column.Header.includes('Check') ? (
                      ''
                    ) : column.Header.includes('Vigencia') ? (
                      <>
                        {column.render('Header')}
                        <DatePickerFilter handleGetData={handleGetData} />
                      </>
                    ) : column.Header.includes('Departamento') ? (
                      <>
                        {column.render('Header')}
                        <DepartmentFilter
                          handleGetData={handleGetData}
                          selectedDepartment={selectedDepartment}
                          departmentState={departmentState}
                        />
                      </>
                    ) : (
                      column.render('Header')
                    )}
                  </th>
                </>
              ))}
            </tr>
          ))}
          {headerGroups.map((headerGroup, indexe) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={'h' + indexe}>
              {headerGroup.headers.map((column, indeze) =>
                column.children !== undefined && column.children.length > 0 ? (
                  isHeaderVisible(column.id, headerGroup) ? (
                    column.children.map((columnR, indexess) => (
                      <th
                        className="row-center-text"
                        {...column.getHeaderProps()}
                        key={'c' + indexess}>
                        {columnR.Header}
                      </th>
                    ))
                  ) : null
                ) : (
                  <th
                    className={
                      column.Header.includes('Vigencia') ||
                      column.Header.includes('Precio') ||
                      column.Header.includes('Check')
                        ? ''
                        : 'remove-border-left remove-border-right'
                    }
                    key={'colempty' + indeze}>
                    {''}
                  </th>
                )
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <tr key={index} {...row.getRowProps()}>
                {row.cells.map((cell, indez) => {
                  const isDisabled =
                    selectedProducts.length >= 8 &&
                    !selectedProducts.find(
                      p => p.ProductId === row.original.ProductId
                    )
                  return cell.column.Header.includes('Precio') ||
                    cell.column.Header.includes('Vigencia') ? (
                    cell.row.values[cell.column.Header]?.map(
                      (value, indexess) => (
                        <td
                          {...cell.getCellProps({
                            style: {
                              maxWidth: '200px',
                            },
                          })}
                          className={
                            cell.column.isVisible
                              ? 'show-cell-report align-right'
                              : 'hidde-cell-report align-right'
                          }
                          key={'final' + indexess}>
                          {formatValue(value, indexess, cell.column.Header)}
                        </td>
                      )
                    )
                  ) : cell.column.Header.includes('Check') ? (
                    <td {...cell.getCellProps()} key={'without' + indez}>
                      <div className="table-order-check-style">
                        <CustomCheckBox
                          key={row.original.ProductId}
                          checked={selectedProducts.find(
                            p => p.ProductId === row.original.ProductId
                          )}
                          disabled={isDisabled}
                          onChange={() => {
                            if (isDisabled) return

                            const product = {
                              ...row.original,
                              ListType:
                                selectedOption?.template?.value ||
                                row.original.ListType,
                            }
                            const newSelectedProducts = selectedProducts.find(
                              p => p.ProductId === product.ProductId
                            )
                              ? selectedProducts.filter(
                                  p => p.ProductId !== product.ProductId
                                )
                              : [...selectedProducts, product]
                            setSelectedProducts(newSelectedProducts)
                          }}
                        />
                      </div>
                    </td>
                  ) : cell.column.Header.includes('Tipo') ? (
                    <td {...cell.getCellProps()} key={'without' + indez}>
                      {selectedOption?.template?.value || cell.render('Cell')}
                    </td>
                  ) : (
                    // show the data of other columns
                    <td {...cell.getCellProps()} key={'without' + indez}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="footer-table-printer" style={{ paddingRight: 16 }}>
        <div className="cs-footer-content">
          <p>
            {t('SEARCH_CLIENT_T.SHOW_PAGINATION_LABEL', {
              registers: data?.[0]?.TotalRegisters,
              pageSize: pageSize * currentPage - (pageSize - 1),
              elementsPerPage: pageSize * currentPage,
            })}
          </p>
          <select
            name="changePageSize"
            id="pageSize_selector"
            onChange={e => {
              setPageSize(e.target.value)
              handleGetData({ pageSize: e.target.value, currentPage: 1 })
            }}
            value={pageSize}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
        </div>
        <Pagination
          type={'pagination'}
          firstIndex={firstIndex}
          lastIndex={lastIndex}
          currentPage={currentPage}
          pageNums={pageNums}
          setCurrentPage={handleChangePage}
          setFirstIndex={setFirstIndex}
          setLastIndex={setLastIndex}
          margin={0}
        />
      </div>
      <div className="printer-table-continue-button-wrapper">
        <button
          onClick={handleShow}
          disabled={selectedProducts.length === 0}
          className="printer-header-button">
          Continuar
        </button>
      </div>
      <ModalPrinterTemplates
        show={openTemplateModal}
        onClose={handleClose}
        selectedOption={selectedOption}
      />
    </div>
  )
}
