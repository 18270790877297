import React from 'react'
import { Square1ToPrint } from '../Template/components/novey/cmf/Square1ToPrint'
import { Square2ToPrint } from '../Template/components/novey/cmf/Square2ToPrint'
import { Square3ToPrint } from '../Template/components/novey/cmf/Square3ToPrint'
import {
  OFFER,
  OFFER_CMF,
  REGULAR,
  REGULAR_CMF,
  SPECIAL_CMF,
} from '../Template'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { Square1ToPrintNRegular } from '../Template/components/novey/regular/Square1ToPrint'
import { Square2ToPrintNRegular } from '../Template/components/novey/regular/Square2ToPrint'
import { Square1ToPrintNCmfOffer } from '../Template/components/novey/cmfOffer/Square1ToPrint'
import { Square2ToPrintNCmfOffer } from '../Template/components/novey/cmfOffer/Square2ToPrint'
import { Square3ToPrintNCmfOffer } from '../Template/components/novey/cmfOffer/Square3ToPrint'
import { Square1ToPrintNOffer } from '../Template/components/novey/offer/Square1ToPrint'
import { Square2ToPrintNOffer } from '../Template/components/novey/offer/Square2ToPrint'
import { useSelector } from 'react-redux'
import { Square1ToPrintCCmfOffer } from '../Template/components/cochez/cmfOffer/Square1ToPrint'
import { Square2ToPrintCCmfOffer } from '../Template/components/cochez/cmfOffer/Square2ToPrint'
import { Square2ToPrintCRegular } from '../Template/components/cochez/regular/Square2ToPrint'
import { Square1ToPrintCRegular } from '../Template/components/cochez/regular/Square1ToPrint'
import { Square1ToPrintCOffer } from '../Template/components/cochez/offer/Square1ToPrint'
import { Square2ToPrintCOffer } from '../Template/components/cochez/offer/Square2ToPrint'
import { Square1ToPrintCCmf } from '../Template/components/cochez/cmf/Square1ToPrint'
import { Square2ToPrintCCmf } from '../Template/components/cochez/cmf/Square2ToPrint'
import { Square3ToPrintCCmf } from '../Template/components/cochez/cmf/Square3ToPrint'
import { Square3ToPrintCRegular } from '../Template/components/cochez/regular/Square3ToPrint'
import { Square3ToPrintCOffer } from '../Template/components/cochez/offer/Square3ToPrint'
import { Square3ToPrintCCmfOffer } from '../Template/components/cochez/cmfOffer/Square3ToPrint'
import { Square3ToPrintNRegular } from '../Template/components/novey/regular/Square3ToPrint'
import { Square3ToPrintNOffer } from '../Template/components/novey/offer/Square3ToPrint'
import { Square1ToPrintSpecial } from '../Template/components/cochez/special/Square1ToPrint'
import { Square2ToPrintSpecial } from '../Template/components/cochez/special/Square2ToPrint'

export const TemplateToPrint = ({ content, i }) => {
  const { CompanyId } = useSelector(state => state.company)
  const isCochez =
    CompanyId?.toUpperCase() === 'AEE876CB-7183-4BEE-8FCA-984B7A1F6BA9'

  const { selectedTemplateType } = usePrinterContext()
  const templateType = content?.template?.[0]

  const isRegCMF = selectedTemplateType?.template === REGULAR_CMF
  const isOfferCMF = selectedTemplateType?.template === OFFER_CMF
  const isReg = selectedTemplateType?.template === REGULAR
  const isOffer = selectedTemplateType?.template === OFFER
  const isSpecialCMF = selectedTemplateType?.template === SPECIAL_CMF

  if (isRegCMF && !isCochez) {
    return (
      <>
        {templateType === 'square-1' && (
          <Square1ToPrint i={i} products={content} />
        )}
        {templateType === 'square-2' && (
          <Square2ToPrint i={i} products={content} />
        )}
        {templateType === 'square-3' && (
          <Square3ToPrint i={i} products={content} />
        )}
        {templateType === 'square-4' && (
          <Square3ToPrint i={i} products={content} />
        )}
      </>
    )
  }
  if (isOfferCMF && !isCochez) {
    return (
      <>
        {templateType === 'square-1' && (
          <Square1ToPrintNCmfOffer i={i} products={content} />
        )}
        {templateType === 'square-2' && (
          <Square2ToPrintNCmfOffer i={i} products={content} />
        )}
        {templateType === 'square-3' && (
          <Square3ToPrintNCmfOffer i={i} products={content} />
        )}
        {templateType === 'square-4' && (
          <Square3ToPrintNCmfOffer i={i} products={content} />
        )}
      </>
    )
  }
  if (isReg && !isCochez) {
    return (
      <>
        {templateType === 'square-1' && (
          <Square1ToPrintNRegular i={i} products={content} />
        )}
        {templateType === 'square-2' && (
          <Square2ToPrintNRegular i={i} products={content} />
        )}
        {templateType === 'square-3' && (
          <Square3ToPrintNRegular i={i} products={content} />
        )}
        {templateType === 'square-4' && (
          <Square3ToPrintNRegular i={i} products={content} />
        )}
      </>
    )
  }
  if (isOffer && !isCochez) {
    return (
      <>
        {templateType === 'square-1' && (
          <Square1ToPrintNOffer i={i} products={content} />
        )}
        {templateType === 'square-2' && (
          <Square2ToPrintNOffer i={i} products={content} />
        )}
        {templateType === 'square-3' && (
          <Square3ToPrintNOffer i={i} products={content} />
        )}
        {templateType === 'square-4' && (
          <Square3ToPrintNOffer i={i} products={content} />
        )}
      </>
    )
  }
  if (isOfferCMF && isCochez) {
    return (
      <>
        {templateType === 'square-1' && (
          <Square1ToPrintCCmfOffer i={i} products={content} />
        )}
        {templateType === 'square-2' && (
          <Square2ToPrintCCmfOffer i={i} products={content} />
        )}
        {templateType === 'square-3' && (
          <Square3ToPrintCCmfOffer i={i} products={content} />
        )}
        {templateType === 'square-4' && (
          <Square3ToPrintCCmfOffer i={i} products={content} />
        )}
      </>
    )
  }
  if (isReg && isCochez) {
    return (
      <>
        {templateType === 'square-1' && (
          <Square1ToPrintCRegular i={i} products={content} />
        )}
        {templateType === 'square-2' && (
          <Square2ToPrintCRegular i={i} products={content} />
        )}
        {templateType === 'square-3' && (
          <Square3ToPrintCRegular i={i} products={content} />
        )}
        {templateType === 'square-4' && (
          <Square3ToPrintCRegular i={i} products={content} />
        )}
      </>
    )
  }
  if (isOffer && isCochez) {
    return (
      <>
        {templateType === 'square-1' && (
          <Square1ToPrintCOffer i={i} products={content} />
        )}
        {templateType === 'square-2' && (
          <Square2ToPrintCOffer i={i} products={content} />
        )}
        {templateType === 'square-3' && (
          <Square3ToPrintCOffer i={i} products={content} />
        )}
        {templateType === 'square-4' && (
          <Square3ToPrintCOffer i={i} products={content} />
        )}
      </>
    )
  }

  if (isRegCMF && isCochez) {
    return (
      <>
        {templateType === 'square-1' && (
          <Square1ToPrintCCmf i={i} products={content} />
        )}
        {templateType === 'square-2' && (
          <Square2ToPrintCCmf i={i} products={content} />
        )}
        {templateType === 'square-3' && (
          <Square3ToPrintCCmf i={i} products={content} />
        )}
        {templateType === 'square-4' && (
          <Square3ToPrintCCmf i={i} products={content} />
        )}
      </>
    )
  }

  if (isSpecialCMF) {
    return (
      <>
        {templateType === 'square-1' && (
          <Square1ToPrintSpecial i={i} products={content} />
        )}
        {templateType === 'square-2' && (
          <Square2ToPrintSpecial i={i} products={content} />
        )}
      </>
    )
  }
  return null
}
