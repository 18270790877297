import { images } from 'Assets/Images'
import React from 'react'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { FormatMoney } from 'Utils/functions'
import { disclaimers } from 'Views/PrinterPreview/constants'
const DEFAULT_VALUE = disclaimers.regularCmf

export const Square2ToPrintCCmf = ({ products, i }) => {
  const { templateRef, terms } = usePrinterContext()

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const terms1 =
    terms?.find(term => term?.prodId === prod1?.ProductId)?.value ||
    DEFAULT_VALUE
  const terms2 =
    terms?.find(term => term?.prodId === prod2?.ProductId)?.value ||
    DEFAULT_VALUE
  const prodBasePrice1 = FormatMoney(prod1?.BasePrice || 0)
  const prodBasePrice2 = FormatMoney(prod2?.BasePrice || 0)

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        key={products.page}
        data-testid="printer-preview-left-canvas-2"
        id="printer-preview-left-canvas-square-2"
        className="printer-preview-left-canvas-square-2">
        <div
          className="printer-preview-left-canvas-square-2-box"
          style={{
            height: '100%',
            top: '0',
          }}>
          <div className="printer-preview-left-content-body-square-2">
            <p
              className="printer-preview-left-content-body-square-2-title"
              style={{ lineHeight: '17px' }}>
              {prod1?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-2-sku">
              {prod1?.Sku || ''}
            </p>
            {prod1?.BasePrice && (
              <div
                className="printer-preview-left-content-body-square-2-price-wrapper"
                style={{ marginTop: '6px' }}>
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ color: '#000' }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-square-2-price"
                  style={{ color: '#000', fontSize: '80px' }}>
                  {prodBasePrice1?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ color: '#000' }}>
                  {prodBasePrice1?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div className="printer-preview-left-content-footer-square-2">
            <div className="printer-preview-left-content-footer-square-2-quotes">
              <p></p>
              <p
                className="printer-preview-left-content-footer-square-2-quote-text"
                style={{ color: '#000' }}>
                36 <br /> cuotas
              </p>
              <p
                className="printer-preview-left-content-footer-square-2-quote-text"
                style={{ color: '#000' }}>
                ${FormatMoney(prod1?.cmfCredit || 0) || ''}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{ border: 'none' }}>
                {terms1}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-2-box"
          style={{
            height: '50%',
            top: '50%',
          }}>
          <div className="printer-preview-left-content-body-square-2-2">
            <p
              className="printer-preview-left-content-body-square-2-title-2"
              style={{ lineHeight: '17px' }}>
              {prod2?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-2-sku-2">
              {prod2?.Sku || ''}
            </p>
            {prod2?.BasePrice && (
              <div
                className="printer-preview-left-content-body-square-2-price-wrapper"
                style={{ marginTop: '0px' }}>
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ color: '#000' }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-square-2-price-2"
                  style={{ color: '#000', fontSize: '80px' }}>
                  {prodBasePrice2?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ color: '#000' }}>
                  {prodBasePrice2?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div className="printer-preview-left-content-footer-square-2-2">
            <div className="printer-preview-left-content-footer-square-2-quotes">
              <p></p>
              <p
                className="printer-preview-left-content-footer-square-2-quote-text"
                style={{ color: '#000' }}>
                36 <br /> cuotas
              </p>
              <p
                className="printer-preview-left-content-footer-square-2-quote-text"
                style={{ color: '#000' }}>
                ${FormatMoney(prod2?.cmfCredit || 0) || ''}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{ border: 'none' }}>
                {terms2}
              </p>
            </div>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.cochez.cmf2}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
