import { fetchGetOdvDetails } from 'Api/services/busServices'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCustomerByIdService } from 'redux/services/clientsServices'

export const useBusLogic = () => {
  const orderDispatchPostData = useSelector(
    state => state.sellOrder.orderDispatchPostData?.Data
  )

  const isWebDocument =
    orderDispatchPostData?.SalesDocumentInfo?.OriginODV === 'AB'
  const odvDocHeaderId =
    orderDispatchPostData?.SalesDocumentInfo?.SalesOrderHeaderId

  const [currentSelectedAddress, setCurrentSelectedAddress] = useState({})

  useEffect(() => {
    const getOdvDetails = async saleOrderId => {
      try {
        const data = await fetchGetOdvDetails({
          saleOrderId: odvDocHeaderId,
        })
        const customerId = orderDispatchPostData?.SalesDocumentInfo?.CustomerId
        const busAddressId = data?.Data?.[0]?.AddressId?.toLowerCase()
        const userData = await getCustomerByIdService(customerId)
        const addressData = userData?.data?.Data?.AddressList
        const selectedAddressData = addressData?.find(
          address =>
            address?.AddressInfo?.AddressId?.toLowerCase() === busAddressId
        )?.AddressInfo
        if (selectedAddressData) {
          setCurrentSelectedAddress(selectedAddressData)
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }
    if (isWebDocument && odvDocHeaderId) {
      getOdvDetails(odvDocHeaderId)
    }
  }, [odvDocHeaderId])
  return { currentSelectedAddress, isWebDocument }
}
