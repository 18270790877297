import React, { useState, useMemo, useEffect, useRef } from 'react'
import Navigation from '../../Components/Navigation'
import RightSetLocationModal from '../../Components/RightSetLocationModal'
import DirectDeliveryModal from 'Components/DirectDeliveryModal/DirectDeliveryModal'
import TableOrdenDeVenta from '../../Components/TableOrdenDeVenta'
import { Row, Col, Container } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import './OrdenDeVenta.css'
import GenericWarningModal from 'Components/GenericWarningModal'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { Box, CircularProgress, Modal } from '@mui/material'
import FormDireccion from 'Components/FormDireccion'
import {
  getCustomerById,
  getWharehouseInfo,
} from 'redux/actions/clientsActions'
import {
  approvedColumns,
  unaprovedColumns,
  closeColumns,
} from './columnConstants'

import ModalCancelPendingDispatch from 'Components/ModalCancelPendingDispatch'
import { generateSellOrderAction } from 'redux/actions/sellOrderActions'
import GenericModal from 'Components/GenericModal'
import { ID_CONSTANTS } from 'redux/types'
import { fullfilmentPlanActionByDelivery } from 'redux/actions/catalogActions'
import { FormatMoney, formatDate } from 'Utils/functions'
import {
  documentTypeId,
  salesOrderTypeIds,
  dispatchTypeId,
  VAPermissions,
  docHeaderStatusDescription,
} from 'Utils/constants'
import {
  cotizacionUpdate,
  searchSalesDocumentDetail,
} from 'redux/actions/cotizacionActions'
import MiniLoading from 'Components/MiniLoading'
import RelationalDocLabel from 'Components/RelationalDocLabel'
import { getCorrectDocPosition } from './utils/getCorrectDocPosition'
import useValidatePermission from 'Hooks/useValidatePermission'
import { GetApprovalSalesOrder } from 'redux/services/cotizacionServices'
import No from '../../Assets/Images/Login/no.svg'
import { useUploadOdcFile } from './hooks/useUploadOdcFile'
import { DocumentStatusSelector } from './components/DocumentStatusSelector'
import { fetchGetOdvDetails } from 'Api/services/busServices'

function OrdenDeVenta(props) {
  const dispatch = useDispatch()
  const param = useParams()
  const { uploadFile } = useUploadOdcFile()
  const isCustomer = useSelector(state => state.login.isCustomer)
  const [hiddenColumns] = useState([])
  const [comment, setComment] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [isCartEmpty, setIsCartEmpty] = useState(false)
  const [dispatchQtyState, setDispatchQtyState] = useState([])
  const [showAddAddressState, setShowAddAddressState] = useState(false)
  const [isDirectDelivery, setIsDirectDelivery] = useState(false)
  const [directdeliveryState] = useState(false)
  const [listOfNewCosts, setListOfNewCosts] = useState([])
  const [
    openModalCancelPendingDispatchState,
    setOpenModalCancelPendingDispatchState,
  ] = useState(false)
  const sellOrderValidatedRef = useRef(false)
  const [branchesState, setBranchesState] = useState([])
  const [showFailedSellOrderState, setShowFailedSellOrderState] =
    useState(false)
  const [approvedColumnsState, setApprovedColumnsState] = useState(
    approvedColumns({})
  )
  const [unaprovedColumnsState] = useState(unaprovedColumns())
  const [closeColumnsState, setCloseColumnsState] = useState(closeColumns())
  const companyId = useSelector(state => state.company.CompanyId)
  const { fullfilmentResponse } = useSelector(state => state.catalog)
  const clienteId = useSelector(
    state => state?.cotizacion?.QuoteHeader?.CustomerId
  )
  const { isCommercialCreditSelected } = useSelector(state => state.sellOrder)
  const [closeWarningModal, setCloseWarningModal] = useState(true)
  const createDispatch = useValidatePermission(VAPermissions.createDispatch)
  const [cancelDispatchProductSelected, setCancelDispatchProductSelected] =
    useState({})
  const useSelectorProducts = useSelector(state => state.cotizacion.QuoteHeader)
  const customerSavedLocations = useSelector(
    state => state.sellOrder.customerInfoObject
  )

  const [hasProductsWithCancel, setHasProductsWithCancel] = useState(false)

  const useSelectorQuoteObject = useSelector(state => state.cotizacion)
  const {
    sellOrderPostLoading,
    sellOrderPostData,
    isRedirect,
    sellOrderFileAttached,
  } = useSelector(state => state.sellOrder)

  const [webOdvDetails, setWebOdvDetails] = useState([])
  const isWebDocument =
    sellOrderPostData?.Data?.SalesDocumentInfo?.Origin === 'AB'
  const isHomeDelivery = webOdvDetails?.DeliveryType === 1
  const showHomeDeliveryAddress = isWebDocument
    ? isHomeDelivery
    : useSelectorProducts?.QuoteAddressId
  const addressData = customerSavedLocations?.AddressList
  const busAddressId = webOdvDetails?.AddressId?.toLowerCase()
  const selectedAddressData = useMemo(() => {
    return addressData?.find(
      address => address?.AddressInfo?.AddressId?.toLowerCase() === busAddressId
    )?.AddressInfo
  }, [addressData, busAddressId])

  const history = useHistory()

  const sellOrderValidated = useSelector(
    state => state.sellOrder.isPreOrderValidated
  )

  const [loadingData, setLoadingData] = useState(false)
  const [isApproved, setIsApproved] = useState(true)

  sellOrderValidatedRef.current = sellOrderValidated

  const validateDirectDeliveryProviders = lineItem => {
    let validationPassed = false

    lineItem?.Providers?.forEach(itemProv => {
      if (itemProv.IsDirectDelivery) {
        validationPassed = true
      }
    })
    return validationPassed
  }

  const validateHasProductsWithCancel = () => {
    let validationPassed = false
    dispatchQtyState.forEach(item => {
      if (item.qtyCanceled > 0) {
        validationPassed = true
      }
    })
    return validationPassed
  }

  const setTableData = data => {
    if (!data) return []
    const products = data?.map((product, index) => {
      const isPreOrder = window.location.pathname.includes('preorden_de_venta')

      const productData =
        sellOrderPostData?.Data?.SalesDocumentInfo?.Lines.find(
          line => line.ProductId === product.ProductId
        )
      const qtyCanceled = productData?.QtyCanceled || 0
      const qtyPending =
        parseFloat(product?.Quantity || 0) -
        parseFloat(productData?.QtyDelivered || 0) -
        parseFloat(productData?.QtyCanceled || 0)
      const qtyDeliveredFromDB =
        Math.sign(qtyPending) === -1
          ? productData?.QtyDeliveredCalculated
          : productData?.QtyDelivered
      const qtyDelivered = isPreOrder
        ? productData?.QtyDelivered || 0
        : qtyDeliveredFromDB

      return {
        productId: product?.ProductId,
        referencia: product?.SystemCode,
        descripción: product?.ProductName,
        pendiente: null,
        cantidad: product?.Quantity,
        precio_unitario: product?.Price,
        Descuento: product?.DiscountAmount,
        precio_total: product?.Price * product?.Quantity,
        costo: product?.Cost ? product?.Cost : 0.0,
        costModifier: !!(
          product?.Cost === 0 ||
          product?.Cost?.toFixed(2) === null ||
          product?.Cost?.toFixed(2) === undefined
        ),
        solicitud_despacho: null,
        negativeMargen: isWebDocument
          ? false
          : product?.Price <
            (product?.FinalPrices?.[1]?.FinalPrice || product?.FinalPrice),
        isSupplierDirectDelivery: validateDirectDeliveryProviders(product),
        reasonId: productData?.ReasonId || null,
        qtyCanceled,
        qtyDelivered,
        qtyOutstanding: productData?.QtyOutstanding || 0,
      }
    })
    return products
  }

  const productsData = useMemo(() => {
    if (sellOrderPostData) {
      return setTableData(sellOrderPostData?.Data?.SalesDocumentInfo?.Lines)
    } else {
      return setTableData(useSelectorProducts.Lines)
    }
  }, [useSelectorProducts, sellOrderPostData])

  const saveChangeCancelDispatch = async (productId, reasonId) => {
    setLoadingData(true)
    try {
      const newLines = sellOrderPostData?.Data?.SalesDocumentInfo?.Lines.map(
        line => {
          if (line.ProductId === productId) {
            const searchQtyCanceled =
              dispatchQtyState.find(item => item.productId === productId)
                ?.solicitud_despacho || 1

            const qtyCanceled = Number.isInteger(searchQtyCanceled)
              ? searchQtyCanceled
              : Number(
                  FormatMoney(searchQtyCanceled, false, 4).replace(/,/g, '')
                )

            let totalQuantityCanceled = line.QtyCanceled + qtyCanceled
            if (totalQuantityCanceled > line.Quantity) {
              totalQuantityCanceled = line.Quantity
            }
            line.QtyCanceled = totalQuantityCanceled
            line.ReasonId = reasonId
            line.salesOrderLineId = line.DocLineId
            line.salesOrderHeaderId = line.DocHeaderId
          } else {
            line.salesOrderLineId = line.DocLineId
            line.salesOrderHeaderId = line.DocHeaderId
          }
          return line
        }
      )
      const payload = {
        documentTypeId:
          sellOrderPostData?.Data?.SalesDocumentInfo?.DocumentTypeId,
        salesOrder: {
          salesOrderHeaderId:
            sellOrderPostData?.Data?.SalesDocumentInfo?.DocHeaderId,
          customerId: sellOrderPostData?.Data?.SalesDocumentInfo?.CustomerId,
          creditAccountId: null,
          salesAgentId:
            sellOrderPostData?.Data?.SalesDocumentInfo?.SalesAgentId,
          DocumentDate:
            sellOrderPostData?.Data?.SalesDocumentInfo?.DocumentDate,
          quantity: sellOrderPostData?.Data?.SalesDocumentInfo?.Quantity,
          amount: sellOrderPostData?.Data?.SalesDocumentInfo?.Amount,
          amountWithTax:
            sellOrderPostData?.Data?.SalesDocumentInfo?.AmountWithTax,
          taxAmount: sellOrderPostData?.Data?.SalesDocumentInfo?.TaxAmount,
          companyId: sellOrderPostData?.Data?.SalesDocumentInfo?.CompanyId,
          companyCode: sellOrderPostData?.Data?.SalesDocumentInfo?.CompanyCode,
          quoteHeaderId:
            sellOrderPostData?.Data?.SalesDocumentInfo?.QuoteHeaderId,
          salesOrderTypeId:
            sellOrderPostData?.Data?.SalesDocumentInfo?.SalesOrderTypeId,
          HeaderStatusId:
            sellOrderPostData?.Data?.SalesDocumentInfo?.DocHeaderStatusId,
          comments: sellOrderPostData?.Data?.SalesDocumentInfo?.Comments,
          salesAgentEmail:
            sellOrderPostData?.Data?.SalesDocumentInfo?.SalesAgentEmail,
          WarehouseId: sellOrderPostData?.Data?.SalesDocumentInfo?.WarehouseId,
          origin: 'VA',
          lines: newLines,
          // CreditAccountId: isCommercialCreditSelected?.credit ==="yes"?isCommercialCreditSelected?.accountItemSelected?.CreditAccountId:null,
        },
      }
      await dispatch(generateSellOrderAction(payload))
    } catch (error) {
      console.error(error)
    } finally {
      // setLoadingData(false)
      window.location.reload()
    }
  }

  const fetchWarehouse = async () => {
    const payload = {
      companyId,
      tenantId: 2,
      SearchText: null,
      PageNumber: 1,
      PageSize: 100,
    }
    const warehouseInfo = await dispatch(getWharehouseInfo(payload))
    const warehouses = warehouseInfo
      .map(branch => {
        const composedWarehouse = {
          ...branch.Warehouses[0],
          WarehouseAddress: branch.Address_AddressDetail,
          AddressId: branch.AddressId,
        }
        return composedWarehouse
      })
      .filter(warehouse => {
        return warehouse.Description !== undefined
      })
    setBranchesState(prevState => [...prevState, ...warehouses])
  }
  const updatCustomer = async CustomerId => {
    const response = await dispatch(getCustomerById(CustomerId))
    dispatch({ type: 'SET_CUSTOMER_INFO', payload: response })
  }
  const fetchDocument = async (
    DocumentTypeId = null,
    id = null,
    header = null
  ) => {
    setLoadingData(true)
    try {
      const payload = {
        DocumentTypeId,
        DocumentHeaderId: id,
        DocumentNumber: header,
        PageNumber: 1,
        PageSize: 1,
      }
      const { Data, Status } = await dispatch(
        searchSalesDocumentDetail(payload)
      )
      if (Data && Status.Code === 200) {
        if (DocumentTypeId.toLowerCase() === documentTypeId.ODV.toLowerCase()) {
          const dataFormated = { Data: { SalesDocumentInfo: { ...Data } } }
          dispatch({ type: 'SELL_ORDER_POST_RESULT', payload: dataFormated })

          const relationalDocPosition =
            getCorrectDocPosition(Data?.RelationDoc) ?? 0
          const { DocHeaderId, DocumentNumber, DocumentTypeId } =
            Data?.RelationDoc?.[relationalDocPosition]
          await fetchDocument(DocumentTypeId, DocHeaderId, DocumentNumber)
        }
        if (
          DocumentTypeId.toLowerCase() ===
          documentTypeId.QUOTATION.toLowerCase()
        ) {
          dispatch(cotizacionUpdate({ data: Data, QuoteHeaderId: id }))
          await updatCustomer(Data.CustomerId) // @todo check if this is necessary or not
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingData(false)
    }
  }

  const validatePreorderInfo = () => {
    sellOrderValidatedRef.current = true
    dispatch({
      type: 'SET_PREORDER_VALIDATION',
      payload: true,
    })
  }

  useEffect(() => {
    validatePreorderInfo()
    if (param?.id && !isRedirect) {
      dispatch({ type: 'SET_CUSTOMER_INFO', payload: null })
      fetchDocument(documentTypeId.ODV, param?.id)
    }
    const payload = {
      TypeDispatchId: dispatchTypeId.domicilio,
      SalesOrderTypeId:
        isCommercialCreditSelected?.credit === 'yes'
          ? salesOrderTypeIds.credito_comercial
          : salesOrderTypeIds.pago_contado,
      PageNumber: 1,
      PageSize: 100,
    }
    dispatch(fullfilmentPlanActionByDelivery(payload))
    fetchWarehouse()
    dispatch({ type: 'SET_IS_REDIRECT', payload: false })
  }, [])

  useEffect(() => {
    if (sellOrderPostData?.Data?.SalesDocumentInfo?.Lines) {
      setHasProductsWithCancel(validateHasProductsWithCancel())
    }
  }, [dispatchQtyState])

  useEffect(() => {
    setApprovedColumnsState(
      approvedColumns({ hasProductsWithCancel, isCustomer })
    )

    setCloseColumnsState(closeColumns(hasProductsWithCancel))
  }, [
    hasProductsWithCancel,
    sellOrderPostData?.Data?.SalesDocumentInfo?.DescriptionStatus,
  ])

  useEffect(() => {
    dispatch({ type: 'SELL_ORDER_POST_LOADING', payload: false })
    dispatch({ type: 'DISPATCH_ORDER_POST_LOADING', payload: false })
    const setProductValidation = productsData.map((item, index) => {
      const cantidad = parseFloat(item?.cantidad || 0)
      const qtyDelivered = parseFloat(item?.qtyDelivered || 0)
      const qtyCanceled = parseFloat(item?.qtyCanceled || 0)

      const pendiente = FormatMoney(
        cantidad - qtyDelivered - qtyCanceled,
        false,
        4
      ).replace(/,/g, '')

      return {
        ...item,
        qtyDelivered,
        qtyCanceled,
        isSelected: false,
        solicitud_despacho: '',
        pendiente,
        cancelados: item?.qtyCanceled || null,
      }
    })

    setDispatchQtyState(setProductValidation)
  }, [useSelectorProducts, sellOrderPostData])

  const location = window.location.href

  const allProductsWithNegativeMargin = useMemo(
    () =>
      useSelectorQuoteObject?.QuoteHeader?.Lines?.filter(
        line =>
          line?.Price <
            (line?.FinalPrices?.[1]?.FinalPrice || line?.FinalPrice) &&
          line?.NegotiatedReasonId !== null
      ),
    [useSelectorQuoteObject?.QuoteHeader?.Lines, location]
  )
  const productsWithNegativeMargin = useMemo(
    () =>
      allProductsWithNegativeMargin?.filter(line => {
        const product = productsData?.find(
          product => product?.productId === line?.ProductId
        )
        if (
          product?.cantidad - product?.qtyDelivered - product?.qtyCanceled >
          0
        ) {
          return true
        }
        return false
      }),
    [allProductsWithNegativeMargin]
  )
  const createOrder = useValidatePermission(VAPermissions.createOrder)

  useEffect(() => {
    const checkApprovalState = async () => {
      try {
        const data = await GetApprovalSalesOrder(
          useSelectorQuoteObject?.QuoteHeader?.RelationDoc?.find(
            doc =>
              doc?.DocumentNumber.includes('ODV') ||
              doc?.DocumentTypeId?.toLowerCase().includes(
                documentTypeId?.ODV?.toLowerCase()
              )
          )?.DocHeaderId
        )
        setIsApproved(data?.data?.Data?.[0]?.Approval)
      } catch (error) {
        setIsApproved(true)
      }
    }
    if (
      productsWithNegativeMargin?.length > 0 &&
      location.includes('created')
    ) {
      checkApprovalState()
    }
  }, [productsWithNegativeMargin, location])

  const validateCheckedProduct = () => {
    let validateProductIsSelected = false

    dispatchQtyState.every(item => {
      const quantityDispatch =
        typeof item.solicitud_despacho === 'string'
          ? Number(item.solicitud_despacho.replace(/,/g, ''))
          : item.solicitud_despacho

      if (
        item.isSelected === true &&
        quantityDispatch <= item.cantidad &&
        quantityDispatch > 0
      ) {
        validateProductIsSelected = true
        return true
      } else {
        if (
          item.isSelected === true &&
          !(quantityDispatch <= item.cantidad && quantityDispatch > 0)
        ) {
          validateProductIsSelected = false
          return false
        } else {
          return true
        }
      }
    })

    return validateProductIsSelected
  }

  const getArrayHiddenColumns = hiddenColumns => {
    const hiddenColumnsCopy = hiddenColumns.filter(column =>
      column.Status === false ? column.ColumnName : false
    )
    return hiddenColumnsCopy.map(column => column.ColumnName)
  }
  const buttonChange =
    sellOrderValidated && param?.isPreorderValidatedOnRoute === 'created'
      ? createDispatch
      : createOrder

  const columns = useMemo(() => {
    const productsPending = dispatchQtyState.filter(
      p => p.qtyDelivered !== p.cantidad
    )

    if (sellOrderValidated && param?.isPreorderValidatedOnRoute === 'created') {
      if (!productsPending?.length) {
        return closeColumnsState
      }
      return approvedColumnsState
    } else {
      return unaprovedColumnsState
    }
  }, [
    sellOrderValidated,
    approvedColumnsState,
    unaprovedColumnsState,
    hasProductsWithCancel,
    dispatchQtyState,
  ])

  useEffect(() => {}, [dispatchQtyState])

  const handleOpen = state => {
    setShowModal(state)
  }

  const fetchCustomerOnUpdate = async () => {
    const customerData = await dispatch(getCustomerById(clienteId))
    if (customerData) {
      dispatch({ type: 'SET_CUSTOMER_INFO', payload: customerData })
      setShowAddAddressState(false)
    }
  }

  const validateSelectedProductsToDispatch = valueProducts => {
    const arrayOfSelectedProducts = { ...useSelectorProducts, Lines: [] }

    valueProducts.forEach((productItem, index) => {
      const quantityDispatch =
        typeof productItem.solicitud_despacho === 'string'
          ? Number(productItem.solicitud_despacho.replace(/,/g, ''))
          : productItem.solicitud_despacho

      if (
        productItem.isSelected === true &&
        quantityDispatch <= productItem.cantidad &&
        quantityDispatch > 0
      ) {
        arrayOfSelectedProducts.Lines.push({
          sellOrderTableValues: productItem,
          ...useSelectorProducts.Lines[index],
        })
      }
    })
    return arrayOfSelectedProducts
  }

  const validateOrderInfo = () => {
    if (validateCheckedProduct()) {
      setShowModal(!showModal)
      dispatch({
        type: 'SET_SELL_ORDER_OBJECT',
        payload: validateSelectedProductsToDispatch(dispatchQtyState),
      })
    } else {
      setIsCartEmpty(true)
    }
  }

  const onValidateOrderModals = () => {
    return (
      <>
        {showAddAddressState ? (
          <Modal
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: window.innerWidth - 491,
              alignItems: 'center',
              zIndex: 999999999999999,
            }}
            onClose={() => {
              setShowAddAddressState(false)
            }}
            open={showAddAddressState}>
            <>
              <FormDireccion
                setSelectedAddress={() => {}}
                setDireccion={() => {}}
                isModalScreen={true}
                handleMetodoEntrega={fetchCustomerOnUpdate}
              />
            </>
          </Modal>
        ) : null}
        <div className={showModal ? 'moda-background' : ''}>
          <DirectDeliveryModal
            data={dispatchQtyState}
            isDirectDelivery={isDirectDelivery}
            setListOfNewCosts={setListOfNewCosts}
            listOfNewCosts={listOfNewCosts}
          />
          <RightSetLocationModal
            branchesData={branchesState}
            fullfilmentData={fullfilmentResponse}
            handleOpen={handleOpen}
            open={showModal}
            deployAddAddres={deployedValue => {
              setShowAddAddressState(deployedValue)
            }}
            data={dispatchQtyState}
            isDirectDelivery={isDirectDelivery}
            setIsDirectDelivery={setIsDirectDelivery}
            listOfNewCosts={listOfNewCosts}
            webOdvDetails={webOdvDetails}
          />
        </div>
        <GenericWarningModal
          validateIsCartEmpty={isCartEmpty}
          setValidateIsCartEmpty={setIsCartEmpty}
          textSelected={t('SELL_ORDER_T.GENERIC_TEXT_WARNING')}
        />
        <ModalCancelPendingDispatch
          openModalCancelPendingDispatchState={
            openModalCancelPendingDispatchState
          }
          setOpenModalCancelPendingDispatchState={
            setOpenModalCancelPendingDispatchState
          }
          processCancelDispatch={value => {
            setOpenModalCancelPendingDispatchState(value)
          }}
          setCancelDispatchProductSelected={value =>
            setCancelDispatchProductSelected(value)
          }
          saveCancelDispatch={reasonId =>
            saveChangeCancelDispatch(
              cancelDispatchProductSelected.productId,
              reasonId
            )
          }
        />
      </>
    )
  }

  const populateItemsArrayOfPayload = () => {
    const arrayOfItems = []

    useSelectorQuoteObject?.QuoteHeader?.Lines.forEach(lineItem => {
      const payloadFormation = {
        productId: lineItem?.ProductId,
        priceListId: lineItem?.PriceListId,
        warehouseId: useSelectorQuoteObject?.QuoteHeader?.WarehouseId,
        quantity: lineItem?.Quantity,
        amount: lineItem?.Amount,
        amountWithTax: lineItem?.AmountWithTax,
        BasePrice: lineItem?.BasePrice,
        DiscountAmount: lineItem?.DiscountAmount,
        FinalPrice: lineItem?.FinalPrice,
        tax: lineItem?.Tax,
        cost: lineItem?.Cost,
        price: lineItem?.Price,
        lineNum: lineItem?.LineNum,
        qtyOrdered: lineItem?.Quantity,
        qtyDelivered: 0,
        qtyCanceled: 0,
        qtyOutstanding: 0,
        isDirectDelivery: lineItem?.IsDirectDelivery,
      }

      const cleanEntries = Object.fromEntries(
        Object.entries(payloadFormation).filter(([_, v]) => v != null)
      )

      arrayOfItems.push(cleanEntries)
    })

    return arrayOfItems
  }

  const generateSaleOrderDocument = async () => {
    const isCreditCommercial = isCommercialCreditSelected?.credit === 'yes'

    const createSaleOrderPayload = {
      documentTypeId: ID_CONSTANTS.salesOrder.sellOrderTypeID,
      salesOrder: {
        customerId: useSelectorQuoteObject?.QuoteHeader?.CustomerId,
        customerNameDocument:
          useSelectorQuoteObject?.QuoteHeader?.CustomerFullName,
        salesAgentId: useSelectorQuoteObject?.QuoteHeader?.SalesAgentId,
        DocumentDate: useSelectorQuoteObject?.QuoteHeader?.DocumentDate,
        quantity: useSelectorQuoteObject?.QuoteHeader?.Quantity,
        amount: useSelectorQuoteObject?.QuoteHeader?.Amount,
        amountWithTax: useSelectorQuoteObject?.QuoteHeader?.AmountWithTax,
        tax: useSelectorQuoteObject?.QuoteHeader?.Tax,
        cost: useSelectorQuoteObject?.QuoteHeader?.Cost,
        companyId: useSelectorQuoteObject?.QuoteHeader?.CompanyId,
        companyCode: useSelectorQuoteObject?.QuoteHeader?.CompanyCode,
        quoteHeaderId: useSelectorQuoteObject?.QuoteHeader?.QuoteHeaderId,
        salesOrderTypeId:
          isCommercialCreditSelected?.credit === 'yes'
            ? salesOrderTypeIds.credito_comercial
            : salesOrderTypeIds.pago_contado,
        HeaderStatusId: ID_CONSTANTS.salesOrder.headerStatusSalesOrderID,
        isDirectDelivery: directdeliveryState ? 1 : 0,
        salesOrderStatusId: ID_CONSTANTS.salesOrder.salesOrderStatusID,
        comments: comment,
        salesAgentEmail: null,
        lines: populateItemsArrayOfPayload(),
        warehouseId: useSelectorQuoteObject?.QuoteHeader?.WarehouseId,
        origin: 'VA',
        CreditAccountId: isCreditCommercial
          ? isCommercialCreditSelected?.accountItemSelected?.CreditAccountId
          : null,
        CreditAccountNumber: isCreditCommercial
          ? isCommercialCreditSelected?.accountItemSelected?.CreditAccountNumber
          : null,
        OrderNumber: isCreditCommercial
          ? isCommercialCreditSelected?.orderNumber
          : null,
      },
    }
    const result = await dispatch(
      generateSellOrderAction(createSaleOrderPayload)
    )

    if (result.message !== 'ERROR') {
      if (sellOrderFileAttached) {
        await uploadFile({
          file: sellOrderFileAttached,
          docData: result.Data.SalesDocumentInfo,
        })
      }
      dispatch({
        type: 'SET_PREORDER_VALIDATION',
        payload: true,
      })

      dispatch({
        type: 'SET_IS_DIRECT_DELIVERY',
        payload: directdeliveryState,
      })

      dispatch({
        type: 'SET_IS_REDIRECT',
        payload: false,
      })

      const route = `/orden_de_venta/created/${result.Data.SalesDocumentInfo?.DocHeaderId}`
      history.push(route)
    } else {
      setShowFailedSellOrderState(true)
    }
  }

  useEffect(() => {
    const getOdvDetails = async saleOrderId => {
      try {
        const data = await fetchGetOdvDetails({
          saleOrderId: sellOrderPostData?.Data?.SalesDocumentInfo?.DocHeaderId,
        })
        data?.Data?.[0] && setWebOdvDetails(data?.Data?.[0])
      } catch (error) {
        console.error('Error:', error)
      }
    }
    if (
      isWebDocument &&
      sellOrderPostData?.Data?.SalesDocumentInfo?.DocHeaderId
    ) {
      getOdvDetails(sellOrderPostData?.Data?.SalesDocumentInfo?.DocHeaderId)
    }
  }, [isWebDocument, sellOrderPostData?.Data?.SalesDocumentInfo?.DocHeaderId])

  const onFailCreatingSellOrderToast = () => {
    return (
      <Modal
        open={showFailedSellOrderState}
        onClose={() => {
          setShowFailedSellOrderState(false)
        }}>
        <Box>
          <GenericModal
            hideCheck={true}
            showCaution={true}
            setConfirm={() => {}}
            setIsOpen={() => setShowFailedSellOrderState(false)}
            text={'No se ha podido crear la orden de venta'}
            mostrarButtons={false}></GenericModal>
        </Box>
      </Modal>
    )
  }

  const isDocumentCanceledOrClosed =
    sellOrderPostData?.Data?.SalesDocumentInfo?.DescriptionStatus ===
      docHeaderStatusDescription.closed ||
    sellOrderPostData?.Data?.SalesDocumentInfo?.DescriptionStatus ===
      docHeaderStatusDescription.canceled

  return (
    <>
      <Navigation />
      {loadingData ? (
        <MiniLoading full={true} noMargin={true} />
      ) : (
        <>
          {productsWithNegativeMargin.length > 0 && !isApproved && (
            <Modal open={closeWarningModal}>
              <Box
                className="modal-box-message"
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <img
                  src={No}
                  alt="close"
                  onClick={() => {
                    setCloseWarningModal(false)
                  }}
                  style={{
                    position: 'absolute',
                    top: '25px',
                    right: '30px',
                    cursor: 'pointer',
                  }}
                />
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'semibold',
                  }}>
                  La orden de venta requiere aprobación porque hay productos con
                  precios otorgados por debajo del costo del producto
                </p>
              </Box>
            </Modal>
          )}

          <div className="sell-order-generada-container">
            <div className="sell-order-generada">
              <div className="sell-order-editar-header">
                <h2 className="titulo-sell-order">
                  {t('SELL_ORDER_T.SELL_ORDER_TITLE')}
                </h2>
              </div>
              <div className="sell-order-info-gen">
                <div className="sell-order-info-gen-cliente">
                  {sellOrderValidated &&
                  param?.isPreorderValidatedOnRoute === 'created' ? (
                    <p className="sell-order-info-gen-text">
                      Orden N°:{' '}
                      {
                        sellOrderPostData?.Data?.SalesDocumentInfo
                          ?.DocumentNumber
                      }
                    </p>
                  ) : null}
                  <p className="sell-order-info-gen-text">
                    {`Cliente: ${useSelectorProducts.CustomerNameDocument}`}
                  </p>
                  <p className="sell-order-info-gen-text">
                    {t('SELL_ORDER_T.EMAIL_LABEL', {
                      email:
                        useSelectorProducts?.CustomerEmail ||
                        useSelectorProducts.EmailAddress ||
                        t('SELL_ORDER_T.NO_INFO_LABEL'),
                    })}
                  </p>
                  <p className="sell-order-info-gen-text">
                    {t('SELL_ORDER_T.PHONE_LABEL', {
                      phone:
                        useSelectorProducts.Cellphone_Number ||
                        useSelectorProducts?.Telephone_Number ||
                        t('SELL_ORDER_T.NO_INFO_LABEL'),
                    })}
                  </p>
                  <p className="sell-order-info-gen-text">
                    {t('CREDIT_ACCOUNT', {
                      account:
                        sellOrderPostData?.Data?.SalesDocumentInfo
                          ?.CreditAccountNumber ||
                        isCommercialCreditSelected?.accountItemSelected
                          ?.CreditAccountNumber ||
                        t('SELL_ORDER_T.NO_INFO_LABEL'),
                    })}
                  </p>
                </div>
                <div className="sell-order-info-gen-retiro">
                  {showHomeDeliveryAddress ? (
                    <>
                      <p className="sell-order-info-gen-text">
                        {t('SELL_ORDER_T.DELIVERY_HOME')}
                      </p>
                      <p className="sell-order-info-gen-text">
                        {t('SELL_ORDER_T.ADDRESS_LABEL', {
                          address:
                            useSelectorProducts?.QuoteAddressStreet ||
                            useSelectorProducts?.CustomerAddress,
                        })}
                      </p>
                      {useSelectorProducts?.QuoteDistrictName && (
                        <p className="sell-order-info-gen-text">
                          {`${useSelectorProducts.QuoteDistrictName}, ${useSelectorProducts.QuoteCountryName}`}
                        </p>
                      )}
                      <p className="sell-order-info-gen-text">{`Alias: ${
                        isWebDocument
                          ? selectedAddressData?.Name ||
                            useSelectorProducts.QuoteAddressDetail
                          : useSelectorProducts.QuoteAddressDetail
                      }`}</p>
                    </>
                  ) : (
                    <>
                      <p className="sell-order-info-gen-text">
                        {t('SELL_ORDER_T.BRANCH_RECOLECTION_LABEL')}
                      </p>
                      <p className="sell-order-info-gen-text">
                        {t('SELL_ORDER_T.ADDRESS_LABEL', {
                          address: useSelectorProducts.WarehouseAddress,
                        })}
                      </p>
                      <p className="sell-order-info-gen-text">
                        {useSelectorProducts.WarehouseDescription}
                      </p>
                    </>
                  )}
                </div>
                <div className="sell-order-info-gen-vendedor">
                  <p className="sell-order-info-gen-text">
                    {/* {t("SELL_ORDER_T.SELLER_LABEL")} */}
                    {useSelectorProducts?.SalesAgentFullName}
                  </p>
                  <p className="sell-order-info-gen-text">
                    {t('SELL_ORDER_T.DATE_LABEL', {
                      date: useSelectorProducts?.CreatedDate?.slice(
                        0,
                        19
                      ).replace('T', ' '),
                    })}
                  </p>
                  <p className="sell-order-info-gen-text">
                    {t('SELL_ORDER_T.QUOTATION_LABEL', {
                      quotation: useSelectorProducts.DocumentNumber,
                    })}
                  </p>
                  <p className="sell-order-info-gen-text">
                    {t('SELL_ORDER_T.CHANNEL_USED_LABEL', {
                      channel_used: useSelectorProducts.Channel || '',
                    })}
                  </p>
                  <DocumentStatusSelector
                    documentData={sellOrderPostData?.Data?.SalesDocumentInfo}
                    odvStatus={null}
                  />
                </div>
              </div>

              {sellOrderValidated &&
              param?.isPreorderValidatedOnRoute === 'created' ? (
                <div className="sell-order-advice">
                  <p className="info-advice-text-title">
                    {t('SELL_ORDER_T.NOTE_LABEL')}
                  </p>
                  <p className="info-advice-text">
                    {t('SELL_ORDER_T.WARNING_TEXT')}
                  </p>
                </div>
              ) : null}

              <TableOrdenDeVenta
                data={dispatchQtyState}
                columns={columns}
                hideFooter={true}
                isProductPage={false}
                isInventory={false}
                setDispatchQtyState={setDispatchQtyState}
                isApproved={isApproved}
                setIsApproved={setIsApproved}
                hiddenColumns={getArrayHiddenColumns(hiddenColumns)}
                selectAllProducts={selectedValue => {
                  const baseProduct = dispatchQtyState
                  baseProduct.forEach((item, i) => {
                    baseProduct[i] = { ...item, isSelected: !selectedValue }
                  })

                  setDispatchQtyState([...baseProduct])
                }}
                changeSelectedStatus={index =>
                  setDispatchQtyState([
                    ...dispatchQtyState.slice(0, index),
                    {
                      ...dispatchQtyState[index],
                      isSelected: !dispatchQtyState[index]?.isSelected,
                    },
                    ...dispatchQtyState.slice(index + 1),
                  ])
                }
                setChangeQty={value => setDispatchQtyState(value)}
                onDeleteProductCallback={value => {
                  setCancelDispatchProductSelected(value)
                  setOpenModalCancelPendingDispatchState(true)
                }}
              />
              {param?.isPreorderValidatedOnRoute === 'created' &&
              useSelectorQuoteObject?.QuoteHeader?.DocumentDueDate &&
              useSelectorQuoteObject?.QuoteHeader?.SalesAgentFullName ? (
                <div className="date-time-updated mt-5">
                  <p>
                    Modificado por:{' '}
                    {useSelectorQuoteObject?.QuoteHeader?.SalesAgentFullName}
                  </p>
                  <p>
                    Fecha de última modificación:{' '}
                    {formatDate(
                      useSelectorQuoteObject?.QuoteHeader?.DocumentDueDate
                    )}
                  </p>
                </div>
              ) : null}
            </div>

            <Container className="footer-container">
              <Row className="footer-info">
                <Col>
                  <Row className="footer-left-text-title">
                    {t('SELL_ORDER_T.RELATED_DOCS')}
                  </Row>
                  {sellOrderPostData?.Data?.SalesDocumentInfo?.RelationDoc?.map(
                    (item, index) => (
                      <Row className="footer-left-text" key={index}>
                        <RelationalDocLabel
                          value={item}
                          document={sellOrderPostData?.Data?.SalesDocumentInfo}
                        />
                      </Row>
                    )
                  )}
                </Col>
                <Col>
                  <Row className="footer-right-text-title">
                    {t('SELL_ORDER_T.ADD_COMMENTS')}
                  </Row>

                  <Row className="footer-right-row-container">
                    <textarea
                      className="footer-textarea-style"
                      value={comment || ''}
                      disabled={isDocumentCanceledOrClosed}
                      onChange={e => setComment(e.target.value)}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>

            <div className="save-button-footer-container">
              {sellOrderPostLoading ? (
                <button className={'sell-order-save-button-footer'}>
                  <CircularProgress size={20} />
                </button>
              ) : (
                buttonChange &&
                dispatchQtyState.filter(p => p.qtyDelivered !== p.cantidad)
                  .length > 0 && (
                  <button
                    className={'sell-order-save-button-footer'}
                    // disabled={!isApproved}
                    onClick={async () => {
                      if (
                        sellOrderValidated &&
                        param?.isPreorderValidatedOnRoute === 'created'
                      ) {
                        validateOrderInfo()
                      } else {
                        await generateSaleOrderDocument()
                      }
                    }}>
                    {sellOrderValidated &&
                    param?.isPreorderValidatedOnRoute === 'created'
                      ? t('SELL_ORDER_T.GENERATE_DISPATCH_SLOICITUDE')
                      : t('SELL_ORDER_T.CONTINUE_BUTTON')}
                  </button>
                )
              )}
            </div>
          </div>
          {sellOrderValidated &&
          param?.isPreorderValidatedOnRoute === 'created' ? (
            onValidateOrderModals()
          ) : (
            <>
              <>{onFailCreatingSellOrderToast()}</>
            </>
          )}
        </>
      )}
    </>
  )
}

export default OrdenDeVenta
