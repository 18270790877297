import React, { useState } from 'react'
import './navigation.css'
import VerticalNav from '../VerticalNav'
import HorizontalNav from '../HorizontalNav'
import Overlay from 'Components/Overlay'

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [drawerWidth, setDrawerWidth] = useState(70)
  const [selectedOption, setSelectedOption] = useState(false)
  const [menuOpenItem, setMenuOpenItem] = useState([])

  const handleDrawer = () => {
    menuOpenItem.includes(selectedOption.title)
      ? setIsOpen(false)
      : setIsOpen(!isOpen)

    setMenuOpenItem([])
    setSelectedOption(false)
    if (!isOpen) {
      setDrawerWidth(320)
    } else {
      setDrawerWidth(70)
    }
  }
  return (
    <>
      <div className="general-navigation">
        <VerticalNav
          isOpen={isOpen}
          drawerWidth={drawerWidth}
          handleDrawer={handleDrawer}
          setIsOpen={setIsOpen}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          menuOpenItem={menuOpenItem}
          setMenuOpenItem={setMenuOpenItem}
        />
        <Overlay
          isOpen={isOpen || menuOpenItem.includes(selectedOption.title)}
          handleDrawer={handleDrawer}
        />
        <HorizontalNav home={null} handleDrawer={handleDrawer} />
      </div>
    </>
  )
}

export default Navigation
