import { updateOdvWebStatus } from 'Api/services/busServices'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  colorsStatus,
  listOfStatus,
} from 'Views/OrdenDeVenta/constants/odvStatus'
import { CustomSelector } from 'Views/Printer/components/CustomSelector'

export const DocumentStatusSelector = ({ documentData, odvStatus }) => {
  const dispatch = useDispatch()
  const isWebDocument = documentData?.Origin === 'AB'
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = React.useState({
    value: documentData?.DescriptionStatus,
    key: documentData?.DocHeaderStatusId,
  })

  const filterList = DocHeaderStatusId => {
    let listWithExtraData = []
    const docStatusIds = {
      partialDelivery: '93b7c0b7-1fd3-4851-8e3b-0ebf41b4497b',
      readyToDeliver: '399ad404-7605-4381-880a-aea2d40ef05d',
      readyToPickUp: '4c2888e9-3ba2-47b8-9731-23133edb053e',
      inTransit: '54056da2-3e95-4e8b-8416-6ed08b80c77b',
    }
    const index = listOfStatus.findIndex(
      status => status?.key?.toLowerCase() === DocHeaderStatusId?.toLowerCase()
    )
    if (DocHeaderStatusId?.toLowerCase() === docStatusIds.partialDelivery) {
      listWithExtraData = listOfStatus.filter(status =>
        [
          docStatusIds.readyToDeliver,
          docStatusIds.readyToPickUp,
          docStatusIds.inTransit,
        ].includes(status?.key?.toLowerCase())
      )
    }
    if (index === -1) return []
    return [...listWithExtraData, ...listOfStatus.slice(index)]
  }

  const filteredStatus = filterList(documentData?.DocHeaderStatusId)

  const notEditable =
    documentData?.DescriptionStatus === 'Cerrada' ||
    documentData?.DescriptionStatus === 'Cancelada'

  const status = () => {
    const status = documentData?.DescriptionStatus
    return status === 'En proceso de despacho'
      ? colorsStatus.pending
      : status === 'Cerrada'
      ? colorsStatus.closed
      : colorsStatus.process
  }

  const handleOptionChange = async option => {
    setSelectedOption(option)
    try {
      setLoading(true)

      await updateOdvWebStatus({
        saleOrderId: documentData?.DocHeaderId,
        headerStatusId: option.key,
        statusName: option.value,
      })
      dispatch({
        type: 'SELL_ORDER_STATUS',
        payload: {
          value: option.value,
          key: option.key,
        },
      })
    } catch (error) {
      console.error('Error:', error)
      setSelectedOption({
        value: documentData?.DescriptionStatus,
        key: documentData?.DocHeaderStatusId,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {isWebDocument && (
        <div style={{ width: '100%', marginTop: 10 }}>
          <CustomSelector
            placeholder="Selecciona qué desea visualizar"
            options={filteredStatus}
            selectedOption={selectedOption || {}}
            handleOption={handleOptionChange}
            disabled={notEditable}
            loading={loading}
            styles={{
              width: '100%',
              padding: '0 14px',
              height: '35px',
            }}
          />
        </div>
      )}
      {!isWebDocument && documentData?.DescriptionStatus && (
        <>
          <p
            className="status"
            style={{
              backgroundColor: status(),
              marginRight: 10,
            }}>
            {documentData.DescriptionStatus}
          </p>
          {odvStatus && (
            <p
              className="status"
              style={{ backgroundColor: colorsStatus.pending }}>
              {'Cambio de precio pendiente'}
            </p>
          )}
        </>
      )}
    </>
  )
}
