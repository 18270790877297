import React from 'react'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { Square1 } from './cmf/Square1'
import { Square2 } from './cmf/Square2'
import { Square3 } from './cmf/Square3'
import { Square1NRegular } from './regular/Square1'
import { Square2NRegular } from './regular/Square2'
import { Square1NOffer } from './offer/Square1'
import { Square2NOffer } from './offer/Square2'
import { Square1NCmfOffer } from './cmfOffer/Square1'
import { Square2NCmfOffer } from './cmfOffer/Square2'
import { Square3NCmfOffer } from './cmfOffer/Square3'
import { Square3NRegular } from './regular/Square3'
import { Square3NOffer } from './offer/Square3'
import { Square1Special } from '../cochez/special/Square1'
import { Square2Special } from '../cochez/special/Square2'
import {
  OFFER,
  OFFER_CMF,
  REGULAR,
  REGULAR_CMF,
  SPECIAL_CMF,
} from '../cochez/CochezTemplates'

export const NoveyTemplates = ({ products, i }) => {
  const {
    currentPage,
    allTemplatesContent,
    showAllTemplates,
    selectedTemplateType,
  } = usePrinterContext()

  const isCurrentPage = products.page === currentPage
  if (!isCurrentPage && !showAllTemplates) return null

  const currentTemplate = allTemplatesContent.find(
    content => content.page === currentPage
  )?.template?.[0]

  const isRegCMF = selectedTemplateType?.template === REGULAR_CMF
  const isOfferCMF = selectedTemplateType?.template === OFFER_CMF
  const isReg = selectedTemplateType?.template === REGULAR
  const isOffer = selectedTemplateType?.template === OFFER
  const isSpecialCMF = selectedTemplateType?.template === SPECIAL_CMF

  if (isOfferCMF) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1NCmfOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2NCmfOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-3' && (
          <Square3NCmfOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-4' && (
          <Square3NCmfOffer i={i} products={products} />
        )}
      </>
    )
  }
  if (isReg) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1NRegular i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2NRegular i={i} products={products} />
        )}
        {currentTemplate === 'square-3' && (
          <Square3NRegular i={i} products={products} />
        )}
        {currentTemplate === 'square-4' && (
          <Square3NRegular i={i} products={products} />
        )}
      </>
    )
  }
  if (isOffer) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1NOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2NOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-3' && (
          <Square3NOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-4' && (
          <Square3NOffer i={i} products={products} />
        )}
      </>
    )
  }

  if (isRegCMF) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1 i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2 i={i} products={products} />
        )}
        {currentTemplate === 'square-3' && (
          <Square3 i={i} products={products} />
        )}
        {currentTemplate === 'square-4' && (
          <Square3 i={i} products={products} />
        )}
      </>
    )
  }
  if (isSpecialCMF) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1Special i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2Special i={i} products={products} />
        )}
      </>
    )
  }
  return <div data-testid="novey-template" />
}
