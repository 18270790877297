import React, { useState, useEffect } from 'react'
import {
  CheckCircleFill,
  PencilSquare,
  XCircleFill,
} from 'react-bootstrap-icons'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'

export const TermsTextArea = ({
  prod,
  styles,
  defaultValue,
  rows = 5,
  size = 12,
  className = 'printer-preview-left-content-footer-square-2-legal',
}) => {
  const { terms, setTerms } = usePrinterContext()
  const [value, setValue] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  const handleEditTerms = () => {
    const hasTerms = terms.some(term => term?.prodId === prod.ProductId)
    const newTerms = hasTerms
      ? terms.map(term => {
          if (term?.prodId === prod.ProductId) {
            return {
              prodId: prod.ProductId,
              value,
            }
          }
          return term
        })
      : [
          ...terms,
          {
            prodId: prod.ProductId,
            value,
          },
        ]

    setTerms(newTerms)
    setIsEditing(false)
  }

  const handleResetTerms = () => {
    setValue(defaultValue)
    setIsEditing(false)
  }

  useEffect(() => {
    const term = terms.find(term => term?.prodId === prod?.ProductId)
    if (term) {
      setValue(term.value)
    } else {
      setValue(defaultValue)
    }
  }, [terms, defaultValue])

  return (
    <>
      <textarea
        className={className}
        type="text"
        rows={rows}
        value={value || defaultValue}
        disabled={!isEditing}
        onChange={e => setValue(e.target.value)}
        style={{
          border: isEditing ? '1px solid #000' : 'none',
          background: 'transparent',
          ...styles,
        }}
      />
      <div className="printer-terms-edit-wrapper-1">
        {isEditing && (
          <CheckCircleFill
            data-testid="confirmEdit"
            onClick={handleEditTerms}
            size={size}
            className="printer-preview-content-check"
          />
        )}
        {isEditing && (
          <XCircleFill
            size={size}
            onClick={handleResetTerms}
            className="printer-preview-content-circle"
          />
        )}
        {!isEditing && (
          <PencilSquare
            size={size}
            data-testid="pencil"
            onClick={() => setIsEditing(true)}
            className="printer-preview-content-pencil"
          />
        )}
      </div>
    </>
  )
}
