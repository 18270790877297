import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import no from '../../Assets/Images/Login/no.svg'
import search from '../../Assets/Images/BuscarCotizacion/search.svg'
import { ReactComponent as IconCarrito } from '../../Assets/Images/Generals/carrito_entrega.svg'
import { ReactComponent as ShopCarrito } from '../../Assets/Images/Generals/shop.svg'
import { GeoAltFill, CheckCircleFill, Circle } from 'react-bootstrap-icons'
import './rightSetLocationModal.css'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import es from 'dayjs/locale/es'
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Modal,
  TextField,
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { ID_CONSTANTS } from 'redux/types'
import {
  generateDispatchOrderAction,
  validateAmountOnCommercialCredit,
} from 'redux/actions/sellOrderActions'
import GenericModal from 'Components/GenericModal'
import SellOrderDeliveryWarning from 'Components/SellOrderDeliveryWarning'
import CheckDeliveryCostsModal from 'Components/CheckDeliveryCostsModal'
import CheckIsDDAvaliableModal from 'Components/CheckIsDDAvaliableModal'
import {
  fullfilmentPlanActionByDelivery,
  supplierAction,
} from 'redux/actions/catalogActions'
import {
  ROUTES,
  companiesCode,
  customerTypeId,
  dispatchTypeId,
  salesOrderTypeIds,
  VAPermissions,
} from 'Utils/constants'
import styles from './rightSetLocationModal.module.css'
import Loading from 'Components/Loading'
import useValidatePermission from 'Hooks/useValidatePermission'
import { useDebounce } from 'Hooks/useDebounce'
import { FormatMoney } from 'Utils/functions'
import { useValidateLocation } from './hooks/useValidateLocation'

const defaultSupplier = {
  fulfillmentPlanId: '13CEDD9C-A262-4728-AD0E-78BF6565A978',
  fulfillmentPlanDescription: 'ENTREGA DIRECTA D1',
}

function RightSetLocationModal({
  handleOpen,
  open,
  deployAddAddres,
  fullfilmentData,
  branchesData,
  data,
  isDirectDelivery,
  setIsDirectDelivery,
  listOfNewCosts,
  webOdvDetails,
}) {
  const dispatchQtyState = data
  const dispatch = useDispatch()
  const { checkSelectedLocation } = useValidateLocation()
  const [openDropdown, setOpenDropdown] = useState({
    selectBranchOffice: false,
    dispatchTypeBranchOffice: false,
    dispatchTypeDomicile: false,
    selectSupplier: false,
  })
  const [location, setLocation] = useState(null)
  const [seeMoreState, setSeeMoreState] = useState(false)
  const [deliveryMethodState, setDeliveryMethodState] = useState('DEL')
  const [showDeliveryTypeState, setShowDeliveryTipeState] = useState(false)
  const [deliveryTypeState, setDeliveryTypeState] = useState(null)
  const [showSucState, setShowSucState] = useState(false)
  const [sucState, setSucState] = useState(null)
  const [supplier, setSupplier] = useState(null)
  const [deliveryDateState, setDeliveryDateState] = useState(null)
  const history = useHistory()
  const [isNotValidDelivState, setIsNotValidDelivState] = useState(false)
  const [isNotValidCostState, setIsNotValidCostState] = useState(false)
  const [isNotValidDDProductState, setIsNotValidDDProductState] =
    useState(false)

  const [showFailedSellOrderState, setShowFailedSellOrderState] =
    useState(false)

  const [showFailedCreditAccState, setShowFailedCreditAccState] =
    useState(false)

  const customerSavedLocations = useSelector(
    state => state.sellOrder.customerInfoObject
  )

  const deliveryMethod = useValidatePermission(VAPermissions.deliveryMethod)
  const { loadingSupplierProducts, supplierProduct } = useSelector(
    state => state.catalog
  )

  const user = useSelector(state => state.login.user)
  const [dispatchTypeSelected, setDispatchTypeSelected] = useState(
    '' + dispatchTypeId.domicilio
  )
  const { sellOrderObject, sellOrderPostData, isCommercialCreditSelected } =
    useSelector(state => state.sellOrder)
  const isWebDocument =
    sellOrderPostData?.Data?.SalesDocumentInfo?.Origin === 'AB'
  const [sendingSubmit, setSendingSubmit] = useState(false)
  const theme = createTheme({
    typography: {
      fontSize: 20,
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            color: '#003082',
          },
        },
      },
    },
  })

  useEffect(() => {
    const productIds = data
      .filter(p => p.solicitud_despacho > 0)
      .map(p => p.productId)

    if (isDirectDelivery) dispatch(supplierAction({ ProductIds: productIds }))
  }, [isDirectDelivery])

  useEffect(() => {
    setDeliveryTypeState(fullfilmentData)
  }, [fullfilmentData, isDirectDelivery])

  useEffect(() => {
    setDeliveryTypeState(fullfilmentData)
  }, [fullfilmentData, isDirectDelivery])

  const closeBar = () => {
    handleOpen(false)
    setIsDirectDelivery(false)
    setDeliveryDateState(null)
    setDeliveryTypeState(null)
    setLocation(null)
  }

  const onKeyDown = e => {
    e.preventDefault()
  }

  const changeMethod = type => {
    setDeliveryMethodState(type)
    setDeliveryTypeState('')
    setSeeMoreState(false)
    setDeliveryDateState(null)
    setSucState('')
    setLocation(null)
    setIsDirectDelivery(false)
  }

  // Get fulFlimentPlans by IdDelivery
  const onChangeDeliveryMethod = dispatchTypeSelectedP => {
    const payload = {
      TypeDispatchId: dispatchTypeSelectedP,
      SalesOrderTypeId:
        isCommercialCreditSelected?.credit === 'yes'
          ? salesOrderTypeIds.credito_comercial
          : salesOrderTypeIds.pago_contado,
      PageNumber: 1,
      PageSize: 100,
    }
    dispatch(fullfilmentPlanActionByDelivery(payload))
  }

  useEffect(() => {
    if (isWebDocument && webOdvDetails && open) {
      const isHomeDelivery = webOdvDetails?.DeliveryType === 1
      isHomeDelivery ? handleHomeDeliveryMethod() : handleStoreDeliveryMethod()

      const defaultSucState = branchesData.find(
        suc =>
          suc.WarehouseId?.toLowerCase() ===
          webOdvDetails.WeareHouseId?.toLowerCase()
      )
      defaultSucState?.WarehouseId && setSucState(defaultSucState)

      const date = dayjs(webOdvDetails?.DeliveryDate)
      webOdvDetails?.DeliveryDate && setDeliveryDateState(date)

      const defaultAddress = customerSavedLocations?.AddressList?.find(
        address =>
          address.AddressInfo?.AddressId?.toLowerCase() ===
          webOdvDetails?.AddressId?.toLowerCase()
      )

      defaultAddress && isHomeDelivery && setLocation(defaultAddress)
      defaultAddress && isHomeDelivery && setSeeMoreState(true)
    }
  }, [
    isWebDocument,
    branchesData,
    webOdvDetails,
    customerSavedLocations?.AddressList,
    open,
  ])

  useEffect(() => {
    const isFiltered = !!fullfilmentData?.Data

    if (isWebDocument && webOdvDetails && open && isFiltered) {
      const isHomeDelivery = webOdvDetails?.DeliveryType === 1
      const fullFilmentData = isHomeDelivery
        ? fullfilmentData.Data.filter(
            f =>
              f.FulfillmentPlanId?.toUpperCase() !==
                '13CEDD9C-A262-4728-AD0E-78BF6565A978' ||
              f.FulfillmentPlanId?.toUpperCase() !==
                'E775A02F-E3D0-49B7-90B4-AB7C54166171'
          )
        : fullfilmentData.Data

      const defaultDeliveryType = fullFilmentData?.find(
        f =>
          f.FulfillmentPlanId?.toLowerCase() ===
          webOdvDetails?.TypeOfShipment?.toLowerCase()
      )

      defaultDeliveryType && setDeliveryTypeState(defaultDeliveryType)
    }
  }, [open, fullfilmentData])

  const handleHomeDeliveryMethod = () => {
    changeMethod('DEL')
    setOpenDropdown({
      ...openDropdown,
      selectBranchOffice: false,
      selectSupplier: false,
      dispatchTypeDomicile: false,
      dispatchTypeBranchOffice: false,
    })
    setSucState(null)
    setDispatchTypeSelected(dispatchTypeId.domicilio)
    onChangeDeliveryMethod(dispatchTypeId.domicilio)
  }

  const handleStoreDeliveryMethod = () => {
    changeMethod('BRANCH')
    setOpenDropdown({
      ...openDropdown,
      selectBranchOffice: false,
      selectSupplier: false,
      dispatchTypeDomicile: false,
      dispatchTypeBranchOffice: false,
    })
    setSucState(null)
    setDispatchTypeSelected(dispatchTypeId.retiroTienda)
    onChangeDeliveryMethod(dispatchTypeId.retiroTienda)
  }

  const renderDeliveryMethodButtons = () => {
    return (
      <Row>
        <h2 className="delivery-type-title">
          {t('RIGHT_SELECTION_MODAL.SELECT_DELIVERY_METHOD_TITLE')}
        </h2>
        <Col>
          <Row className="delivery-button-container">
            <button
              className={
                deliveryMethodState === 'DEL'
                  ? 'delivery-button-style-active'
                  : 'delivery-button-style-inactive'
              }
              onClick={handleHomeDeliveryMethod}>
              <IconCarrito
                className={
                  deliveryMethodState === 'DEL'
                    ? 'delivery-method-img'
                    : 'delivery-method-img-inactive'
                }
              />
              <span
                className={
                  deliveryMethodState === 'DEL'
                    ? 'delivery-method-text'
                    : 'delivery-method-text-inactive'
                }>
                {t('RIGHT_SELECTION_MODAL.HOUSE_OPTION')}
              </span>
            </button>
            <button
              className={
                deliveryMethodState === 'BRANCH'
                  ? 'delivery-button-style-active'
                  : 'delivery-button-style-inactive'
              }
              onClick={handleStoreDeliveryMethod}>
              <ShopCarrito
                className={
                  deliveryMethodState === 'BRANCH'
                    ? 'delivery-method-img'
                    : 'delivery-method-img-inactive'
                }
              />
              <span
                className={
                  deliveryMethodState === 'BRANCH'
                    ? 'delivery-method-text'
                    : 'delivery-method-text-inactive'
                }>
                {t('RIGHT_SELECTION_MODAL.BRANCH_OPTION')}
              </span>
            </button>
          </Row>
        </Col>
      </Row>
    )
  }

  const renderDirectionButtons = (item, index) => {
    const isSelectedDirection =
      location?.AddressInfo?.AddressId === item?.AddressInfo?.AddressId

    return (
      <button
        key={index}
        className={
          isSelectedDirection
            ? 'direction-button-style-active'
            : 'direction-button-style-inactive'
        }
        onClick={() => {
          isSelectedDirection ? setLocation(null) : setLocation(item)
        }}>
        <Col className="direction-location-container col-6">
          <div>
            <GeoAltFill
              className={
                isSelectedDirection
                  ? 'pinpoint-location-active'
                  : 'pinpoint-location-inactive'
              }
            />
          </div>
          <div
            className={
              isSelectedDirection
                ? 'direction-text-style'
                : 'direction-text-style-inactive'
            }>
            {item.AddressInfo.Name || item.AddressInfo.AddressDetail}
          </div>
        </Col>
        <Col className="sell-order-column-width">
          {isSelectedDirection ? (
            <CheckCircleFill className={'check-circle-active'} />
          ) : (
            <Circle className={'check-circle-inactive'} />
          )}
        </Col>
      </button>
    )
  }

  const renderLocation = addresses => {
    const sliceAddress = seeMoreState ? addresses : addresses.slice(0, 6)

    return (
      <Row>
        <h2 className="delivery-location-title">
          {t('RIGHT_SELECTION_MODAL.SELECT_DIRECTION')}
        </h2>
        <Col>
          <Row className="direction-button-container">
            {sliceAddress.map((address, index) =>
              seeMoreState || index < 4
                ? renderDirectionButtons(address, index)
                : null
            )}
          </Row>
          {addresses.length > 3 && (
            <Row>
              <div className="see-more-address">
                <p onClick={() => setSeeMoreState(!seeMoreState)}>
                  {seeMoreState
                    ? t('RIGHT_SELECTION_MODAL.SEE_LESS')
                    : t('RIGHT_SELECTION_MODAL.SEE_MORE')}
                </p>
              </div>
            </Row>
          )}
        </Col>
      </Row>
    )
  }

  const renderAddMoreLocation = () => {
    return (
      <Row
        onClick={() => {
          deployAddAddres(true)
        }}
        className="add-direction-container">
        <Col className="add-direction-label-col">
          <label className="add-direction-label-text" htmlFor="">
            {t('RIGHT_SELECTION_MODAL.ADD_NEW_DIRECTION')}
          </label>
        </Col>
        <Col className="add-direction-plus-sign-col">
          <div className="add-direction-plus">+</div>
        </Col>
      </Row>
    )
  }

  const DynamicSelector = ({
    title,
    setShowState,
    showState,
    valueState,
    placeholder,
    mappedInfo,
    controlValue,
    setValueState,
    loading,
    showSearch,
  }) => {
    const [textValue, setTextValue] = useState('')
    const [data, setData] = useState(mappedInfo)
    const debounce = useDebounce()
    const handlerSearch = value => {
      const result = mappedInfo.filter(item =>
        item?.Description?.toUpperCase()?.includes(value.toUpperCase())
      )
      setData(result)
    }

    const hasSelectedValue =
      valueState &&
      (valueState.descriptionFulfillmentPlan ||
        valueState.Description ||
        valueState.ProviderName)
    return (
      <ClickAwayListener
        onClickAway={event => {
          if (
            showState &&
            !event.target?.id !== 'generic-address-filter-content'
          ) {
            setShowState(false)
          }
          if (
            showDeliveryTypeState &&
            event.target?.id !== 'generic-address-filter-content'
          )
            setShowDeliveryTipeState(false)

          if (
            showSucState &&
            event.target?.id !== 'generic-address-filter-content'
          )
            setShowSucState(false)
        }}>
        <div>
          <Row className="no-margin-nopadding generic-address-father-container">
            <div className="generic-address-title-text">{t(title)}</div>
            <div className="no-margin-nopadding generic-address-search-filter">
              <button
                disabled={loading}
                id="generic-address-filter-content"
                className="generic-address-filter-content"
                onClick={() => setShowState(!showState)}>
                <h2
                  onClick={() => setShowState(!showState)}
                  className={
                    hasSelectedValue
                      ? 'generic-address-label-text'
                      : 'generic-address-label-placeholder'
                  }>
                  {hasSelectedValue && mappedInfo
                    ? valueState.descriptionFulfillmentPlan ||
                      valueState.Description ||
                      valueState.ProviderName
                    : !hasSelectedValue && mappedInfo?.length
                    ? t(placeholder)
                    : 'Sin datos'}
                </h2>
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} className="spinner" />
                ) : (
                  <FontAwesomeIcon
                    onClick={() => setShowState(!showState)}
                    icon={faChevronDown}
                    className="generic-address-down-icon"
                  />
                )}
              </button>
              <ul
                className={
                  showState
                    ? 'generic-address-filter-options'
                    : 'generic-address-filter-options-hidden'
                }>
                {showSearch && (
                  <li className="item-search-box-list-price">
                    <div className="item-list-price-li-search">
                      <input
                        onChange={e => {
                          setTextValue(e.target.value)
                          debounce(() => handlerSearch(e.target.value), 500)
                        }}
                        value={textValue}
                        type="text"
                        id="generic-address-filter-content"
                        className="list-price-search"
                        placeholder="Buscar"
                      />
                      <img
                        src={search}
                        className="list-price-search-icon"
                        alt="search"
                        id="generic-address-filter-content"
                        onClick={() => handlerSearch(textValue)}
                      />
                    </div>
                  </li>
                )}
                {data?.map((item, index) => {
                  if (
                    item?.FulfillmentPlanId?.toLowerCase() ===
                    defaultSupplier?.fulfillmentPlanId?.toLowerCase()
                  )
                    return null

                  return (
                    <li
                      className="generic-address-filter-option"
                      key={controlValue + index}>
                      <input
                        type="radio"
                        className="radio"
                        id={controlValue + index.toString()}
                        name="distrito"
                        value={
                          item.descriptionFulfillmentPlan ||
                          item.Description ||
                          item.ProviderName
                        }
                        onClick={values => {
                          setValueState(item)
                          setShowState(false)
                        }}
                      />
                      <label
                        htmlFor={controlValue + index.toString()}
                        className={styles.label}>
                        {item.descriptionFulfillmentPlan ||
                          item.Description ||
                          item.ProviderName}
                      </label>
                    </li>
                  )
                  // }
                })}
              </ul>
            </div>
          </Row>
        </div>
      </ClickAwayListener>
    )
  }

  const renderGenericMultipleSelectForm = ({
    title,
    showState,
    setShowState,
    valueState,
    setValueState,
    mappedInfo,
    placeholder,
    controlValue,
    blockComboBox,
    preselectedValue,
    showSearch,
  }) => {
    return (
      <DynamicSelector
        title={title}
        setShowState={setShowState}
        showState={showState}
        valueState={valueState}
        placeholder={placeholder}
        mappedInfo={mappedInfo}
        controlValue={controlValue}
        setValueState={setValueState}
        showSearch={showSearch}
      />
    )
  }

  const validateForms = () => {
    const validateDeliveryTypeState = deliveryTypeState?.FulfillmentPlanId
    const isValidNewcost = () =>
      !listOfNewCosts.some(line => line.newCost === 0 || line.newCost === '')
    if (
      isDirectDelivery &&
      validateDeliveryTypeState &&
      isValidNewcost() &&
      sucState &&
      deliveryDateState &&
      location &&
      supplier
    ) {
      return true
    } else {
      if (
        // eslint-disable-next-line no-prototype-builtins
        !isDirectDelivery &&
        deliveryMethodState === 'BRANCH' &&
        validateDeliveryTypeState &&
        Object.prototype.hasOwnProperty.call(
          deliveryTypeState,
          'FulfillmentPlanId'
        ) &&
        ((sucState && deliveryDateState) || (location && deliveryDateState))
      ) {
        return true
      }
      if (
        !isDirectDelivery &&
        deliveryMethodState === 'DEL' &&
        validateDeliveryTypeState &&
        sucState &&
        deliveryDateState &&
        location
      ) {
        return true
      }

      return false
    }
  }

  const onFailCreatingSellOrderToast = () => {
    return (
      <Modal
        open={showFailedSellOrderState}
        onClose={() => {
          setShowFailedSellOrderState(false)
        }}>
        <Box>
          <GenericModal
            hideCheck={true}
            showCaution={true}
            setConfirm={() => {}}
            setIsOpen={() => setShowFailedSellOrderState(false)}
            text={t('GENERIC_MODAL.DISPATCH_NO_CREATED')}
            mostrarButtons={false}></GenericModal>
        </Box>
      </Modal>
    )
  }

  const onFailReservationOnCreditAcc = () => {
    return (
      <Modal
        open={showFailedCreditAccState}
        onClose={() => {
          setShowFailedSellOrderState(false)
        }}>
        <Box>
          <GenericModal
            hideCheck={true}
            showCaution={true}
            setConfirm={() => {}}
            setIsOpen={() => setShowFailedCreditAccState(false)}
            text={'La cuenta efectiva no tiene límite de crédito disponible'}
            mostrarButtons={false}></GenericModal>
        </Box>
      </Modal>
    )
  }

  const populateItemsArrayOfPayload = () => {
    const arrayOfItems = []
    sellOrderObject?.Lines.forEach(lineItem => {
      const quantityDispatch =
        typeof lineItem?.sellOrderTableValues?.solicitud_despacho === 'string'
          ? Number(
              lineItem.sellOrderTableValues.solicitud_despacho.replace(/,/g, '')
            )
          : lineItem?.sellOrderTableValues?.solicitud_despacho

      const amountPicked =
        quantityDispatch * lineItem?.sellOrderTableValues?.precio_unitario
      const taxes = sellOrderObject.TaxExempt
        ? 0
        : amountPicked * lineItem?.TaxCategoryValue
      const amountWithTaxPicked = amountPicked + taxes
      const qtyDelivered =
        quantityDispatch + lineItem?.sellOrderTableValues?.qtyDelivered || 0
      const qtyCanceled = lineItem?.sellOrderTableValues?.qtyCanceled || 0

      const newPending = FormatMoney(
        parseFloat(lineItem?.sellOrderTableValues?.cantidad || 0) -
          (parseFloat(qtyDelivered) + parseFloat(qtyCanceled))
      )
      const qtyPending = Number(newPending.replace(/,/g, ''))

      const newProductCost = listOfNewCosts?.find(
        p => p?.productId === lineItem?.ProductId
      )?.newCost
      const cost = parseFloat(newProductCost).toFixed(2)
      const payloadFormation = {
        dispatchRequestLineDetailId: '00000000-0000-0000-0000-000000000000',
        salesOrderHeaderId: sellOrderObject?.salesOrderHeaderId,
        productId: lineItem?.ProductId,
        priceListId: lineItem?.PriceListId,
        warehouseId: sucState?.WarehouseId,
        quantity: quantityDispatch,
        amount: amountPicked,
        amountWithTax: amountWithTaxPicked,
        DiscountAmount: lineItem?.DiscountAmount,
        tax: taxes,
        cost: isDirectDelivery ? Number(cost) : lineItem?.Cost,
        price: lineItem?.Price,
        discount: lineItem?.Discount,
        lineNum: lineItem?.LineNum,
        productSerialNumber: 0,
        createdBy: null,
        lastModifiedBy: null,
        status: 1,
        FulfillmentPlanId: deliveryTypeState.FulfillmentPlanId,
        UoMId: lineItem?.UoMId
          ? lineItem.UoMId
          : '6360326E-23B2-40F0-9F7B-0056EBA3A1CB',
        NegotiatedReasonId: sellOrderObject?.NegotiatedReasonId,
        WarrantyProductId: lineItem?.WarrantyProductId,
        qtyOrdered: lineItem?.sellOrderTableValues?.cantidad,
        qtyDelivered,
        qtyPending,
      }

      const cleanEntries = Object.fromEntries(
        Object.entries(payloadFormation).filter(([_, v]) => v != null)
      )
      arrayOfItems.push(cleanEntries)
    })
    return arrayOfItems
  }

  const callCreditAccountValidations = async (createSaleOrderPayload, e) => {
    let totalAmount = 0
    let totalAmountExent = 0
    // eslint-disable-next-line array-callback-return
    sellOrderObject.Lines.map(line => {
      const quantityDispatch =
        typeof line.sellOrderTableValues.solicitud_despacho === 'string'
          ? Number(
              line.sellOrderTableValues.solicitud_despacho.replace(/,/g, '')
            )
          : line.sellOrderTableValues.solicitud_despacho

      totalAmount +=
        quantityDispatch * line.sellOrderTableValues.precio_unitario
      totalAmountExent +=
        quantityDispatch * line.sellOrderTableValues.precio_unitario
      // Add the taxes
      totalAmount +=
        quantityDispatch *
        line.sellOrderTableValues.precio_unitario *
        line.TaxCategoryValue
    })
    const commercialCreditPayloadConsult = {
      product: 'oracle-credit-commercial-customer',
      no_cia:
        sellOrderObject?.CompanyCode === companiesCode.cochez ? '01' : '03',
      no_cliente:
        sellOrderPostData?.Data?.SalesDocumentInfo?.CreditAccountNumber,
    }

    const response = await dispatch(
      validateAmountOnCommercialCredit(commercialCreditPayloadConsult)
    )

    if (!response.message) {
      const isExent = response.exento.toUpperCase() === 'S'
      const balance = response.limite - response.saldo
      const totalValidate = isExent ? totalAmountExent : totalAmount
      // if is exent change tax, total, and data form lines
      if (isExent) {
        createSaleOrderPayload.dispatchRequest.tax = 0
        createSaleOrderPayload.dispatchRequest.total =
          createSaleOrderPayload.dispatchRequest.subTotal
        // change amount and taxes by lines
        createSaleOrderPayload.dispatchRequest.amount = totalValidate
        // eslint-disable-next-line array-callback-return
        createSaleOrderPayload.dispatchRequest.lines.map(lineNumber => {
          lineNumber.tax = 0
          lineNumber.amountWithTax = lineNumber.amount
        })
        createSaleOrderPayload.dispatchRequest.TaxExempt = true
      }
      if (balance >= totalValidate) {
        const result = await dispatch(
          generateDispatchOrderAction(createSaleOrderPayload)
        )

        if (result.message !== 'ERROR') {
          setDispatchValues()
          history.push(
            `${ROUTES.orderDispatch}${result.Data?.SalesDocumentInfo?.DocHeaderId}`
          )
        } else {
          handleOpen(false)
          setShowFailedSellOrderState(true)
          enableButtonOnclick(e)
        }
      } else {
        handleOpen(false)
        setShowFailedCreditAccState(true)
        enableButtonOnclick(e)
      }
    } else {
      handleOpen(false)
      setShowFailedCreditAccState(true)
      enableButtonOnclick(e)
    }
  }

  const callRegularFlow = async (createSaleOrderPayload, e) => {
    const result = await dispatch(
      generateDispatchOrderAction(createSaleOrderPayload)
    )

    if (result.message !== 'ERROR') {
      setDispatchValues()
      const idDespacho = `${ROUTES.orderDispatch}${result.Data?.SalesDocumentInfo.DocHeaderId}`
      history.push(idDespacho)
    } else {
      enableButtonOnclick(e)
      handleOpen(false)
      setShowFailedSellOrderState(true)
    }
  }

  const generateDispatchDocument = async e => {
    // add the validation for delivery type
    const linesPicked = populateItemsArrayOfPayload()
    let subTotalDispatch = 0
    let totalTaxDispatch = 0
    // eslint-disable-next-line array-callback-return
    linesPicked.map(line => {
      subTotalDispatch += line.amount
      if (line?.taxesExempt) {
        totalTaxDispatch = 0
      } else {
        totalTaxDispatch += line.tax
      }
    })
    const totalDispatch = subTotalDispatch + totalTaxDispatch
    let createSaleOrderPayload = {
      documentTypeId: ID_CONSTANTS.dispatchOrder.dipatchTypeID,
      dispatchRequest: {
        ...(supplier ? { ProviderId: supplier.ProviderId } : {}),
        dispatchRequestHeaderId: null,
        salesOrderHeaderId:
          sellOrderPostData?.Data?.SalesDocumentInfo?.DocHeaderId, // "94ECBC98-1235-4537-AF11-9851EE2E9C52",
        HeaderStatusId: ID_CONSTANTS.dispatchOrder.headerStatusDispatchOrderID,
        dispatchTypeId: dispatchTypeSelected,
        addressId:
          deliveryMethodState === 'DEL'
            ? location.AddressInfo.AddressId
            : sucState.AddressId,
        fulfillmentPlanId: deliveryTypeState.FulfillmentPlanId,
        deliveryDate: deliveryDateState
          .format('YYYY-MM-DDTHH:mm:ss[Z]')
          .toString(),
        total: totalDispatch, // sellOrderObject?.Amount,
        subTotal: subTotalDispatch, // 20,
        tax: totalTaxDispatch, // sellOrderObject?.Tax,
        salesAgentId: user?.AuthenticationInfo?.UserId,
        comments: sellOrderObject?.comment,
        createdBy: null,
        lastModifiedBy: null,
        status: 1,
        salesAgentEmail: null,
        companyId: sellOrderObject?.CompanyId,
        companyCode:
          sellOrderObject?.CompanyId?.toUpperCase() ===
          'AEE876CB-7183-4BEE-8FCA-984B7A1F6BA9'
            ? 'CO'
            : 'NO' || sellOrderObject?.CompanyCode,
        CustomerEmail: sellOrderObject?.CustomerEmail,
        WarehouseId: sucState?.WarehouseId,
        DocumentDate: sellOrderObject?.DocumentDate,
        ExternalCode: null,
        origin: 'VA',
        customerId: sellOrderObject?.CustomerId,
        lines: linesPicked,
        isDirectDelivery,
        TaxExempt: sellOrderObject?.TaxExempt,
      },
    }

    const cleanEntries = Object.fromEntries(
      Object.entries(createSaleOrderPayload.dispatchRequest).filter(
        ([_, v]) => v != null
      )
    )

    createSaleOrderPayload = {
      ...createSaleOrderPayload,
      dispatchRequest: { ...cleanEntries },
    }

    if (deliveryMethodState === 'DEL') {
      const payload = {
        ...location,
        CustomerId: sellOrderObject?.CustomerId,
      }
      await checkSelectedLocation(payload)
    }

    if (
      isCommercialCreditSelected?.credit !== 'no' &&
      isCommercialCreditSelected?.accountItemSelected
    ) {
      callCreditAccountValidations(createSaleOrderPayload, e)
    } else {
      callRegularFlow(createSaleOrderPayload, e)
    }
  }

  const setDispatchValues = () => {
    dispatch({
      type: 'SET_SELECTED_DATE',
      payload: deliveryDateState.toString(),
    })

    dispatch({
      type: 'SET_DELIVERY_TYPE',
      payload: deliveryTypeState,
    })

    if (deliveryMethodState === 'DEL') {
      dispatch({
        type: 'SET_SELECTED_ADDRESS',
        payload: location,
      })
    } else {
      dispatch({
        type: 'SET_SELECTED_ADDRESS',
        payload: sucState, // sucState,//{ AddressInfo: { AddressDetail: sucState } },
      })
    }
  }

  const renderVADDatePickerBox = (title, valueState, setValueState) => {
    return (
      <Row className="no-margin-nopadding generic-address-father-container">
        <div className="generic-address-title-text">{title}</div>
        <Col className="no-margin-nopadding">
          <ThemeProvider theme={theme}>
            <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDayjs}>
              <DatePicker
                className="generic-date-picker-styles"
                inputFormat={t('RIGHT_SELECTION_MODAL.DATE_INPUT_FORMAT')}
                disableMaskedInput={true}
                minDate={dayjs()}
                PopperProps={{ className: 'generic-date-picker-popper-styles' }}
                value={valueState}
                onChange={newValue => {
                  setValueState(newValue)
                }}
                slotProps={{
                  textField: {
                    placeholder: t(
                      'RIGHT_SELECTION_MODAL.SET_DELIVERY_DATE_PLACEHOLDER'
                    ),
                  },
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    sx={{ svg: { fontSize: 20 } }}
                    onKeyDown={onKeyDown}
                    error={false}
                    helperText={null}
                    inputProps={{
                      ...params.inputProps,
                      className: 'generic-date-input-styles',
                      placeholder: t(
                        'RIGHT_SELECTION_MODAL.SET_DELIVERY_DATE_PLACEHOLDER'
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </ThemeProvider>
        </Col>
      </Row>
    )
  }

  const validateDirectDelivery = () => {
    return (
      <div className="no-margin-nopadding generic-address-father-container">
        <div className="add-delivery-superior-margin">
          {deliveryMethod && (
            <div className="">
              <span className="generic-address-title-text">
                {t('TABLE_SELL_ORDER_T.CHECK_ON_DIRECT_TITLE')}
              </span>
              <label className="margin-label-sell-method">
                <input
                  type="radio"
                  name="directValidation"
                  checked={isDirectDelivery}
                  value="yes"
                  onChange={newValue => {
                    setIsDirectDelivery(!isDirectDelivery)
                    setDeliveryTypeState(null)
                    setSupplier(null)
                    setDeliveryMethodState('DEL')
                  }}
                  onClick={() => {
                    if (!isDirectDelivery) {
                      setDeliveryTypeState(null)
                      setSupplier(null)
                      setIsDirectDelivery(true)
                    }
                    setDeliveryTypeState(null)
                    onChangeDeliveryMethod(dispatchTypeId.domicilio)
                  }}
                />
                <span className="table-sell-order-option-span">
                  {t('TABLE_SELL_ORDER_T.YES_SELECTED_OPTION')}
                </span>
              </label>
              <label className="margin-label-sell-method">
                <input
                  type="radio"
                  name="directValidation"
                  checked={!isDirectDelivery}
                  value="no"
                  onChange={newValue => {
                    setIsDirectDelivery(!isDirectDelivery)
                    setDeliveryTypeState(null)
                    setSupplier(null)
                    // setDeliveryMethodState("SUC");
                  }}
                  onClick={() => {
                    if (isDirectDelivery) {
                      setIsDirectDelivery(false)
                    }
                  }}
                />
                <span className="table-sell-order-option-span">
                  {t('TABLE_SELL_ORDER_T.NO_SELECTED_OPTION')}
                </span>
              </label>
            </div>
          )}
        </div>
        {isDirectDelivery ? (
          <div className="table-sell-order-directdel-row">
            <div className="table-sell-order-directdel-column">
              <label className="table-sell-order-option-select-description">
                <span className="table-sell-order-option-select-description-important">
                  {t('TABLE_SELL_ORDER_T.IMPORTANT_LABEL')}
                </span>
                {t('TABLE_SELL_ORDER_T.IMPORTANT_WARNING')}
              </label>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
  const onValidatePreOrderModals = () => {
    return (
      <SellOrderDeliveryWarning
        isNotValidDelivState={isNotValidDelivState}
        setIsNotValidDelivState={setIsNotValidDelivState}
        textTitle={t('SELL_ORDER_T.WARNING_TITLE_NO_DELIVERY')}
        textDescription={t('SELL_ORDER_T.WARNING_DESCRIPTION_NO_DELIVERY')}
        editQuote={t('SELL_ORDER_T.EDIT_QUOTE_LABEL')}
        returnToOrder={t('SELL_ORDER_T.RETURN_ORDER_LABEL')}
        onEditQuoteCallback={() => {
          history.goBack()
        }}
      />
    )
  }

  const validateDeliveryValues = () => {
    let validateSupplierDDAvaliable = false

    dispatchQtyState.every(item => {
      if (item.isSupplierDirectDelivery) {
        validateSupplierDDAvaliable = true
        return true
      } else {
        validateSupplierDDAvaliable = false
        return false
      }
    })
    return validateSupplierDDAvaliable
  }

  const checkPreorderValidations = e => {
    setIsNotValidCostState(false)
    const isPassedDirectDeliveryValidations = validateDeliveryValues()

    if (isDirectDelivery) {
      const productReferences = collectArrayOfReferences()

      if (isPassedDirectDeliveryValidations) {
        generateDispatchDocument(e)
        return
      }
      if (productReferences.length) {
        enableButtonOnclick(e)
        setSendingSubmit(false)
        setIsNotValidDDProductState(true)
        setIsDirectDelivery(false)
        setDeliveryDateState(null)
        setDeliveryTypeState(null)
        setLocation(null)
        handleOpen(false)
      } else {
        generateDispatchDocument(e)
        return
      }
    }
    if (!isDirectDelivery) {
      generateDispatchDocument(e)
    } else {
      handleOpen(false)
      enableButtonOnclick(e)
      setSendingSubmit(false)
    }
  }

  const collectArrayOfReferences = () => {
    const arrayOfFInvalidReferences = []

    dispatchQtyState
      .filter(p => p.isSelected && p.solicitud_despacho > 0)
      .forEach(item => {
        if (!item.isSupplierDirectDelivery) {
          arrayOfFInvalidReferences.push(item.referencia)
        }
      })

    return arrayOfFInvalidReferences
  }

  const onInvalidProductModal = () => {
    return (
      <CheckIsDDAvaliableModal
        isNotValidDDProductState={isNotValidDDProductState}
        setIsNotValidDDProductState={setIsNotValidDDProductState}
        setIsDirectDelivery={setIsDirectDelivery}
        mainTitleFirst={t('La referencia ')}
        mainTitleLast={t(' no tiene entrega directa')}
        onEditQuoteCallback={() => {
          history.goBack()
        }}
        referencesArray={collectArrayOfReferences()}
      />
    )
  }

  const onCostsModal = () => {
    return (
      <CheckDeliveryCostsModal
        isNotValidCostState={isNotValidCostState}
        setIsNotValidCostState={setIsNotValidCostState}
        textTitle={t('SELL_ORDER_T.DELIVERY_COST_TITLE')}
      />
    )
  }
  const disableButtonOnclick = e => {
    e.target.disabled = true
  }
  const enableButtonOnclick = e => {
    e.target.disabled = false
    setSendingSubmit(false)
  }

  return (
    <>
      {sendingSubmit && <Loading full />}
      {open ? (
        <Container className={'dispatch-type-navbar-container'}>
          <Row className="no-margin-nopadding col-distribute-container">
            <Row className="no-margin-nopadding client-detail-forms-distribution">
              <Row className="no-margin-nopadding">
                <Col className="dispatch-type-close">
                  <h2 className="dispatch-type-title">
                    {t('RIGHT_SELECTION_MODAL.SELECT_INVENTORY_TYPE')}
                  </h2>
                  <img
                    src={no}
                    alt="close-icon"
                    className="close-icon-dispach-menu"
                    onClick={closeBar}
                  />
                </Col>
                <hr />
              </Row>
              <Row>
                <Col>{validateDirectDelivery()}</Col>
              </Row>
              {renderDeliveryMethodButtons()}
              {deliveryMethodState === 'DEL' ? (
                <>
                  <div className="add-sucursal-superior-margin">
                    {renderGenericMultipleSelectForm({
                      title: t('RIGHT_SELECTION_MODAL.SELECT_BILLING_BRANCH'),
                      showState: openDropdown.selectBranchOffice,
                      setShowState: state =>
                        setOpenDropdown({
                          ...openDropdown,
                          selectBranchOffice: state,
                        }),
                      valueState: sucState,
                      setValueState: setSucState,
                      mappedInfo: branchesData,
                      placeholder: t(
                        'RIGHT_SELECTION_MODAL.BRANCH_PLACEHOLDER'
                      ),
                      controlValue: 'SUC',
                      showSearch: true,
                    })}
                  </div>
                  {customerSavedLocations?.CustomerType?.CustomerTypeId ===
                  customerTypeId.natural
                    ? customerSavedLocations.AddressList?.length > 0
                      ? renderLocation(customerSavedLocations?.AddressList)
                      : null
                    : customerSavedLocations?.AdministrativeStoreInfo
                        ?.AddressList?.length > 0
                    ? renderLocation(
                        customerSavedLocations?.AdministrativeStoreInfo
                          .AddressList
                      )
                    : null}
                  {renderAddMoreLocation()}
                  <div className="add-delivery-superior-margin">
                    {renderVADDatePickerBox(
                      t('RIGHT_SELECTION_MODAL.SET_DELIVERY_DATE_TITLE'),
                      deliveryDateState,
                      setDeliveryDateState
                    )}
                  </div>
                  <div className="add-delivery-superior-margin">
                    {/* {validateDirectDelivery()} */}
                  </div>
                  {fullfilmentData && fullfilmentData?.Data?.length > 0 ? (
                    <div className="add-delivery-superior-margin">
                      {renderGenericMultipleSelectForm({
                        title: t('RIGHT_SELECTION_MODAL.SELECT_DELIVERY_TYPE'),
                        showState: openDropdown.dispatchTypeDomicile,
                        setShowState: state =>
                          setOpenDropdown({
                            ...openDropdown,
                            dispatchTypeDomicile: state,
                          }),
                        valueState: deliveryTypeState,
                        setValueState: setDeliveryTypeState,
                        mappedInfo: isDirectDelivery
                          ? fullfilmentData.Data.filter(
                              f =>
                                f.FulfillmentPlanId ===
                                  '13CEDD9C-A262-4728-AD0E-78BF6565A978' ||
                                f.FulfillmentPlanId ===
                                  'E775A02F-E3D0-49B7-90B4-AB7C54166171'
                            )
                          : fullfilmentData.Data,
                        placeholder: t(
                          'RIGHT_SELECTION_MODAL.SELECT_DELIVERY_METHOD'
                        ),
                        controlValue: 'DEL',
                        blockComboBox: isDirectDelivery,
                      })}
                    </div>
                  ) : null}
                  {isDirectDelivery && (
                    <div className="select-supplier">
                      <DynamicSelector
                        title={'RIGHT_SELECTION_MODAL.CHOOSE_SUPPLIER'}
                        showState={openDropdown.selectSupplier}
                        setShowState={state =>
                          setOpenDropdown({
                            ...openDropdown,
                            selectSupplier: !openDropdown.selectSupplier,
                          })
                        }
                        valueState={supplier}
                        placeholder={'RIGHT_SELECTION_MODAL.SELECT_SUPPLIER'}
                        mappedInfo={supplierProduct}
                        setValueState={setSupplier}
                        loading={loadingSupplierProducts}></DynamicSelector>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="add-sucursal-superior-margin">
                    {renderGenericMultipleSelectForm({
                      title: t('RIGHT_SELECTION_MODAL.SELECT_BRANCH'),
                      showState: openDropdown.selectBranchOffice,
                      setShowState: state =>
                        setOpenDropdown({
                          ...openDropdown,
                          selectBranchOffice: !openDropdown.selectBranchOffice,
                        }),
                      valueState: sucState,
                      setValueState: setSucState,
                      mappedInfo: branchesData,
                      placeholder: t(
                        'RIGHT_SELECTION_MODAL.BRANCH_PLACEHOLDER'
                      ),
                      controlValue: 'SUC',
                      showSearch: true,
                    })}
                    <p className="message-billing-branch">
                      {t('RIGHT_SELECTION_MODAL.MESSAGE_BILLING_BRANCH')}
                    </p>
                  </div>
                  <div className="add-delivery-superior-margin">
                    {renderVADDatePickerBox(
                      t('RIGHT_SELECTION_MODAL.SELECT_DELIVERY_DATE'),
                      deliveryDateState,
                      setDeliveryDateState
                    )}
                  </div>

                  {fullfilmentData && fullfilmentData?.Data?.length > 0 ? (
                    <div className="add-delivery-superior-margin">
                      {renderGenericMultipleSelectForm({
                        title: t('RIGHT_SELECTION_MODAL.SELECT_DELIVERY_TYPE'),
                        showState: openDropdown.dispatchTypeBranchOffice,
                        setShowState: state =>
                          setOpenDropdown({
                            ...openDropdown,
                            dispatchTypeBranchOffice:
                              !openDropdown.dispatchTypeBranchOffice,
                          }),
                        valueState: deliveryTypeState,
                        setValueState: setDeliveryTypeState,
                        mappedInfo: fullfilmentData.Data,
                        placeholder: t(
                          'RIGHT_SELECTION_MODAL.SELECT_DELIVERY_METHOD'
                        ),
                        controlValue: 'DEL',
                        blockComboBox: isDirectDelivery,
                      })}
                    </div>
                  ) : (
                    <div className="add-delivery-superior-margin">
                      <label className="add-delivery-error-on-loading">
                        {t('RIGHT_SELECTION_MODAL.ERROR_TYPE_DELIVERY_LABEL')}
                      </label>
                    </div>
                  )}
                </>
              )}
            </Row>
            <Row className="generate-dispatch-button-distribution">
              <Col className="generate-dispatch-button-container">
                <button
                  className="generate-dispatch-button-footer"
                  disabled={!validateForms()}
                  onClick={e => {
                    setSendingSubmit(true)
                    disableButtonOnclick(e)
                    checkPreorderValidations(e)
                  }}>
                  {sendingSubmit ? (
                    <CircularProgress size={20} />
                  ) : (
                    t('RIGHT_SELECTION_MODAL.GENERATE_DISPATCH_BUTTON')
                  )}
                </button>
              </Col>
            </Row>
          </Row>
        </Container>
      ) : null}
      {onFailCreatingSellOrderToast()}
      {onFailReservationOnCreditAcc()}
      {onValidatePreOrderModals()}
      {onCostsModal()}
      {onInvalidProductModal()}
    </>
  )
}

export default RightSetLocationModal
