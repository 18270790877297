import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { images } from 'Assets/Images'
import React, { useEffect } from 'react'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { useGetCmfCredit } from '../../../hooks/useGetCmfCredit'
import { FormatMoney } from 'Utils/functions'
import { TermsTextArea } from '../../TermsTextArea'
import { disclaimers } from 'Views/PrinterPreview/constants'
export const DEFAULT_VALUE = disclaimers.regularCmf

export const Square2CCmf = ({ products, i }) => {
  const { terms } = usePrinterContext()

  const { fetchCmfCredit, loading } = useGetCmfCredit()

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const terms1 =
    terms?.find(term => term?.prodId === prod1?.ProductId)?.value ||
    DEFAULT_VALUE
  const terms2 =
    terms?.find(term => term?.prodId === prod2?.ProductId)?.value ||
    DEFAULT_VALUE
  const prodBasePrice1 = FormatMoney(prod1?.BasePrice || 0)
  const prodBasePrice2 = FormatMoney(prod2?.BasePrice || 0)

  useEffect(() => {
    if (prod1?.Sku && !prod1?.cmfCredit) {
      fetchCmfCredit(prod1?.BasePrice, prod1?.Sku)
    }
    if (prod2?.Sku && !prod2?.cmfCredit) {
      fetchCmfCredit(prod2?.BasePrice, prod2?.Sku)
    }
  }, [prod1?.Sku, prod2?.Sku])

  return (
    <div
      key={products.page}
      data-testid="printer-preview-left-canvas-2"
      id="printer-preview-left-canvas-square-2"
      className="printer-preview-left-canvas-square-2">
      <div
        className="printer-preview-left-canvas-square-2-box"
        style={{
          height: '100%',
          top: '0',
        }}>
        <div className="printer-preview-left-content-body-square-2">
          <p
            className="printer-preview-left-content-body-square-2-title"
            style={{ lineHeight: '17px' }}>
            {prod1?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-2-sku">
            {prod1?.Sku || ''}
          </p>
          {prod1?.BasePrice && (
            <div
              className="printer-preview-left-content-body-square-2-price-wrapper"
              style={{ marginTop: '6px' }}>
              <p
                className="printer-preview-mini-text-square-2"
                style={{ color: '#000' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-square-2-price"
                style={{ color: '#000', fontSize: '80px' }}>
                {prodBasePrice1?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text-square-2"
                style={{ color: '#000' }}>
                {prodBasePrice1?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div className="printer-preview-left-content-footer-square-2">
          <div className="printer-preview-left-content-footer-square-2-quotes">
            <p></p>
            <p
              className="printer-preview-left-content-footer-square-2-quote-text"
              style={{ color: '#000' }}>
              36 <br /> cuotas
            </p>
            <p
              className="printer-preview-left-content-footer-square-2-quote-text"
              style={{ color: '#000' }}>
              {!loading && '$' + (FormatMoney(prod1?.cmfCredit || 0) || '')}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  data-testid="spinner"
                  style={{ width: 20 }}
                />
              )}
            </p>
          </div>
          <div
            className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove"
            style={{ marginTop: 6 }}>
            <TermsTextArea rows={3} prod={prod1} defaultValue={terms1} />
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-2-box"
        style={{
          height: '50%',
          top: '50%',
        }}>
        <div className="printer-preview-left-content-body-square-2-2">
          <p
            className="printer-preview-left-content-body-square-2-title-2"
            style={{ lineHeight: '17px' }}>
            {prod2?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-2-sku-2">
            {prod2?.Sku || ''}
          </p>
          {prod2?.BasePrice && (
            <div
              className="printer-preview-left-content-body-square-2-price-wrapper"
              style={{ marginTop: '0px' }}>
              <p
                className="printer-preview-mini-text-square-2"
                style={{ color: '#000' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-square-2-price-2"
                style={{ color: '#000', fontSize: '80px' }}>
                {prodBasePrice2?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text-square-2"
                style={{ color: '#000' }}>
                {prodBasePrice2?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div className="printer-preview-left-content-footer-square-2-2">
          <div className="printer-preview-left-content-footer-square-2-quotes">
            <p></p>
            <p
              className="printer-preview-left-content-footer-square-2-quote-text"
              style={{ color: '#000' }}>
              36 <br /> cuotas
            </p>
            <p
              className="printer-preview-left-content-footer-square-2-quote-text"
              style={{ color: '#000' }}>
              {!loading && '$' + (FormatMoney(prod2?.cmfCredit || 0) || '')}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 20 }}
                />
              )}
            </p>
          </div>
          <div
            className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove"
            style={{ marginTop: 6 }}>
            <TermsTextArea rows={3} prod={prod2} defaultValue={terms2} />
          </div>
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.cochez.cmf2}
        alt="template-preview"
      />
    </div>
  )
}
