import React from 'react'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { Square1CRegular } from './regular/Square1'
import { Square2CRegular } from './regular/Square2'
import { Square1COffer } from './offer/Square1'
import { Square2COffer } from './offer/Square2'
import { Square1CCmf } from './cmf/Square1'
import { Square2CCmf } from './cmf/Square2'
import { Square3CCmf } from './cmf/Square3'
import { Square1CCmfOffer } from './cmfOffer/Square1'
import { Square2CCmfOffer } from './cmfOffer/Square2'
import { Square3CRegular } from './regular/Square3'
import { Square3COffer } from './offer/Square3'
import { Square3CCmfOffer } from './cmfOffer/Square3'
import { Square1Special } from './special/Square1'
import { Square2Special } from './special/Square2'

export const REGULAR_CMF = 'REGULAR_CMF'
export const OFFER_CMF = 'OFFER_CMF'
export const REGULAR = 'REGULAR'
export const OFFER = 'OFFER'
export const SPECIAL_CMF = 'SPECIAL_CMF'

export const CochezTemplates = ({ products, i }) => {
  const {
    currentPage,
    allTemplatesContent,
    showAllTemplates,
    selectedTemplateType,
  } = usePrinterContext()

  const isCurrentPage = products.page === currentPage
  if (!isCurrentPage && !showAllTemplates) return null

  const currentTemplate = allTemplatesContent.find(
    content => content.page === currentPage
  )?.template?.[0]

  const isRegCMF = selectedTemplateType?.template === REGULAR_CMF
  const isOfferCMF = selectedTemplateType?.template === OFFER_CMF
  const isReg = selectedTemplateType?.template === REGULAR
  const isOffer = selectedTemplateType?.template === OFFER
  const isSpecialCMF = selectedTemplateType?.template === SPECIAL_CMF

  if (isOfferCMF) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1CCmfOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2CCmfOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-3' && (
          <Square3CCmfOffer i={i} products={products} />
        )}
        {currentTemplate === 'square-4' && (
          <Square3CCmfOffer i={i} products={products} />
        )}
      </>
    )
  }
  if (isReg) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1CRegular i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2CRegular i={i} products={products} />
        )}
        {currentTemplate === 'square-3' && (
          <Square3CRegular i={i} products={products} />
        )}
        {currentTemplate === 'square-4' && (
          <Square3CRegular i={i} products={products} />
        )}
      </>
    )
  }
  if (isOffer) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1COffer i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2COffer i={i} products={products} />
        )}
        {currentTemplate === 'square-3' && (
          <Square3COffer i={i} products={products} />
        )}
        {currentTemplate === 'square-4' && (
          <Square3COffer i={i} products={products} />
        )}
      </>
    )
  }

  if (isRegCMF) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1CCmf i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2CCmf i={i} products={products} />
        )}
        {currentTemplate === 'square-3' && (
          <Square3CCmf i={i} products={products} />
        )}
        {currentTemplate === 'square-4' && (
          <Square3CCmf i={i} products={products} />
        )}
      </>
    )
  }
  if (isSpecialCMF) {
    return (
      <>
        {currentTemplate === 'square-1' && (
          <Square1Special i={i} products={products} />
        )}
        {currentTemplate === 'square-2' && (
          <Square2Special i={i} products={products} />
        )}
      </>
    )
  }
  return <div data-testid="cochez-template" />
}
