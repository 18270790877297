export const colorsStatus = {
  process: '#81E7CB',
  pending: '#FF96A0',
  closed: '#FFDF94',
}

export const listOfStatus = [
  {
    key: '2cac34c2-ccd3-4d2e-beb6-084322b03450',
    DocumentTypeId: '502983d8-15cc-421c-afba-22bf488da064',
    SortOrder: 1,
    value: 'Creada',
    Icon: null,
    ColorPrimary: null,
    ColorSecondary: null,
  },
  {
    key: '83d564ae-ca2b-4348-95a5-2f561d0b1efc',
    DocumentTypeId: '502983d8-15cc-421c-afba-22bf488da064',
    SortOrder: 2,
    value: 'En proceso de despacho',
    Icon: null,
    ColorPrimary: null,
    ColorSecondary: null,
  },
  {
    key: '399ad404-7605-4381-880a-aea2d40ef05d',
    DocumentTypeId: '502983d8-15cc-421c-afba-22bf488da064',
    SortOrder: 3,
    value: 'Listo para enviar',
    Icon: null,
    ColorPrimary: null,
    ColorSecondary: null,
  },
  {
    key: '4c2888e9-3ba2-47b8-9731-23133edb053e',
    DocumentTypeId: '502983d8-15cc-421c-afba-22bf488da064',
    SortOrder: 4,
    value: 'Listo para retirar',
    Icon: null,
    ColorPrimary: null,
    ColorSecondary: null,
  },
  {
    key: '54056da2-3e95-4e8b-8416-6ed08b80c77b',
    DocumentTypeId: '502983d8-15cc-421c-afba-22bf488da064',
    SortOrder: 5,
    value: 'En tránsito',
    Icon: null,
    ColorPrimary: null,
    ColorSecondary: null,
  },
  {
    key: 'ec21ca9d-6743-4fad-bf5f-0005942f42de',
    DocumentTypeId: '502983d8-15cc-421c-afba-22bf488da064',
    SortOrder: 6,
    value: 'Entregado',
    Icon: null,
    ColorPrimary: null,
    ColorSecondary: null,
  },
  {
    key: '9fde5e59-ee3b-40b5-8db9-880cceacc9bc',
    DocumentTypeId: '502983d8-15cc-421c-afba-22bf488da064',
    SortOrder: 7,
    value: 'Cancelada',
    Icon: null,
    ColorPrimary: null,
    ColorSecondary: null,
  },
  {
    key: '93b7c0b7-1fd3-4851-8e3b-0ebf41b4497b',
    DocumentTypeId: '502983d8-15cc-421c-afba-22bf488da064',
    SortOrder: 8,
    value: 'Entrega Parcial',
    Icon: null,
    ColorPrimary: null,
    ColorSecondary: null,
  },
  {
    key: 'b654ca47-2438-4117-9249-3afc2d759955',
    DocumentTypeId: '502983d8-15cc-421c-afba-22bf488da064',
    SortOrder: 9,
    value: 'Cerrada',
    Icon: null,
    ColorPrimary: null,
    ColorSecondary: null,
  },
]
