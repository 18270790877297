import React from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { FormatMoney } from 'Utils/functions'

const defTermValue = date =>
  `La tarjeta digital CMF es emitida por CM Financiera, S.A. (CMF).  Accede a www.cmfinanciera.com para más información. La responsabilidad de CMF se limita a los aspectos del crédito. Promociones válidas del X al XX de X del 2024. No aplica con otras promociones ni descuentos, precios no incluyen ITBMS, promoción aplica para productos seleccionados pagando únicamente con la tarjeta digital CMF.`

export const Square1ToPrintSpecial = ({ products, i }) => {
  const { templateRef, terms } = usePrinterContext()

  const prod = products?.content?.products?.[0]
  const terms1 =
    terms?.find(term => term?.prodId === prod?.ProductId)?.value ||
    defTermValue(prod?.FinishDate)
  const cmfCredit = FormatMoney(prod?.cmfCredit || 0)
  const prodBasePrice = FormatMoney(prod?.BasePrice || 0)
  const prodPrice = FormatMoney(prod?.Price || 0)

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        id="printer-preview-left-canvas"
        data-testid="printer-preview-left-canvas-1"
        className="printer-preview-left-canvas">
        <div
          className="printer-preview-left-content-body"
          style={{ top: '152px', maxWidth: 'calc(70% - 40px)' }}>
          <p
            className="printer-preview-left-content-body-title"
            style={{ fontSize: '20px', lineHeight: 'normal' }}>
            {prod?.ProductName || ''}
          </p>
          <p
            className="printer-preview-left-content-body-sku"
            style={{ marginTop: '-10px' }}>
            {prod?.Sku || ''}
          </p>
          {prod?.Price && (
            <div
              className="printer-preview-left-content-body-price-wrapper"
              style={{
                position: 'absolute',
                width: '100%',
                top: '90px',
              }}>
              <p
                className="printer-preview-mini-text"
                style={{
                  color: '#D9000C',
                  fontSize: '40px',
                  marginTop: '-20px',
                }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-price"
                style={{ color: '#D9000C', fontSize: '103px' }}>
                {prodPrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text"
                style={{
                  color: '#D9000C',
                  fontSize: '40px',
                  marginTop: '-20px',
                }}>
                {prodPrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
          {prod?.BasePrice && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '4px',
                position: 'absolute',
                right: '-306px',
                width: '100%',
                top: '88px',
              }}>
              <p
                style={{
                  fontSize: '22px',
                  fontWeight: 'bold',
                  marginBottom: '8px',
                  color: '#000',
                }}>
                Precio Regular
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#000',
                    fontSize: '21px',
                    marginTop: '-17px',
                  }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-price"
                  style={{ color: '#000', fontSize: '46px', marginTop: 0 }}>
                  {prodBasePrice?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#000',
                    fontSize: '21px',
                    marginTop: '-17px',
                  }}>
                  {prodBasePrice?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className="printer-preview-left-content-footer"
          style={{ bottom: '98px' }}>
          <div
            className="printer-preview-left-content-footer-quotes"
            style={{
              gridTemplateColumns: '1fr 0.8fr 0.8fr',
            }}>
            <p
              className="printer-preview-left-content-footer-quote-text"
              style={{ color: '#D9000C' }}>
              36 cuotas
            </p>
            <p
              className="printer-preview-left-content-footer-quote-text"
              style={{
                color: '#D9000C',
                textAlign: 'start',
                paddingLeft: '8px',
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '1px',
              }}>
              {prod?.cmfCredit && (
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#D9000C',
                    fontSize: '16px',
                    marginTop: '-6px',
                  }}>
                  $
                </p>
              )}
              {prod?.cmfCredit && (
                <p
                  className="printer-preview-left-content-body-price"
                  style={{
                    color: '#D9000C',
                    fontSize: '34px',
                    marginTop: 0,
                    height: 'auto',
                  }}>
                  {cmfCredit?.toString()?.split('.')?.[0] || 0 || ''}.
                </p>
              )}
              {prod?.cmfCredit && (
                <p
                  className="printer-preview-mini-text"
                  style={{
                    color: '#D9000C',
                    fontSize: '16px',
                    marginTop: '-6px',
                  }}>
                  {cmfCredit?.toString()?.split('.')?.[1] || '00'}
                </p>
              )}
            </p>
            <div />
          </div>
          <div
            className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove"
            style={{ position: 'absolute', bottom: '-90px' }}>
            <p
              className="printer-preview-left-content-footer-legal-1"
              style={{
                border: 'none',
                color: '#000',
                fontSize: '9px',
                background: 'transparent',
                height: '40.5px',
              }}>
              {terms1}
            </p>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.cochez.special1}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
