import { images } from 'Assets/Images'
import React from 'react'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { FormatMoney } from 'Utils/functions'
const DEFAULT_VALUE = 'Precio no incluye ITBMS'

export const Square2ToPrintCRegular = ({ products, i }) => {
  const { templateRef, terms } = usePrinterContext()

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const terms1 =
    terms?.find(term => term?.prodId === prod1?.ProductId)?.value ||
    DEFAULT_VALUE
  const terms2 =
    terms?.find(term => term?.prodId === prod2?.ProductId)?.value ||
    DEFAULT_VALUE
  const prodBasePrice1 = FormatMoney(prod1?.BasePrice || 0)
  const prodBasePrice2 = FormatMoney(prod2?.BasePrice || 0)

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        key={products.page}
        data-testid="printer-preview-left-canvas-2"
        id="printer-preview-left-canvas-square-2"
        className="printer-preview-left-canvas-square-2">
        <div
          className="printer-preview-left-canvas-square-2-box"
          style={{
            height: '100%',
            top: '0',
          }}>
          <div
            className="printer-preview-left-content-body-square-2"
            style={{ top: '90px' }}>
            <p className="printer-preview-left-content-body-square-2-title">
              {prod1?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-2-sku">
              {prod1?.Sku || ''}
            </p>
            {prod1?.BasePrice && (
              <div className="printer-preview-left-content-body-square-2-price-wrapper">
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ color: '#000', marginTop: '-18px' }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-square-2-price"
                  style={{
                    color: '#000',
                    fontSize: '102px',
                    marginTop: '40px',
                  }}>
                  {prodBasePrice1?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ color: '#000', marginTop: '-18px' }}>
                  {prodBasePrice1?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-2"
            style={{ top: 'calc(50% - 25px)' }}>
            <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{ border: 'none', textAlign: 'end' }}>
                {terms1}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-2-box"
          style={{
            height: '50%',
            top: '50%',
            // background: 'green',
          }}>
          <div
            className="printer-preview-left-content-body-square-2-2"
            style={{ top: '90px' }}>
            <p className="printer-preview-left-content-body-square-2-title-2">
              {prod2?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-2-sku-2">
              {prod2?.Sku || ''}
            </p>
            {prod2?.BasePrice && (
              <div className="printer-preview-left-content-body-square-2-price-wrapper">
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ color: '#000', marginTop: '-18px' }}>
                  $
                </p>
                <p
                  className="printer-preview-left-content-body-square-2-price"
                  style={{
                    color: '#000',
                    fontSize: '102px',
                    marginTop: '40px',
                  }}>
                  {prodBasePrice2?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p
                  className="printer-preview-mini-text-square-2"
                  style={{ color: '#000', marginTop: '-18px' }}>
                  {prodBasePrice2?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-2-2"
            style={{ top: 'calc(100% - 25px)' }}>
            <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{ border: 'none', textAlign: 'end' }}>
                {terms2}
              </p>
            </div>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.cochez.regular2}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
