import Login from '../Views/Login'
import SearchQuote from '../Views/SearchQuote'
import CrearCotizacion from '../Views/CrearCotizacion'
import MisTareas from '../Views/MisTareas'
import GenerarCotizacion from '../Views/GenerarCotizacion'
import EditarCotizacion from '../Views/EditarCotizacion'
import OrdenDeVenta from '../Views/OrdenDeVenta'
import EditarCarrito from '../Views/EditarCarrito'
import Departments from '../Views/Departments'
import NotFound from '../Views/NotFound'
import Home from '../Views/Home'
import SearchCustomer from '../Views/SearchCustomer'
import MisListas from '../Views/MisListas'
import VentaPorProducto from '../Views/VentaPorProducto'
import OrdenDeDespacho from 'Views/OrdenDeDespacho'
import SearchSaleOrder from 'Views/SearchSaleOrder'
import SearchDispatch from 'Views/SearchDispatch'
import Deals from 'Views/Ofertas/index'
import Printer from 'Views/Printer'
import PrinterPreview from 'Views/PrinterPreview'
// import Reports from 'Views/Reports/Reports'
// import ReportByDepartment from 'Views/Reports/ReportByDepartment'
// import ReportByCredit from 'Views/Reports/ReportByCredit'
import { ROUTES } from 'Utils/constants'
import MaterialCalculatorSummary from 'Components/MaterialsCalculatorSummary/MaterialCalculatorSummary'
import SearchSaleOrderWeb from 'Views/SearchSaleOrderWeb'

const routes = [
  {
    path: ROUTES.login,
    element: Login,
    isPrivate: false,
    exact: true,
  },
  {
    path: ROUTES.home,
    element: Home,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.printer,
    element: Printer,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.printerPreview,
    element: PrinterPreview,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.myTasks,
    element: MisTareas,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.searchQuote,
    element: SearchQuote,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.searchSaleOrderWeb,
    element: SearchSaleOrderWeb,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.searchSaleOrder,
    element: SearchSaleOrder,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.searchDispatch,
    element: SearchDispatch,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.createQuote,
    element: CrearCotizacion,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.generateQuote,
    element: GenerarCotizacion,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.materialsPreOrder,
    element: MaterialCalculatorSummary,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.generateQuoteTypeDocument,
    element: GenerarCotizacion,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.offers,
    element: Deals,
    exact: true,
    isPrivate: true,
  },
  // {
  //   path: ROUTES.reports,
  //   element: Reports,
  //   exact: true,
  //   isPrivate: true,
  // },
  // {
  //   path: ROUTES.reportsByDepartment,
  //   element: ReportByDepartment,
  //   exact: true,
  //   isPrivate: true,
  // },
  // {
  //   path: ROUTES.reportsByCredit,
  //   element: ReportByCredit,
  //   exact: true,
  //   isPrivate: true,
  // },
  {
    path: `${ROUTES.editQuoteHeaderId}:header/:id`,
    element: EditarCotizacion,
    exact: true,
    isPrivate: true,
  },
  {
    path: `${ROUTES.editQuoteHeaderId}:header/:id/edit_cart`,
    element: EditarCarrito,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.createQuoteFromList,
    element: CrearCotizacion,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.departments,
    element: Departments,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.customers,
    element: SearchCustomer,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.list,
    element: MisListas,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.quoteProduct,
    element: VentaPorProducto,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.salePreorder,
    element: OrdenDeVenta,
    exact: true,
    isPrivate: true,
  },
  {
    path: `${ROUTES.salePreorderValidate}:isPreorderValidatedOnRoute/:id`,
    element: OrdenDeVenta,
    exact: true,
    isPrivate: true,
  },
  {
    path: `${ROUTES.orderDispatch}:id`,
    element: OrdenDeDespacho,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUTES.notFound,
    element: NotFound,
    isPrivate: false,
  },
]

export default routes
