import { busApi } from 'Api/axios'

export const updateOdvWebStatus = async ({
  saleOrderId,
  headerStatusId,
  statusName,
}) => {
  const { data } = await busApi.patch(`/UpdateSaleOrderWebStatus`, {
    saleOrderId,
    headerStatusId,
    statusName,
  })
  return data
}

export const fetchGetOdvDetails = async ({ saleOrderId }) => {
  const { data } = await busApi.get(
    `/SearchBySaleOrderId?OrderSaleId=${saleOrderId}`
  )
  return data
}
