import { aludraApi } from 'Api/axios'

const mdl11 = {
  salesRelatedDocuments: '/mdl11/salesRelatedDocuments/Post',
  searchSalesDocuments: '/mdl11/SearchSalesDocuments/Post',
  searchSalesDocumentDetail: '/mdl11/SearchSalesDocumentDetail',
  salesAgentsUrl: '/mdl11/salesAgent/search',
  pdfURL: '/mdl11/GeneratePDF',
  emailURL: '/mdl11/ForwardEmailSalesDocuments/Post',
  ReasonCancellation: '/mdl11/ReasonCancellation',
  cannalURL: 'mdl11/getInputChannels/',
  addCotizationURL: 'mdl11/AddUpdateSalesDocuments/Post',
  getApprovallSalesOrder: 'mdl11/GetApprovalSalesOrder',
  updateApprovalSalesOrder: 'mdl11/UpdateApprovalSalesOrder',
  updateQuantityOrdered: 'mdl11/UpdateQtyOrderedODV',
  documentHistory: 'mdl11/documentHistory/post',
  getOdcDocument: 'mdl11/documentHistory',
}
const mdl12 = {
  uploadFileURL: '/mdl12/UploadFile',
}

export const UpdateApprovalSalesOrder = async payload => {
  return aludraApi.post(mdl11.updateApprovalSalesOrder, payload)
}

export const GetApprovalSalesOrder = async DocumentHeaderId => {
  return aludraApi.get(mdl11.getApprovallSalesOrder + `?Id=${DocumentHeaderId}`)
}
export const searchSalesDocumentsService = async (
  payload,
  isSearchSaleOrderWeb
) => {
  return aludraApi.post(mdl11.searchSalesDocuments, payload)
}

export const salesRelatedDocumentsService = async payload => {
  return aludraApi.post(mdl11.salesRelatedDocuments, payload)
}

export const AddUpdateSalesDocumentsService = async payload => {
  return aludraApi.post(mdl11.addCotizationURL, payload)
}

export const getPdfCotizacionService = async payload => {
  return aludraApi.post(mdl11.pdfURL, payload)
}

export const sendEmailCotizacionService = async payload => {
  return aludraApi.post(mdl11.emailURL, payload)
}

export const getSalesAgentsService = async payload => {
  return aludraApi.post(mdl11.salesAgentsUrl, payload)
}

export const searchSalesDocumentDetailService = async payload => {
  return aludraApi.post(mdl11.searchSalesDocumentDetail, payload)
}

export const getReasonCancellationService = async () => {
  return aludraApi.get(mdl11.ReasonCancellation)
}

export const getChannelsService = async => {
  return aludraApi.get(mdl11.cannalURL)
}

export const uploadFileService = async payload => {
  return aludraApi.post(mdl12.uploadFileURL, payload)
}
export const updateQuantityOrdered = async payload => {
  return aludraApi.post(mdl11.updateQuantityOrdered, payload)
}
export const uploadDocumentHistory = async payload => {
  return aludraApi.post(mdl11.documentHistory, payload)
}
export const getOdcDocument = async ({ documentTypeId, documentId }) => {
  return aludraApi.get(
    mdl11.getOdcDocument +
      `?documentTypeId=${documentTypeId}&documentId=${documentId}`
  )
}
