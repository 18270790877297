import React, { useEffect } from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useGetCmfCredit } from '../../../hooks/useGetCmfCredit'
import { FormatMoney } from 'Utils/functions'
import { formatStartEndDate } from 'Utils/Helpers/formatDate'
import { TermsTextArea } from '../../TermsTextArea'

export const sizeOfMarginLeft = {
  1: '0',
  2: '0',
  3: '0',
  4: '-59px',
  5: '-118px',
  6: '-118px',
}

const defTermValue = (startDate, endDate) =>
  `Promoción válida del ${formatStartEndDate(
    startDate,
    endDate
  )}. La tarjeta digital CMF es emitida por CM Financiera, S.A. (CMF). *Valor cuota referencial, aplican condiciones. La responsabilidad de CMF se limita a los aspectos del crédito. Accede a www.cmf.com.pa para más información.`

export const Square1NCmfOffer = ({ products, i }) => {
  const { terms } = usePrinterContext()

  const { fetchCmfCredit, loading } = useGetCmfCredit()

  const prod = products?.content?.products?.[0]

  const terms1 =
    terms?.find(term => term?.prodId === prod?.ProductId) ||
    defTermValue(prod?.StartDate, prod?.FinishDate)
  const prodBasePrice = FormatMoney(prod?.BasePrice || 0)
  const prodPrice = FormatMoney(prod?.Price || 0)

  useEffect(() => {
    if (prod?.Sku && !prod?.cmfCredit) {
      fetchCmfCredit(prod?.Price, prod?.Sku)
    }
  }, [prod?.Sku])

  return (
    <div
      data-testid="printer-preview-left-canvas"
      id="printer-preview-left-canvas"
      className="printer-preview-left-canvas">
      <div className="printer-preview-left-content-body" style={{ gap: '2px' }}>
        <p
          className="printer-preview-left-content-body-title"
          style={{ lineHeight: '30px' }}>
          {prod?.ProductName || ''}
        </p>
        <p className="printer-preview-left-content-body-sku">
          {prod?.Sku || ''}
        </p>
        {prod?.Price && (
          <div
            className="printer-preview-left-content-body-price-wrapper"
            style={{
              marginLeft:
                sizeOfMarginLeft[
                  prod?.Price?.toString()?.split('.')?.[0]?.length
                ],
            }}>
            <p className="printer-preview-mini-text">$</p>
            <p
              className="printer-preview-left-content-body-price"
              style={{
                fontSize: '114px',
              }}>
              {prodPrice?.toString()?.split('.')?.[0] || ''}.
            </p>
            <p className="printer-preview-mini-text">
              {prodPrice?.toString()?.split('.')?.[1] || '00'}
            </p>
          </div>
        )}
        {prod?.BasePrice && (
          <div
            style={{
              position: 'absolute',
              bottom: '-22px',
              right: 0,
              width: '130px',
              height: '1px',
              background: 'red',
              transform: 'rotate(-6deg)',
            }}
          />
        )}
        {prod?.BasePrice && (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              gap: '4px',
              position: 'absolute',
              bottom: '-34px',
              right: '0',
            }}>
            <p
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '0px',
                color: '#000',
              }}>
              Antes
            </p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <p
                className="printer-preview-mini-text"
                style={{ color: '#000', fontSize: '16px', marginTop: '-12px' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-price"
                style={{ color: '#000', fontSize: '32px', marginTop: 0 }}>
                {prodBasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text"
                style={{ color: '#000', fontSize: '16px', marginTop: '-12px' }}>
                {prodBasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          </div>
        )}
      </div>
      <div
        className="printer-preview-left-content-footer"
        style={{ bottom: -2 }}>
        <div className="printer-preview-left-content-footer-quotes">
          <p></p>
          <p className="printer-preview-left-content-footer-quote-text">
            36 <br /> cuotas
          </p>
          <p className="printer-preview-left-content-footer-quote-text">
            {!loading && '$' + (FormatMoney(prod?.cmfCredit || 0) || '')}
            {loading && (
              <FontAwesomeIcon
                data-testid="spinner"
                icon={faSpinner}
                className="spinner"
                style={{ width: 20 }}
              />
            )}
          </p>
        </div>
        <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
          <TermsTextArea
            rows={3}
            prod={prod}
            defaultValue={terms1}
            className="printer-preview-left-content-footer-legal-1"
            styles={{
              fontSize: '9px',
              color: '#000',
              height: '40.5px',
              lineHeight: '10px',
            }}
          />
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.novey.cmfOffer1}
        alt="template-preview"
      />
    </div>
  )
}
