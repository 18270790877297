import React, { useState } from 'react'
import iconFunnel from '../../../../../../Assets/Images/BuscarCotizacion/icon-funnel.svg'
import es from 'dayjs/locale/es'
import { t } from 'i18next'
import { Popover, TextField, ThemeProvider, createTheme } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

export const DatePickerFilter = ({ handleGetData }) => {
  const color = '#003082'
  const theme = createTheme({
    typography: {
      fontSize: 16,
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            color,
          },
        },
      },
    },
  })

  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false)
  const [dateFilterAnchorEl, setDateFilterAnchorEl] = useState(null)

  // Date pickers
  const [isFilterYesterdaySelected, setIsFilterYesterdaySelected] =
    useState(false)
  const [dateFromValue, setDateFromValue] = useState(null)
  const [dateToValue, setDateToValue] = useState(null)

  const handleDateFilterClear = () => {
    setIsFilterYesterdaySelected(false)
    setDateFromValue(null)
    setDateToValue(null)
  }

  const handleDateRangeApply = async () => {
    if (isFilterYesterdaySelected) {
      const dateFrom = new Date()
      dateFrom.setDate(dateFrom.getDate() - 1)
      dateFrom.setHours(0, 0, 0, 0)

      const dateTo = new Date()

      handleGetData({ StartDate: dateFrom, FinishDate: dateTo })
    } else if (dateFromValue !== null) {
      const dateFrom = new Date(dateFromValue)
      dateFrom.setHours(0, 0, 0, 0)

      let dateTo
      if (dateToValue !== null) {
        dateTo = new Date(dateToValue)
      } else {
        dateTo = new Date()
      }

      handleGetData({ StartDate: dateFrom, FinishDate: dateTo })
    } else {
      handleGetData({ StartDate: null, FinishDate: null })
      handleDateFilterClear()
    }

    handleDateFilterClose()
  }
  const handleDateFilterClose = () => {
    setIsDateFilterOpen(false)
  }

  const handleTodaySelected = () => {
    setIsFilterYesterdaySelected(!isFilterYesterdaySelected)
    setDateFromValue(null)
    setDateToValue(null)
  }

  const handleOpenFilter = async event => {
    setDateFilterAnchorEl(event.currentTarget)
    setIsDateFilterOpen(true)
  }

  const isDisabledApplyButton = () => {
    if (isFilterYesterdaySelected) {
      return false
    } else if (dateFromValue === null || dateToValue === null) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <span className="float-end">
        <img
          src={iconFunnel}
          alt="filter"
          style={{ cursor: 'pointer' }}
          onClick={e => handleOpenFilter(e)}
        />
      </span>

      <Popover
        id="date-filter-popover"
        data-testid="date-filter-popover"
        open={isDateFilterOpen}
        anchorEl={dateFilterAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleDateFilterClose}>
        <div className="filter-popover m-4">
          <div className="row mt-10">
            <div className="col-10">
              <label
                className="text-filter-popover-title"
                htmlFor="dateFilterToday">
                Ayer
              </label>
            </div>
            <div className="col-2 text-end">
              <input
                type="radio"
                id="dateFilterToday"
                checked={isFilterYesterdaySelected}
                name="dateFilterToday"
                onChange={handleTodaySelected}
              />
            </div>
          </div>
          <hr className="mb-4" />
          <div className="row">
            <span className="text-filter-popover-title mb-3">
              {t('ACTION_TABLE_T.RANGE_DATE_LABEL')}
            </span>
          </div>
          <div className="row m-1 text-filter-popover-title">
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                adapterLocale={es}
                dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('ACTION_TABLE_T.FROM_LABEL')}
                  value={dateFromValue}
                  inputFormat="DD/MM/YYYY"
                  PopperProps={{
                    className: 'generic-date-picker-popper-styles',
                  }}
                  onChange={newValue => {
                    setIsFilterYesterdaySelected(false)
                    setDateFromValue(newValue)
                  }}
                  renderInput={params => (
                    <TextField {...params} sx={{ svg: { fontSize: 20 } }} />
                  )}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </div>
          <div className="row m-1 mt-3">
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                adapterLocale={es}
                dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('ACTION_TABLE_T.TO_LABEL')}
                  value={dateToValue}
                  inputFormat="DD/MM/YYYY"
                  PopperProps={{
                    className: 'generic-date-picker-popper-styles',
                  }}
                  onChange={newValue => {
                    setIsFilterYesterdaySelected(false)
                    setDateToValue(newValue)
                  }}
                  renderInput={params => (
                    <TextField {...params} sx={{ svg: { fontSize: 20 } }} />
                  )}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </div>
          <div className="row mt-5">
            <div className="col-6">
              <button
                className="btn-clear-filter"
                onClick={handleDateFilterClear}>
                {t('ACTION_TABLE_T.CLEAN_LABEL')}
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn-apply-filter"
                disabled={isDisabledApplyButton()}
                onClick={handleDateRangeApply}>
                {t('ACTION_TABLE_T.APPLY_LABEL')}
              </button>
            </div>
          </div>
        </div>
      </Popover>
    </>
  )
}
