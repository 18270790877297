import React from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { FormatMoney } from 'Utils/functions'
import { TermsTextArea } from '../../TermsTextArea'

export const DEFAULT_VALUE = 'Precio no incluye ITBMS'

export const Square1CRegular = ({ products, i }) => {
  const { terms } = usePrinterContext()

  const prod = products?.content?.products?.[0]
  const terms1 =
    terms?.find(term => term?.prodId === prod?.ProductId)?.value ||
    DEFAULT_VALUE
  const prodBasePrice = FormatMoney(prod?.BasePrice || 0)

  return (
    <div
      id="printer-preview-left-canvas"
      data-testid="printer-preview-left-canvas"
      className="printer-preview-left-canvas">
      <div className="printer-preview-left-content-body">
        <p className="printer-preview-left-content-body-title">
          {prod?.ProductName || ''}
        </p>
        <p className="printer-preview-left-content-body-sku">
          {prod?.Sku || ''}
        </p>
        {prod?.BasePrice && (
          <div className="printer-preview-left-content-body-price-wrapper">
            <p className="printer-preview-mini-text" style={{ color: '#000' }}>
              $
            </p>
            <p
              className="printer-preview-left-content-body-price"
              style={{ color: '#000', fontSize: '160px', marginTop: '70px' }}>
              {prodBasePrice?.toString()?.split('.')?.[0] || ''}.
            </p>
            <p className="printer-preview-mini-text" style={{ color: '#000' }}>
              {prodBasePrice?.toString()?.split('.')?.[1] || '00'}
            </p>
          </div>
        )}
      </div>
      <div className="printer-preview-left-content-footer">
        <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
          <TermsTextArea
            rows={1}
            prod={prod}
            defaultValue={terms1}
            className="printer-preview-left-content-footer-legal-1"
            styles={{ textAlign: 'end' }}
            size={16}
          />
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.cochez.regular1}
        alt="template-preview"
      />
    </div>
  )
}
