import React from 'react'
import imageCompression from 'browser-image-compression'
import { useDispatch } from 'react-redux'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { jsPDF as JsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { uploadFileAction } from 'redux/actions/cotizacionActions'
import { updateProductPrinted } from 'redux/services/printerServices'

const sheetSizes = {
  'square-1': { width: 11.05, height: 8.5, marginH: 0, marginV: 0 },
  'square-2': {
    width: 9.8,
    height: 11,
    marginH: 11,
    marginV: -1.85,
    rotate: 90,
  },
  'square-3': {
    width: 8,
    height: 10,
    marginH: 10.275,
    marginV: -1.75,
    rotate: 90,
  },
  'square-4': {
    width: 8.475,
    height: 11,
    marginH: 11,
    marginV: -2.5,
    rotate: 90,
  },
}

const sheetSizesWithSquare1 = {
  'square-1': { width: 11.05, height: 8.5, marginH: 0, marginV: 0 },
  'square-2': {
    width: 12.5,
    height: 11,
    marginH: 11,
    marginV: -0.5,
    rotate: 90,
  },
  'square-3': {
    width: 10,
    height: 10,
    marginH: 10.275,
    marginV: -0.75,
    rotate: 90,
  },
  'square-4': {
    width: 10.6,
    height: 11,
    marginH: 11,
    marginV: -1.45,
    rotate: 90,
  },
}

const sheetSizesForSquare2 = {
  'square-1': { width: 11.05, height: 8.5, marginH: 0, marginV: 0 },
  'square-2': {
    width: 8.5,
    height: 11,
    marginH: 11,
    marginV: -2.5,
    rotate: 90,
  },
  'square-3': {
    width: 8,
    height: 10,
    marginH: 10.275,
    marginV: -1.75,
    rotate: 90,
  },
  'square-4': { width: 8, height: 8.5, marginH: 1.5, marginV: 0 },
}

const fakeAwait = async () => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 200)
  })
}

export const useGeneratePrinter = () => {
  const dispatch = useDispatch()
  const { allTemplatesContent, templateRef } = usePrinterContext()
  const [printedUrl, setPrintedUrl] = React.useState('')

  const compressImage = async imgDataUrl => {
    const blob = dataUrlToBlob(imgDataUrl)
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    const compressedBlob = await imageCompression(blob, options)
    return await blobToDataUrl(compressedBlob)
  }

  const dataUrlToBlob = dataUrl => {
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  const blobToDataUrl = blob => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  const generateMultiPagePDF = async () => {
    const doc = new JsPDF({
      orientation: 'landscape',
      unit: 'in',
      format: [11, 8.5],
    })

    const overlay = document.getElementById('printer-overlay')
    overlay.style.display = 'flex'
    await fakeAwait()
    const container = document.getElementById('to-print-elements')
    container.style.display = 'block'

    const hasSquare1 = allTemplatesContent.some(
      content => content.template[0] === 'square-1'
    )
    const hasOnlySquare2 = allTemplatesContent.every(
      content => content.template[0] === 'square-2'
    )
    for (let i = 0; i < templateRef.current.length; i++) {
      if (templateRef.current[i] === null) continue
      const currentTemplate = allTemplatesContent[i]
      const templateType = currentTemplate?.template?.[0]
      const { width, height, marginH, marginV, rotate } = hasOnlySquare2
        ? sheetSizesForSquare2[templateType]
        : hasSquare1
        ? sheetSizesWithSquare1[templateType]
        : sheetSizes[templateType]
      const canvas = await html2canvas(templateRef.current[i], {
        scale: 2,
        windowWidth: width,
        windowHeight: height,
        logging: true,
      })
      const imgData = canvas.toDataURL('image/png')

      const compressedImgData = await compressImage(imgData)

      doc.addImage(
        compressedImgData,
        'PNG',
        marginH,
        marginV,
        width,
        height,
        '',
        'NONE',
        rotate || 0
      )
      if (
        i < templateRef.current.length - 1 &&
        templateRef.current[i + 1] !== null
      ) {
        doc.addPage()
      }
    }

    window.open(doc.output('bloburl'), '_blank')
    const containerToHide = document.getElementById('to-print-elements')
    containerToHide.style.display = 'none'
    await fakeAwait()

    const overlayToHide = document.getElementById('printer-overlay')
    overlayToHide.style.display = 'none'

    const pdfBlob = doc.output('blob')
    const formData = new FormData()
    formData.append('FormFile', pdfBlob)
    formData.append('TenantId', 2)
    formData.append('EntityType', 'Asset')
    formData.append('InternalCode', 'TICKET_PRINTER')
    formData.append('EntityId', 'TicketPrint')
    formData.append('Date', new Date().toISOString())
    const resultFile = await dispatch(uploadFileAction(formData))
    const resultUrl = resultFile?.Data?.[0]?.TableDoc?.FileUrl

    const selectedProducts = allTemplatesContent
      .map(content => content.content.products)
      .flat()
      .filter((product, index, self) => {
        return (
          index ===
          self.findIndex(
            t => t.ProductId === product.ProductId && t.Price === product.Price
          )
        )
      })

    const productPayload = selectedProducts.map(product => {
      return {
        ProductId: product.ProductId,
        PriceListId: product.PriceListId,
        Price: product?.Price || product.BasePrice,
        DocumentUrl: resultUrl,
        ListType: product.ListType,
      }
    })
    await updateProductPrinted(productPayload)
    setPrintedUrl(resultUrl)
  }
  return { generateMultiPagePDF, printedUrl }
}
