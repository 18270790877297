import React, { useState, useEffect } from 'react'
import iconFunnel from '../../../../../../Assets/Images/BuscarCotizacion/icon-funnel.svg'
import iconFunnelRed from '../../../../../../Assets/Images/BuscarCotizacion/icon-funnel-red.svg'
import { t } from 'i18next'
import { CircularProgress, Popover } from '@mui/material'
import { CustomCheckBox } from 'Components/CustomCheckBox'
import { getAllDepartmentsService } from 'redux/services/printerServices'

export const DepartmentFilter = ({
  handleGetData,
  selectedDepartment: defSelectedDepartment,
  departmentState: { departments, setDepartments },
}) => {
  const [dateFilterAnchorEl, setDateFilterAnchorEl] = useState(null)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedDepartment, setSelectedDepartment] = useState({
    id: defSelectedDepartment || '',
    name: '',
  })
  const [loading, setLoading] = useState(false)

  const handleApplyFilter = async () => {
    setIsSubmitting(true)
    await handleGetData({ departmentId: selectedDepartment?.id })
    setIsSubmitting(false)
  }
  const handleDateFilterClose = () => {
    setIsFilterOpen(false)
    setSelectedDepartment({
      id: defSelectedDepartment || '',
      name: '',
    })
  }

  const handleOpenFilter = async event => {
    setDateFilterAnchorEl(event.currentTarget)
    setIsFilterOpen(true)
  }

  useEffect(() => {
    const getAllDepartments = async () => {
      if (departments.length > 0) return
      try {
        setLoading(true)
        const data = await getAllDepartmentsService({
          DepartmentId: null,
          SearchText: null,
          PageNumber: 1,
          pageSize: 100,
          Status: true,
        })
        const departments = data?.filter(
          department => department?.Companies?.length > 0
        )
        const listOfDepartments = departments?.map(department => ({
          name: department?.Name,
          id: department?.DepartmentId,
        }))
        const orderByName = listOfDepartments?.sort((a, b) =>
          a?.name.localeCompare(b?.name)
        )
        setDepartments(orderByName)
      } catch (error) {
        console.log('Error getting departments', error)
      } finally {
        setLoading(false)
      }
    }
    isFilterOpen && getAllDepartments()
  }, [isFilterOpen])

  return (
    <>
      <span className="float-end">
        <img
          src={selectedDepartment?.id ? iconFunnelRed : iconFunnel}
          alt="filter"
          style={{ cursor: 'pointer' }}
          onClick={e => handleOpenFilter(e)}
        />
      </span>

      <Popover
        id="date-filter-popover"
        open={isFilterOpen}
        anchorEl={dateFilterAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleDateFilterClose}>
        <div className="department-filters-popover-wrapper">
          {loading && (
            <div className="department-filters-popover-loading-wrapper">
              <CircularProgress
                sx={{
                  '& .MuiCircularProgress-svg': { color: '#003082' },
                }}
                size={21}
              />
            </div>
          )}
          {!loading &&
            departments?.map(department => (
              <div
                key={department.id}
                className="department-filters-popover-options">
                <CustomCheckBox
                  key={department.id}
                  checked={selectedDepartment?.id === department.id}
                  onChange={() => {
                    setSelectedDepartment({
                      id: department.id,
                      name: department.name,
                    })
                  }}
                />
                <span className="department-filters-popover-text-option">
                  {department.name}
                </span>
              </div>
            ))}
          <div className="department-filters-popover-buttons">
            <div className="col-6">
              <button
                className="department-filters-popover-button-clean"
                disabled={isSubmitting}
                onClick={() => {
                  handleGetData({ departmentId: 'none' })
                  setSelectedDepartment({
                    id: '',
                    name: '',
                  })
                }}>
                {t('ACTION_TABLE_T.CLEAN_LABEL')}
              </button>
            </div>
            <div className="col-6">
              <button
                className="department-filters-popover-button-submit"
                disabled={!selectedDepartment?.id || isSubmitting}
                onClick={handleApplyFilter}>
                {isSubmitting ? (
                  <CircularProgress
                    sx={{
                      '& .MuiCircularProgress-svg': { color: '#FFFFFF' },
                    }}
                    size={21}
                  />
                ) : (
                  t('ACTION_TABLE_T.APPLY_LABEL')
                )}
              </button>
            </div>
          </div>
        </div>
      </Popover>
    </>
  )
}
