import React from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { FormatMoney } from 'Utils/functions'
import { formatStartEndDate } from 'Utils/Helpers/formatDate'

const defTermValue = (startDate, endDate) =>
  `Promoción válida del ${formatStartEndDate(
    startDate,
    endDate
  )}. La tarjeta digital CMF es emitida por CM Financiera, S.A. (CMF). *Valor cuota referencial, aplican condiciones. La responsabilidad de CMF se limita a los aspectos del crédito. Accede a www.cmf.com.pa para más información.`

export const Square3ToPrintNCmfOffer = ({ products, i }) => {
  const { templateRef, terms } = usePrinterContext()

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const prod3 = products?.content?.products?.[2] || {}
  const prod4 = products?.content?.products?.[3] || {}
  const terms1 =
    terms?.find(term => term?.prodId === prod1?.ProductId)?.value ||
    defTermValue(prod1?.StartDate, prod1?.FinishDate)
  const terms2 =
    terms?.find(term => term?.prodId === prod2?.ProductId)?.value ||
    defTermValue(prod2?.StartDate, prod2?.FinishDate)
  const terms3 =
    terms?.find(term => term?.prodId === prod3?.ProductId)?.value ||
    defTermValue(prod3?.StartDate, prod3?.FinishDate)
  const terms4 =
    terms?.find(term => term?.prodId === prod4?.ProductId)?.value ||
    defTermValue(prod4?.StartDate, prod4?.FinishDate)

  const prodBasePrice1 = FormatMoney(prod1?.BasePrice || 0)
  const prodBasePrice2 = FormatMoney(prod2?.BasePrice || 0)
  const prodBasePrice3 = FormatMoney(prod3?.BasePrice || 0)
  const prodBasePrice4 = FormatMoney(prod4?.BasePrice || 0)
  const prodPrice1 = FormatMoney(prod1?.Price || 0)
  const prodPrice2 = FormatMoney(prod2?.Price || 0)
  const prodPrice3 = FormatMoney(prod3?.Price || 0)
  const prodPrice4 = FormatMoney(prod4?.Price || 0)

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        id="printer-preview-left-canvas-square-3"
        data-testid="printer-preview-left-canvas-3"
        className="printer-preview-left-canvas-square-3">
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '100%',
            top: '0',
          }}>
          <div
            className="printer-preview-left-content-body-square-3"
            style={{ gap: 0 }}>
            <p className="printer-preview-left-content-body-square-3-title">
              {prod1?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku">
              {prod1?.Sku || ''}
            </p>
            {prod1?.Price && (
              <div
                className="printer-preview-left-content-body-square-3-price-wrapper"
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '62px',
                }}>
                <p className="printer-preview-mini-text-square-3">$</p>
                <p
                  className="printer-preview-left-content-body-square-3-price"
                  style={{ fontSize: '62px' }}>
                  {prodPrice1?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-3">
                  {prodPrice1?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
            {prod1?.BasePrice && (
              <div
                style={{
                  position: 'absolute',
                  top: '136px',
                  right: 0,
                  width: '100px',
                  height: '1px',
                  background: 'red',
                  transform: 'rotate(-12deg)',
                  marginBottom: '4px',
                }}
              />
            )}
            {prod1?.BasePrice && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '4px',
                  position: 'absolute',
                  top: '122px',
                  right: '0',
                }}>
                <p
                  style={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}>
                  Antes
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '10px',
                      marginTop: '-10px',
                    }}>
                    $
                  </p>
                  <p
                    className="printer-preview-left-content-body-price"
                    style={{ color: '#000', fontSize: '26px', marginTop: 0 }}>
                    {prodBasePrice1?.toString()?.split('.')?.[0] || ''}.
                  </p>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '10px',
                      marginTop: '-10px',
                    }}>
                    {prodBasePrice1?.toString()?.split('.')?.[1] || '00'}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-3"
            style={{ top: 'calc(50% - 84px)' }}>
            <div
              className="printer-preview-left-content-footer-square-3-quotes"
              style={{ opacity: prod1?.cmfCredit ? 1 : 0 }}>
              <p></p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                36 <br /> cuotas
              </p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                ${FormatMoney(prod1?.cmfCredit || 0) || ''}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
              <p
                className="printer-preview-left-content-footer-legal-3"
                style={{ border: 'none' }}>
                {terms1}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '100%',
            top: '0',
            left: '50%',
          }}>
          <div
            className="printer-preview-left-content-body-square-3"
            style={{ gap: 0 }}>
            <p className="printer-preview-left-content-body-square-3-title">
              {prod2?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku">
              {prod2?.Sku || ''}
            </p>
            {prod2?.Price && (
              <div
                className="printer-preview-left-content-body-square-3-price-wrapper"
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '62px',
                }}>
                <p className="printer-preview-mini-text-square-3">$</p>
                <p
                  className="printer-preview-left-content-body-square-3-price"
                  style={{ fontSize: '62px' }}>
                  {prodPrice2?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-3">
                  {prodPrice2?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
            {prod2?.BasePrice && (
              <div
                style={{
                  position: 'absolute',
                  top: '136px',
                  right: 0,
                  width: '100px',
                  height: '1px',
                  background: 'red',
                  transform: 'rotate(-12deg)',
                  marginBottom: '4px',
                }}
              />
            )}
            {prod2?.BasePrice && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '4px',
                  position: 'absolute',
                  top: '122px',
                  right: '0',
                }}>
                <p
                  style={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}>
                  Antes
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '10px',
                      marginTop: '-10px',
                    }}>
                    $
                  </p>
                  <p
                    className="printer-preview-left-content-body-price"
                    style={{ color: '#000', fontSize: '26px', marginTop: 0 }}>
                    {prodBasePrice2?.toString()?.split('.')?.[0] || ''}.
                  </p>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '10px',
                      marginTop: '-10px',
                    }}>
                    {prodBasePrice2?.toString()?.split('.')?.[1] || '00'}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-3"
            style={{ top: 'calc(50% - 84px)' }}>
            <div
              className="printer-preview-left-content-footer-square-3-quotes"
              style={{ opacity: prod2?.cmfCredit ? 1 : 0 }}>
              <p></p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                36 <br /> cuotas
              </p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                ${FormatMoney(prod2?.cmfCredit || 0) || ''}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
              <p
                className="printer-preview-left-content-footer-legal-3"
                style={{ border: 'none' }}>
                {terms2}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '50%',
            top: '50%',
          }}>
          <div
            className="printer-preview-left-content-body-square-3-2"
            style={{ top: '70px', gap: 0 }}>
            <p className="printer-preview-left-content-body-square-3-title-2">
              {prod3?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku-2">
              {prod3?.Sku || ''}
            </p>
            {prod3?.Price && (
              <div
                className="printer-preview-left-content-body-square-3-price-wrapper"
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '66px',
                }}>
                <p className="printer-preview-mini-text-square-3">$</p>
                <p
                  className="printer-preview-left-content-body-square-3-price-2"
                  style={{ fontSize: '64px' }}>
                  {prodPrice3?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-3">
                  {prodPrice3?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
            {prod3?.BasePrice && (
              <div
                style={{
                  position: 'absolute',
                  top: '136px',
                  right: 0,
                  width: '100px',
                  height: '1px',
                  background: 'red',
                  transform: 'rotate(-12deg)',
                  marginBottom: '4px',
                }}
              />
            )}
            {prod3?.BasePrice && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '4px',
                  position: 'absolute',
                  top: '120px',
                  right: '0',
                }}>
                <p
                  style={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}>
                  Antes
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '10px',
                      marginTop: '-10px',
                    }}>
                    $
                  </p>
                  <p
                    className="printer-preview-left-content-body-price"
                    style={{ color: '#000', fontSize: '26px', marginTop: 0 }}>
                    {prodBasePrice3?.toString()?.split('.')?.[0] || ''}.
                  </p>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '10px',
                      marginTop: '-10px',
                    }}>
                    {prodBasePrice3?.toString()?.split('.')?.[1] || '00'}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-3-2"
            style={{ top: 'calc(100% - 84px)' }}>
            <div
              className="printer-preview-left-content-footer-square-3-quotes"
              style={{ opacity: prod3?.cmfCredit ? 1 : 0 }}>
              <p></p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                36 <br /> cuotas
              </p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                ${FormatMoney(prod3?.cmfCredit || 0) || ''}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
              <p
                className="printer-preview-left-content-footer-legal-3"
                style={{ border: 'none' }}>
                {terms3}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-3-box"
          style={{
            height: '50%',
            top: '50%',
            left: '50%',
          }}>
          <div
            className="printer-preview-left-content-body-square-3-2"
            style={{ top: '70px', gap: 0 }}>
            <p className="printer-preview-left-content-body-square-3-title-2">
              {prod4?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-3-sku-2">
              {prod4?.Sku || ''}
            </p>
            {prod4?.Price && (
              <div
                className="printer-preview-left-content-body-square-3-price-wrapper"
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '66px',
                }}>
                <p className="printer-preview-mini-text-square-3">$</p>
                <p
                  className="printer-preview-left-content-body-square-3-price-2"
                  style={{ fontSize: '62px' }}>
                  {prodPrice4?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-3">
                  {prodPrice4?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
            {prod4?.BasePrice && (
              <div
                style={{
                  position: 'absolute',
                  top: '136px',
                  right: 0,
                  width: '100px',
                  height: '1px',
                  background: 'red',
                  transform: 'rotate(-12deg)',
                  marginBottom: '4px',
                }}
              />
            )}
            {prod4?.BasePrice && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '4px',
                  position: 'absolute',
                  top: '120px',
                  right: '0',
                }}>
                <p
                  style={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}>
                  Antes
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '10px',
                      marginTop: '-10px',
                    }}>
                    $
                  </p>
                  <p
                    className="printer-preview-left-content-body-price"
                    style={{ color: '#000', fontSize: '26px', marginTop: 0 }}>
                    {prodBasePrice4?.toString()?.split('.')?.[0] || ''}.
                  </p>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '10px',
                      marginTop: '-10px',
                    }}>
                    {prodBasePrice4?.toString()?.split('.')?.[1] || '00'}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-3-2"
            style={{ top: 'calc(100% - 84px)' }}>
            <div
              className="printer-preview-left-content-footer-square-3-quotes"
              style={{ opacity: prod4?.cmfCredit ? 1 : 0 }}>
              <p></p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                36 <br /> cuotas
              </p>
              <p className="printer-preview-left-content-footer-square-3-quote-text">
                ${FormatMoney(prod4?.cmfCredit || 0) || ''}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
              <p
                className="printer-preview-left-content-footer-legal-3"
                style={{ border: 'none' }}>
                {terms4}
              </p>
            </div>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.novey.cmfOffer3}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
