import React, { useEffect, useState, useMemo } from 'react'
import Navigation from '../../Components/Navigation'
import No from '../../Assets/Images/Login/no.svg'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Check from '../../Assets/Images/Login/check.png'
import './generarCotizacion.css'
import NoImage from '../../Assets/Images/Logos/no-image.png'
import { useSelector, useDispatch } from 'react-redux'
import {
  addComment,
  guardarCotizacion,
  mostrarDescuento,
  addChannel,
} from '../../redux/actions/cotizacionActions.js'
import {
  AddUpdateSalesDocumentsService,
  searchSalesDocumentDetailService,
} from 'redux/services/cotizacionServices'
import { BoxArrowUp } from 'react-bootstrap-icons'
import { useParams, useHistory } from 'react-router-dom'
import FilteringTable from '../../Components/FilteringTable'
import {
  FormatMoney,
  updateHiddenColumns,
  validateRoles,
} from 'Utils/functions'
import GenericModal from 'Components/GenericModal'
import ConfirmStartSalesOrder from 'Components/SalesOrder/ConfirmStartSalesOrder'
import GenerateSalesOrder from 'Components/SalesOrder/GenerateSalesOrder'
import {
  creditAccountsAction,
  getChannelsAction,
} from 'redux/actions/catalogActions'
import { ID_CONSTANTS } from 'redux/types'
import { documentTypeId, salesOrderTypeIds } from 'Utils/constants'
import { generateSellOrderAction } from 'redux/actions/sellOrderActions'
import MarginText from 'Components/MarginText/MarginText'
import { t } from 'i18next'
import ModalAlertCart from 'Components/ModalAlertCart/ModalAlertCart'
import { useUploadOdcFile } from 'Views/OrdenDeVenta/hooks/useUploadOdcFile'

function SubtotalText() {
  const showDiscountOption = useSelector(
    state => state.cotizacion.QuoteHeader.ShowDiscountOption
  )

  return (
    <div className="money-data-text">
      <p>Sub Total</p>
      <p
        style={{
          display: showDiscountOption ? 'block' : 'none',
        }}>
        Descuento
      </p>
      <p>ITBMS</p>
      <p>Total</p>
    </div>
  )
}

function SubtotalNumbers({ setDiscount }) {
  const useSelectorProductos = useSelector(
    state => state.cotizacion.QuoteHeader.Lines
  )

  const useSelectorDetalle = useSelector(state => state.cotizacion.QuoteHeader)
  const showDiscountOption = useSelector(
    state => state.cotizacion.QuoteHeader.ShowDiscountOption
  )
  const [subtotal, setSubTotal] = useState(0)
  const [subtotalWithDiscount, setSubtotalWithDiscount] = useState(0)
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0)

  const calcSubtotal = () => {
    let subtotal = 0
    let subtotalWithDiscount = 0
    let DiscountAmount = 0
    useSelectorProductos.forEach(item => {
      DiscountAmount +=
        Number(FormatMoney(item.DiscountAmount, false, 4).replace(/,/g, '')) *
        item.Quantity
      subtotal += item.Price * item.Quantity
      subtotalWithDiscount +=
        item.Price > item.BasePrice
          ? item.Price * item.Quantity
          : item.BasePrice * item.Quantity
      setSubTotal(subtotal)
      setSubtotalWithDiscount(subtotalWithDiscount)
      setDiscount(DiscountAmount)
      setTotalDiscountAmount(DiscountAmount)
    })
  }

  useEffect(() => {
    calcSubtotal()
  }, [useSelectorProductos, showDiscountOption])
  return (
    <div className="money-data-totals">
      <p>
        $
        {showDiscountOption
          ? FormatMoney(subtotalWithDiscount, true, 2)
          : FormatMoney(subtotal, true, 2)}
      </p>
      <p
        style={{
          display: showDiscountOption ? 'block' : 'none',
        }}>
        ${FormatMoney(totalDiscountAmount, false, 2)}
      </p>
      <p>${FormatMoney(useSelectorDetalle.Tax.replace(/,/g, ''), true, 2)}</p>
      <p>
        $
        {FormatMoney(
          Number(FormatMoney(subtotal, true, 2).replace(/,/g, '')) +
            Number(
              FormatMoney(
                useSelectorDetalle.Tax.replace(/,/g, ''),
                true,
                2
              ).replace(/,/g, '')
            ),
          true,
          2
        )}
      </p>
    </div>
  )
}

function GenerarCotizacion(props) {
  const { uploadFile } = useUploadOdcFile()
  const [comment, setComment] = useState('')
  const [discount, setDiscount] = useState(0)
  const { Channel } = useSelector(state => state.cotizacion.QuoteHeader)
  const [open, setOpen] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [hasRelationDocs, setHasRelationDocs] = useState(false)
  const [showChangesODVAlert, setShowChangesODVAlert] = useState(false)
  const [showModalConfirmStartSalesOrder, setShowModalConfirmStartSalesOrder] =
    useState(false)
  const [confirmStartSalesOrder, setConfirmStartSalesOrder] = useState(false)
  const [showModalGenerateSalesOrder, setShowModalGenerateSalesOrder] =
    useState(false)
  const useSelectorQuoteObject = useSelector(state => state.cotizacion)
  const [directdeliveryState] = useState(false)
  // const { isCommercialCreditSelected } = useSelector(state => state.sellOrder);
  const [showFailedSellOrderState, setShowFailedSellOrderState] =
    useState(false)

  const history = useHistory()

  const param = useParams()
  const user = useSelector(state => state.login.user)
  const useSelectorProductos = useSelector(
    state => state.cotizacion.QuoteHeader.Lines
  )

  const [createOdv, setCreateOdv] = useState(false)
  const company = useSelector(state => state.company)

  const useSelectorDetalle = useSelector(state => state.cotizacion.QuoteHeader)

  const useEstadoGuardar = useSelector(state => state.cotizacion)

  const showDiscountOption = useSelector(
    state => state.cotizacion.QuoteHeader.ShowDiscountOption
  )

  const [channelList, setChannelList] = useState([])
  const isDistributionSeller = validateRoles(
    user?.AuthenticationInfo?.UserRoles,
    'Vendedor Comercial Distribución'
  )

  const isConstructionSeller = validateRoles(
    user?.AuthenticationInfo?.UserRoles,
    'Vendedor Comercial Construcción'
  )

  // columnas ocultas
  const [hiddenColumns, setHiddenColumns] = useState([])
  const [columns] = useState([
    {
      Header: 'Referencia',
      accessor: 'Referencia',
      Footer: <MarginText />,
      hideEnabled: true,
    },
    {
      Header: 'Descripción',
      accessor: 'Descripción',
    },
    {
      Header: 'Imagen',
      accessor: 'Imagen',
      hideEnabled: true,
    },
    {
      Header: 'Marca',
      accessor: 'Marca',
      hideEnabled: true,
    },
    {
      Header: 'País de Origen',
      accessor: 'País de Origen',
      hideEnabled: true,
    },
    {
      Header: 'Cantidad',
      accessor: 'Cantidad',
    },
    {
      Header: 'Precio Unitario',
      accessor: 'Precio Unitario',
      Footer: <SubtotalText />,
    },
    {
      Header: 'Descuento',
      accessor: 'Descuento',
      hideEnabled: true,
      Footer: <SubtotalText />,
    },
    {
      Header: 'Precio Total',
      accessor: 'Precio Total',
      Footer: <SubtotalNumbers setDiscount={setDiscount} />,
    },
  ])

  const dispatch = useDispatch()

  const Hoy = new Date()
  const dd = String(Hoy.getDate()).padStart(2, '0')
  const mm = String(Hoy.getMonth() + 1).padStart(2, '0') // January is 0!
  const yyyy = Hoy.getFullYear()
  const Fecha = yyyy + '-' + mm + '-' + dd

  const setTableData = data => {
    const products = data.map(product => {
      return {
        Referencia: product?.Sku,
        Descripción: product?.ProductName,
        Imagen:
          (product.Images && product.Images[0].URL) ||
          product.DefaultProductPicture ||
          NoImage,
        Marca: product?.Brand,
        'País de Origen': 'Panamá',
        Cantidad: product?.Quantity,
        'Precio Unitario':
          !showDiscountOption || product?.Price > product?.BasePrice
            ? product?.Price === null
              ? 0
              : product?.Price
            : product?.BasePrice,
        Descuento: product?.DiscountAmount,
        'Precio Total': product?.Price * product?.Quantity,
      }
    })
    return products
  }

  const productsData = useMemo(
    () => setTableData(useSelectorProductos),
    [showDiscountOption]
  )

  const hasOdv = useEstadoGuardar?.QuoteHeader?.RelationDoc?.find(
    doc =>
      doc?.DocumentNumber.includes('ODV') ||
      doc?.DocumentTypeId?.toLowerCase().includes(
        documentTypeId?.ODV?.toLowerCase()
      )
  )

  const fetchDocumentODV = async () => {
    const payload = {
      DocumentTypeId: hasOdv.DocumentTypeId,
      DocumentHeaderId: hasOdv.DocHeaderId,
      DocumentNumber: hasOdv.DocumentNumber,
      PageNumber: 1,
      PageSize: 1,
    }
    const respuesta = await searchSalesDocumentDetailService(payload)
    const odvAllData = respuesta?.data?.Data
    const cotiAllData = useEstadoGuardar.QuoteHeader
    const addSalesOrderLineId = respuesta?.data?.Data?.Lines.map(prod => ({
      ...prod,
      SalesOrderLineId: prod.DocLineId,
    }))
    const odvProducts = addSalesOrderLineId
    const cotiProds = useEstadoGuardar.QuoteHeader.Lines
    const productCheck = cotiProds?.map(prod => {
      odvAllData.Amount = cotiAllData.Amount
      odvAllData.AmountWithTax = cotiAllData.AmountWithTax
      odvAllData.Tax = cotiAllData.Tax
      const foundProduct = odvProducts?.find(
        item => item.ProductId === prod.ProductId
      )
      if (foundProduct) {
        if (
          prod.Amount !== foundProduct.Amount ||
          prod.price !== prod.FinalPrice
        ) {
          foundProduct.Amount = prod.Amount
          foundProduct.AmountWithTax = prod.AmountWithTax
          foundProduct.DiscountAmount = prod.DiscountAmount
          foundProduct.Tax = prod.Tax
          foundProduct.Price = prod.Price
          foundProduct.Quantity = Math.ceil(prod.Quantity)
          foundProduct.QtyOrdered = Math.ceil(prod.Quantity)
          if (prod.Price < prod.FinalPrice) {
            prod.AuthorizedPriceBy = null
            prod.AuthorizedPriceByName = null
            prod.NegotiatedPriceBy = user?.AuthenticationInfo?.UserId
            prod.NegotiatedPriceByName = user?.AuthenticationInfo?.UserName
          } else {
            prod.NegotiatedPriceBy = null
            prod.NegotiatedPriceByName = null
          }
        }
      } else {
        if (prod.Price < prod.FinalPrice) {
          prod.AuthorizedPriceBy = null
          prod.AuthorizedPriceByName = null
          prod.NegotiatedPriceBy = user?.AuthenticationInfo?.UserId
          prod.NegotiatedPriceByName = user?.AuthenticationInfo?.UserName
        } else {
          prod.NegotiatedPriceBy = null
          prod.NegotiatedPriceByName = null
        }
        return {
          ...prod,
          SalesOrderLineId: '00000000-0000-0000-0000-000000000000',
          QtyOrdered: prod.Quantity,
        }
      }
      const odvProduct = odvProducts?.find(
        item => item?.ProductId === prod?.ProductId
      )
      return {
        ...prod,
        QtyCanceled: odvProduct?.QtyCanceled || 0,
        QtyDelivered: odvProduct?.QtyDelivered || 0,
        QtyDeliveredCalculated: odvProduct?.QtyDeliveredCalculated || 0,
        QtyOutstanding: odvProduct?.QtyOutstanding || 0,
        QtyPending: odvProduct?.QtyPending || 0,
        SalesOrderLineId: foundProduct.SalesOrderLineId,
        QtyOrdered: prod.Quantity,
      }
    })
    const odvSavePayload = {
      DocumentTypeId: hasOdv.DocumentTypeId,
      SalesOrder: {
        SalesOrderHeaderId: hasOdv.DocHeaderId,
        ...odvAllData,
        Lines: productCheck,
      },
    }
    await AddUpdateSalesDocumentsService(odvSavePayload)
  }

  const fetchChannel = async () => {
    const Data = await dispatch(getChannelsAction())
    if (Data && Data?.length > 0 && Data?.[0]?.ChannelId) {
      setChannelList(Data)
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const saveCotizacion = () => {
    if (comment === null || comment === undefined) {
      setComment('')
      dispatch(addComment(''))
      useEstadoGuardar.QuoteHeader.Comment = ''
    } else {
      useEstadoGuardar.QuoteHeader.Comment = comment
      dispatch(addComment(comment))
    }
    const newColumns = []
    const newHiddenColumns = hiddenColumns.map(column => {
      return column.ColumnName
    })
    const columnsVisible = updateHiddenColumns(newHiddenColumns, columns)
    // eslint-disable-next-line array-callback-return
    columnsVisible.map(column => {
      newColumns.push({
        ...column,
        DocumentId: useEstadoGuardar.QuoteHeader.QuoteHeaderId,
      })
    })
    if (newColumns.length > 0) {
      useEstadoGuardar.Columns = newColumns
    }
    useEstadoGuardar.QuoteHeader = {
      ...useEstadoGuardar.QuoteHeader,
      Lines: useEstadoGuardar.QuoteHeader.Lines.map((linea, index) => ({
        ...linea,
        LineNum: index + 1,
        Amount: Number(FormatMoney(linea.Amount, false, 6).replace(/,/g, '')),
        AmountWithTax: Number(
          FormatMoney(linea.AmountWithTax, false, 6).replace(/,/g, '')
        ),
        Tax: Number(FormatMoney(linea.Tax, false, 6).replace(/,/g, '')),
      })),
      Discount: discount,
      CompanyId: company.CompanyId,
      CompanyCode: company?.CompanyCode,
      WarehouseId: company?.WarehouseId,
      WarehouseDescription: company?.WarehouseDescription,
      WarehouseAddress: company?.WarehouseAddress,
      WarehouseCode: company?.WarehouseCode,
      WarehouseName: company?.WarehouseName,
      SalesAgentId:
        useSelectorDetalle?.SalesAgentId || user?.AuthenticationInfo?.UserId,
    }

    dispatch({
      type: 'SET_IS_REDIRECT',
      payload: true,
    })
    dispatch(guardarCotizacion(useEstadoGuardar, props))
    if (hasOdv) {
      fetchDocumentODV()
    }
  }

  const noChannel = () => {
    setShowMessage(true)
    saveCotizacion()
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setComment(useSelectorDetalle.Comment)
    setHasRelationDocs(
      useSelectorDetalle?.RelationDoc &&
        useSelectorDetalle?.RelationDoc.length > 0
    )
  }, [])

  useEffect(() => {
    fetchChannel()
  }, [])

  const handleModalConfirmStartSalesOrder = () => {
    setShowModalConfirmStartSalesOrder(!showModalConfirmStartSalesOrder)
  }

  const processSalesOrder = async values => {
    dispatch({ type: 'SELL_ORDER_FILE_ATTACHED', payload: values.file })
    dispatch({ type: 'IS_COMMERCIAL_CREDIT_SELECTED', payload: values })
    await generateODV(values)
  }

  const generateODV = async values => {
    const isCreditCommercial = values?.credit === 'yes'

    const payload = {
      documentTypeId: ID_CONSTANTS.salesOrder.sellOrderTypeID,
      salesOrder: {
        addressId: useSelectorDetalle?.Address_AddressId,
        customerId: useSelectorQuoteObject?.QuoteHeader?.CustomerId,
        customerNameDocument:
          useSelectorQuoteObject?.QuoteHeader?.CustomerNameDocument,
        creditAccountId:
          values?.credit === 'yes'
            ? values?.accountItemSelected?.CreditAccountId
            : null,
        salesAgentId: useSelectorQuoteObject?.QuoteHeader?.SalesAgentId,
        DocumentDate: useSelectorQuoteObject?.QuoteHeader?.DocumentDate,
        quantity: useSelectorQuoteObject?.QuoteHeader?.Quantity,
        amount: useSelectorQuoteObject?.QuoteHeader?.Amount,
        amountWithTax: useSelectorQuoteObject?.QuoteHeader?.AmountWithTax,
        tax: useSelectorQuoteObject?.QuoteHeader?.Tax,
        cost: useSelectorQuoteObject?.QuoteHeader?.Cost,
        companyId: useSelectorQuoteObject?.QuoteHeader?.CompanyId,
        companyCode: useSelectorQuoteObject?.QuoteHeader?.CompanyCode,
        quoteHeaderId: useSelectorQuoteObject?.QuoteHeader?.QuoteHeaderId,
        salesOrderTypeId:
          values?.credit === 'yes'
            ? salesOrderTypeIds.credito_comercial
            : salesOrderTypeIds.pago_contado,
        HeaderStatusId: ID_CONSTANTS.salesOrder.headerStatusSalesOrderID,
        isDirectDelivery: directdeliveryState ? 1 : 0,
        salesOrderStatusId: ID_CONSTANTS.salesOrder.salesOrderStatusID,
        comments: comment,
        salesAgentEmail: null,
        lines: populateItemsArrayOfPayload(),
        warehouseId: useSelectorQuoteObject?.QuoteHeader?.WarehouseId,
        warehouseCode: useSelectorQuoteObject?.QuoteHeader?.WarehouseCode,
        origin: 'VA',
        Channel,
        CreditAccountId: isCreditCommercial
          ? values?.accountItemSelected?.CreditAccountId
          : null,
        CreditAccountNumber: isCreditCommercial
          ? values?.accountItemSelected?.CreditAccountNumber
          : null,
        OrderNumber: isCreditCommercial ? values?.orderNumber : null,
      },
    }
    const result = await dispatch(generateSellOrderAction(payload))
    if (result.message !== 'ERROR') {
      if (values.file) {
        await uploadFile({
          file: values.file,
          docData: result.Data.SalesDocumentInfo,
        })
      }

      dispatch({
        type: 'SET_PREORDER_VALIDATION',
        payload: true,
      })

      dispatch({
        type: 'SET_IS_DIRECT_DELIVERY',
        payload: directdeliveryState,
      })

      dispatch({
        type: 'SET_IS_REDIRECT',
        payload: false,
      })

      const route = `/orden_de_venta/created/${result.Data.SalesDocumentInfo?.DocHeaderId}`
      history.push(route)
    } else {
      setShowFailedSellOrderState(true)
    }
  }

  const populateItemsArrayOfPayload = () => {
    const arrayOfItems = []

    useSelectorQuoteObject?.QuoteHeader?.Lines.forEach((lineItem, i) => {
      const payloadFormation = {
        productId: lineItem?.ProductId,
        priceListId: lineItem?.PriceListId,
        warehouseId: useSelectorQuoteObject?.QuoteHeader?.WarehouseId,
        quantity: lineItem?.Quantity,
        amount: lineItem?.Amount,
        amountWithTax: lineItem?.AmountWithTax,
        BasePrice: lineItem?.BasePrice,
        DiscountAmount: lineItem?.DiscountAmount,
        FinalPrice: lineItem?.FinalPrice,
        tax: lineItem?.Tax,
        cost: lineItem?.Cost,
        price: lineItem?.Price,
        lineNum: i + 1,
        qtyOrdered: lineItem?.Quantity,
        qtyDelivered: 0,
        qtyCanceled: 0,
        qtyOutstanding: 0,
        isDirectDelivery: lineItem?.IsDirectDelivery,
      }

      const cleanEntries = Object.fromEntries(
        Object.entries(payloadFormation).filter(([_, v]) => v != null)
      )

      arrayOfItems.push(cleanEntries)
    })

    return arrayOfItems
  }
  const generateSalesOrder = () => {
    dispatch({
      type: 'CLEAN_SELL_ORDER',
      payload: null,
    })
    handleModalConfirmStartSalesOrder()
  }

  useMemo(() => {
    setCreateOdv(param?.type_document === 'sales_order')

    if (param?.type_document === 'sales_order') {
      dispatch(
        creditAccountsAction({
          customerId: useSelectorDetalle?.CustomerId,
          companyId: useSelectorDetalle?.CompanyId,
        })
      )
    }
  }, [])

  const checkRelationDocs = () => {
    if (createOdv) {
      generateSalesOrder()
    } else {
      if (hasRelationDocs) {
        setShowChangesODVAlert(hasRelationDocs)
      } else {
        setShowChangesODVAlert(false)
        Channel ? noChannel() : handleOpen()
      }
    }
  }

  const handleModalGenerateSalesOrder = () => {
    setShowModalGenerateSalesOrder(!showModalGenerateSalesOrder)
  }

  useEffect(() => {
    if (confirmStartSalesOrder) {
      setConfirmStartSalesOrder(false)
      handleModalGenerateSalesOrder()
    }
  }, [confirmStartSalesOrder])

  const hiddenColumnDiscount = bool => {
    if (bool !== showDiscountOption) {
      dispatch(mostrarDescuento(bool))
    }
  }

  const getArrayHiddenColumns = hiddenColumns => {
    const hiddenColumnsCopy = hiddenColumns.filter(column =>
      column.Status === false ? column.ColumnName : false
    )
    return hiddenColumnsCopy.map(column => column.ColumnName)
  }

  useEffect(() => {
    if (isConstructionSeller) {
      return dispatch(addChannel('Ventas a clientes – Construcción'))
    } else if (isDistributionSeller) {
      return dispatch(addChannel('Ventas a clientes – Distribución'))
    }
  }, [isConstructionSeller, isDistributionSeller])

  return (
    <>
      <Navigation />
      <ModalAlertCart preQuote={true} shopping={false} saleProduct={false} />
      <div className="cotizacion-generada-container">
        <div className="cotizacion-generada">
          <h2 className="titulo-cotizacion">Detalle de cotización</h2>
          <p className="alert-document">
            Verifica que los datos en la cotización creada sean los correctos
          </p>
          <div className="info-general">
            <div className="info-general-cliente">
              <p>Cliente: {useSelectorDetalle.CustomerNameDocument}</p>
              <p>
                Email:{' '}
                {useSelectorDetalle.EmailAddress
                  ? useSelectorDetalle.EmailAddress
                  : 'Sin información'}
              </p>
              <p>
                Teléfono: {useSelectorDetalle.Telephone || 'Sin información'}
              </p>
            </div>
            <div className="info-general-retiro">
              {useSelectorDetalle?.Address_AddressId ? (
                <>
                  <p>Envío a domicilio</p>
                  <p>
                    {t('Address', {
                      value:
                        useSelectorDetalle?.Address_Street ||
                        t('whithoutDatail'),
                    })}
                  </p>
                  <p>
                    {`${useSelectorDetalle.Address_DistrictName}, ${useSelectorDetalle.Address_CountryName}`}
                  </p>
                  <p>
                    {t('Alias', {
                      value:
                        useSelectorDetalle.Address_AddressDetail ||
                        t('whithoutDatail'),
                    })}
                  </p>
                </>
              ) : (
                <>
                  <p>Retiro en sucursal</p>
                  <p>{useSelectorDetalle.WarehouseAddress}</p>
                  <p>{useSelectorDetalle.WarehouseDescription}</p>
                  <p>{useSelectorDetalle.WarehouseCode}</p>
                </>
              )}
            </div>
            <div className="info-general-vendedor">
              <p>Vendedor: {useSelectorDetalle.SalesAgentFullName}</p>
              <p>Fecha: {Fecha}</p>
            </div>
          </div>
          <FilteringTable
            data={productsData}
            columns={columns}
            isProductPage={true}
            setHiddenColumns={columns => setHiddenColumns(columns)}
            hiddenColumns={getArrayHiddenColumns(hiddenColumns)}
            hiddenColumnDiscount={bool => {
              hiddenColumnDiscount(bool)
            }}
            showDiscountOption={showDiscountOption}
          />
        </div>
        <div className="cotizacion-generada-documento">
          <div>
            <h2 style={{ fontSize: '16px' }}>Adjuntar documento</h2>
            <div className="adjuntar">
              <label htmlFor="cotizacion-file-upload">
                <BoxArrowUp /> Examinar
              </label>
              <input id="cotizacion-file-upload" type="file" />
            </div>
          </div>
          <div>
            <h2 style={{ fontSize: '16px' }}>Agregar comentario</h2>
            <textarea
              value={comment || ''}
              onChange={e => setComment(e.target.value)}
            />
          </div>
        </div>
        <div className="cotizacion-save-button">
          <button
            onClick={() => checkRelationDocs()}
            className="guardar_button">
            {createOdv ? 'Guardar orden de venta' : 'Guardar'}
          </button>
        </div>
        <Modal
          open={showChangesODVAlert}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box>
            <GenericModal
              hideCheck={true}
              showCaution={false}
              setConfirm={() => {
                setShowChangesODVAlert(false)
                Channel ? noChannel() : handleOpen()
              }}
              setIsOpen={() => setShowChangesODVAlert(false)}
              text={
                '¿Estás seguro que deseas aplicar los cambios en la cotización?'
              }
              text2={'Estos cambios actualizarán la orden de venta generada'}
              mostrarButtons={true}
              closeLessPadding={true}
              gapTexts={true}></GenericModal>
          </Box>
        </Modal>
        <Modal
          open={showFailedSellOrderState}
          onClose={() => {
            setShowFailedSellOrderState(false)
          }}>
          <Box>
            <GenericModal
              hideCheck={true}
              showCaution={true}
              setConfirm={() => {}}
              setIsOpen={() => setShowFailedSellOrderState(false)}
              text={'No se ha podido crear la orden de venta'}
              mostrarButtons={false}></GenericModal>
          </Box>
        </Modal>
        {/* Modal confirmar iniciar orden de venta */}
        <Modal open={showModalConfirmStartSalesOrder}>
          <Box>
            <ConfirmStartSalesOrder
              setShowModalConfirmStartSalesOrder={
                handleModalConfirmStartSalesOrder
              }
              setConfirmStartSalesOrder={setConfirmStartSalesOrder}
            />
          </Box>
        </Modal>
        {/* Modal generar orden de venta */}
        <Modal open={showModalGenerateSalesOrder}>
          <Box>
            <GenerateSalesOrder
              setShowModalGenerateSalesOrder={handleModalGenerateSalesOrder}
              processSalesOrder={processSalesOrder}
            />
          </Box>
        </Modal>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className="modal-box-channel">
            <div className="closing-button">
              <img src={No} onClick={handleClose} alt="close" />
            </div>
            <div className="modal-box-channel-content">
              <div className="modal-box-channel-heading">
                <h2>Selecciona el canal por donde entró la solicitud</h2>
              </div>
              <ul className="modal-box-channels">
                {channelList?.map(({ ChannelId, Channel }) => (
                  <li key={ChannelId}>
                    <input
                      type="radio"
                      name="canal"
                      id={Channel}
                      value={Channel}
                      onClick={({ target }) => {
                        dispatch(addChannel(target.value))
                      }}
                    />
                    <label htmlFor={Channel}>{Channel}</label>
                  </li>
                ))}
              </ul>
              <button
                onClick={() => {
                  setShowMessage(true)
                  handleClose()
                  saveCotizacion()
                }}>
                Continuar
              </button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={showMessage}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className="modal-box-message">
            <div className="modal-box-channel-message">
              <div className="closing-button">
                <img
                  src={No}
                  onClick={() => setShowMessage(false)}
                  alt="close"
                />
              </div>
              <div className="modal-box-channel-message-content">
                <img src={Check} alt="check" />
                <h2>
                  La cotización ha sido creada
                  <br />
                  exitosamente
                </h2>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  )
}

export default GenerarCotizacion
