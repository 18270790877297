import React, { useEffect } from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useGetCmfCredit } from '../../../hooks/useGetCmfCredit'
import { FormatMoney } from 'Utils/functions'
import { TermsTextArea } from '../../TermsTextArea'
import { disclaimers } from 'Views/PrinterPreview/constants'

export const DEFAULT_VALUE = disclaimers.regularCmf

export const Square3 = ({ products }) => {
  const { terms } = usePrinterContext()

  const { fetchCmfCredit, loading } = useGetCmfCredit()

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const prod3 = products?.content?.products?.[2] || {}
  const prod4 = products?.content?.products?.[3] || {}
  const terms1 =
    terms?.find(term => term?.prodId === prod1?.ProductId)?.value ||
    DEFAULT_VALUE
  const terms2 =
    terms?.find(term => term?.prodId === prod2?.ProductId)?.value ||
    DEFAULT_VALUE
  const terms3 =
    terms?.find(term => term?.prodId === prod3?.ProductId)?.value ||
    DEFAULT_VALUE
  const terms4 =
    terms?.find(term => term?.prodId === prod4?.ProductId)?.value ||
    DEFAULT_VALUE
  const prodBasePrice1 = FormatMoney(prod1?.BasePrice || 0)
  const prodBasePrice2 = FormatMoney(prod2?.BasePrice || 0)
  const prodBasePrice3 = FormatMoney(prod3?.BasePrice || 0)
  const prodBasePrice4 = FormatMoney(prod4?.BasePrice || 0)

  useEffect(() => {
    if (prod1?.Sku && !prod1?.cmfCredit) {
      fetchCmfCredit(prod1?.BasePrice, prod1?.Sku)
    }
    if (prod2?.Sku && !prod2?.cmfCredit) {
      fetchCmfCredit(prod2?.BasePrice, prod2?.Sku)
    }
    if (prod3?.Sku && !prod3?.cmfCredit) {
      fetchCmfCredit(prod3?.BasePrice, prod3?.Sku)
    }
    if (prod4?.Sku && !prod4?.cmfCredit) {
      fetchCmfCredit(prod4?.BasePrice, prod4?.Sku)
    }
  }, [products])

  return (
    <div
      id="printer-preview-left-canvas-square-3"
      data-testid="printer-preview-left-canvas-3"
      className="printer-preview-left-canvas-square-3">
      <div
        className="printer-preview-left-canvas-square-3-box"
        style={{
          height: '100%',
          top: '-4px',
        }}>
        <div className="printer-preview-left-content-body-square-3">
          <p className="printer-preview-left-content-body-square-3-title">
            {prod1?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-3-sku">
            {prod1?.Sku || ''}
          </p>
          {prod1?.BasePrice && (
            <div className="printer-preview-left-content-body-square-3-price-wrapper">
              <p
                className="printer-preview-mini-text-square-3"
                style={{ marginTop: '8px' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-square-3-price"
                style={{ fontSize: '60px', marginTop: '40px' }}>
                {prodBasePrice1?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text-square-3"
                style={{ marginTop: '8px' }}>
                {prodBasePrice1?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div className="printer-preview-left-content-footer-square-3">
          <div className="printer-preview-left-content-footer-square-3-quotes">
            <p></p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              36 <br /> cuotas
            </p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              {!loading && '$' + (FormatMoney(prod1?.cmfCredit || 0) || '')}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  data-testid="spinner"
                  style={{ width: 20 }}
                />
              )}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
            <TermsTextArea prod={prod1} defaultValue={terms1} />
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-3-box"
        style={{
          height: '100%',
          top: '-4px',
          left: '50%',
        }}>
        <div className="printer-preview-left-content-body-square-3">
          <p className="printer-preview-left-content-body-square-3-title">
            {prod2?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-3-sku">
            {prod2?.Sku || ''}
          </p>
          {prod2?.BasePrice && (
            <div className="printer-preview-left-content-body-square-3-price-wrapper">
              <p
                className="printer-preview-mini-text-square-3"
                style={{ marginTop: '8px' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-square-3-price"
                style={{ fontSize: '60px', marginTop: '40px' }}>
                {prodBasePrice2?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text-square-3"
                style={{ marginTop: '8px' }}>
                {prodBasePrice2?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div className="printer-preview-left-content-footer-square-3">
          <div className="printer-preview-left-content-footer-square-3-quotes">
            <p></p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              36 <br /> cuotas
            </p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              {!loading && '$' + (FormatMoney(prod2?.cmfCredit || 0) || '')}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 20 }}
                />
              )}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
            <TermsTextArea prod={prod2} defaultValue={terms2} />
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-3-box"
        style={{
          height: '50%',
          top: '49%',
        }}>
        <div className="printer-preview-left-content-body-square-3-2">
          <p className="printer-preview-left-content-body-square-3-title-2">
            {prod3?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-3-sku-2">
            {prod3?.Sku || ''}
          </p>
          {prod3?.BasePrice && (
            <div className="printer-preview-left-content-body-square-3-price-wrapper">
              <p
                className="printer-preview-mini-text-square-3"
                style={{ marginTop: '8px' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-square-3-price"
                style={{ fontSize: '60px', marginTop: '40px' }}>
                {prodBasePrice3?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text-square-3"
                style={{ marginTop: '8px' }}>
                {prodBasePrice3?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div className="printer-preview-left-content-footer-square-3-2">
          <div className="printer-preview-left-content-footer-square-3-quotes">
            <p></p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              36 <br /> cuotas
            </p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              {!loading && '$' + (FormatMoney(prod3?.cmfCredit || 0) || '')}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 20 }}
                />
              )}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
            <TermsTextArea prod={prod3} defaultValue={terms3} />
          </div>
        </div>
      </div>
      <div
        className="printer-preview-left-canvas-square-3-box"
        style={{
          height: '50%',
          top: '49%',
          left: '50%',
        }}>
        <div className="printer-preview-left-content-body-square-3-2">
          <p className="printer-preview-left-content-body-square-3-title-2">
            {prod4?.ProductName || ''}
          </p>
          <p className="printer-preview-left-content-body-square-3-sku-2">
            {prod4?.Sku || ''}
          </p>
          {prod4?.BasePrice && (
            <div className="printer-preview-left-content-body-square-3-price-wrapper">
              <p
                className="printer-preview-mini-text-square-3"
                style={{ marginTop: '8px' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-square-3-price"
                style={{ fontSize: '60px', marginTop: '40px' }}>
                {prodBasePrice4?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text-square-3"
                style={{ marginTop: '8px' }}>
                {prodBasePrice4?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          )}
        </div>
        <div className="printer-preview-left-content-footer-square-3-2">
          <div className="printer-preview-left-content-footer-square-3-quotes">
            <p></p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              36 <br /> cuotas
            </p>
            <p className="printer-preview-left-content-footer-square-3-quote-text">
              {!loading && '$' + (FormatMoney(prod4?.cmfCredit || 0) || '')}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="spinner"
                  style={{ width: 20 }}
                />
              )}
            </p>
          </div>
          <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
            <TermsTextArea prod={prod4} defaultValue={terms4} />
          </div>
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.cmf3}
        alt="template-preview"
      />
    </div>
  )
}
