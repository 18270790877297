import { images } from 'Assets/Images'
import React from 'react'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { sizeOfMarginLeft } from './Square2'
import { FormatMoney } from 'Utils/functions'
import { formatStartEndDate } from 'Utils/Helpers/formatDate'
const defTermValue = (startDate, endDate) =>
  `Promoción válida del ${formatStartEndDate(
    startDate,
    endDate
  )}. La tarjeta digital CMF es emitida por CM Financiera, S.A. (CMF). *Valor cuota referencial, aplican condiciones. La responsabilidad de CMF se limita a los aspectos del crédito. Accede a www.cmf.com.pa para más información.`

export const Square2ToPrintNCmfOffer = ({ products, i }) => {
  const { templateRef, terms } = usePrinterContext()

  const prod1 = products?.content?.products?.[0]
  const prod2 = products?.content?.products?.[1] || {}
  const terms1 =
    terms?.find(term => term?.prodId === prod1?.ProductId)?.value ||
    defTermValue(prod1?.StartDate, prod1?.FinishDate)
  const terms2 =
    terms?.find(term => term?.prodId === prod2?.ProductId)?.value ||
    defTermValue(prod2?.StartDate, prod2?.FinishDate)
  const prodBasePrice1 = FormatMoney(prod1?.BasePrice || 0)
  const prodBasePrice2 = FormatMoney(prod2?.BasePrice || 0)
  const prodPrice1 = FormatMoney(prod1?.Price || 0)
  const prodPrice2 = FormatMoney(prod2?.Price || 0)

  return (
    <div ref={el => (templateRef.current[i] = el)}>
      <div
        key={products.page}
        data-testid="printer-preview-left-canvas-2"
        id="printer-preview-left-canvas-square-2"
        className="printer-preview-left-canvas-square-2">
        <div
          className="printer-preview-left-canvas-square-2-box"
          style={{
            height: '100%',
            top: '-10px',
          }}>
          <div
            className="printer-preview-left-content-body-square-2"
            style={{ top: '82px' }}>
            <p
              className="printer-preview-left-content-body-square-2-title"
              style={{
                lineHeight: '16px',
              }}>
              {prod1?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-2-sku">
              {prod1?.Sku || ''}
            </p>
            {prod1?.Price && (
              <div
                className="printer-preview-left-content-body-square-2-price-wrapper"
                style={{
                  marginTop: '12px',
                  marginLeft:
                    sizeOfMarginLeft[
                      prod1?.Price?.toString()?.split('.')?.[0]?.length
                    ],
                  width: '100%',
                  position: 'absolute',
                  top: '52px',
                }}>
                <p className="printer-preview-mini-text-square-2">$</p>
                <p
                  className="printer-preview-left-content-body-square-2-price"
                  style={{ fontSize: '70px', marginTop: '10px' }}>
                  {prodPrice1?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-2">
                  {prodPrice1?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
            {prod1?.BasePrice && (
              <div
                style={{
                  position: 'absolute',
                  top: '98px',
                  right: 0,
                  width: '100px',
                  height: '1px',
                  background: 'red',
                  transform: 'rotate(-12deg)',
                  marginBottom: '4px',
                }}
              />
            )}
            {prod1?.BasePrice && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '2px',
                  position: 'absolute',
                  top: '80px',
                  right: '0',
                }}>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}>
                  Antes
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      marginTop: '-12px',
                    }}>
                    $
                  </p>
                  <p
                    className="printer-preview-left-content-body-price"
                    style={{ color: '#000', fontSize: '28px', marginTop: 0 }}>
                    {prodBasePrice1?.toString()?.split('.')?.[0] || ''}.
                  </p>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      marginTop: '-12px',
                    }}>
                    {prodBasePrice1?.toString()?.split('.')?.[1] || '00'}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-2"
            style={{ top: 'calc(50% - 78px)' }}>
            <div
              className="printer-preview-left-content-footer-square-2-quotes"
              style={{ opacity: prod1?.cmfCredit ? 1 : 0 }}>
              <p></p>
              <p className="printer-preview-left-content-footer-square-2-quote-text">
                36 <br /> cuotas
              </p>
              <p className="printer-preview-left-content-footer-square-2-quote-text">
                ${FormatMoney(prod1?.cmfCredit || 0) || ''}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{ border: 'none', background: 'transparent' }}>
                {terms1}
              </p>
            </div>
          </div>
        </div>
        <div
          className="printer-preview-left-canvas-square-2-box"
          style={{
            height: '50%',
            top: '49%',
          }}>
          <div
            className="printer-preview-left-content-body-square-2-2"
            style={{ top: '74px' }}>
            <p
              className="printer-preview-left-content-body-square-2-title-2"
              style={{
                lineHeight: '16px',
              }}>
              {prod2?.ProductName || ''}
            </p>
            <p className="printer-preview-left-content-body-square-2-sku-2">
              {prod2?.Sku || ''}
            </p>
            {prod2?.Price && (
              <div
                className="printer-preview-left-content-body-square-2-price-wrapper"
                style={{
                  marginTop: '12px',
                  marginLeft:
                    sizeOfMarginLeft[
                      prod2?.Price?.toString()?.split('.')?.[0]?.length
                    ],
                  width: '100%',
                  position: 'absolute',
                  top: '52px',
                }}>
                <p className="printer-preview-mini-text-square-2">$</p>
                <p
                  className="printer-preview-left-content-body-square-2-price-2"
                  style={{ fontSize: '70px', marginTop: '10px' }}>
                  {prodPrice2?.toString()?.split('.')?.[0] || ''}.
                </p>
                <p className="printer-preview-mini-text-square-2">
                  {prodPrice2?.toString()?.split('.')?.[1] || '00'}
                </p>
              </div>
            )}
            {prod2?.BasePrice && (
              <div
                style={{
                  position: 'absolute',
                  top: '98px',
                  right: 0,
                  width: '100px',
                  height: '1px',
                  background: 'red',
                  transform: 'rotate(-12deg)',
                  marginBottom: '4px',
                }}
              />
            )}
            {prod2?.BasePrice && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '2px',
                  position: 'absolute',
                  top: '80px',
                  right: '0',
                }}>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}>
                  Antes
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      marginTop: '-12px',
                    }}>
                    $
                  </p>
                  <p
                    className="printer-preview-left-content-body-price"
                    style={{ color: '#000', fontSize: '28px', marginTop: 0 }}>
                    {prodBasePrice2?.toString()?.split('.')?.[0] || ''}.
                  </p>
                  <p
                    className="printer-preview-mini-text"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      marginTop: '-12px',
                    }}>
                    {prodBasePrice2?.toString()?.split('.')?.[1] || '00'}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="printer-preview-left-content-footer-square-2-2"
            style={{ top: 'calc(100% - 80px)' }}>
            <div
              className="printer-preview-left-content-footer-square-2-quotes"
              style={{ opacity: prod2?.cmfCredit ? 1 : 0 }}>
              <p></p>
              <p className="printer-preview-left-content-footer-square-2-quote-text">
                36 <br /> cuotas
              </p>
              <p className="printer-preview-left-content-footer-square-2-quote-text">
                ${FormatMoney(prod2?.cmfCredit || 0) || ''}
              </p>
            </div>
            <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
              <p
                className="printer-preview-left-content-footer-legal-2"
                style={{ border: 'none', background: 'transparent' }}>
                {terms2}
              </p>
            </div>
          </div>
        </div>
        <img
          className="printer-preview-left-image"
          src={images.printer.templates.novey.cmfOffer2}
          alt="template-preview"
          style={{ opacity: 0 }}
        />
      </div>
    </div>
  )
}
