import React, { useEffect } from 'react'
import { images } from 'Assets/Images'
import { ModalPrinterTemplates } from 'Views/Printer/components/ModalPrinterTemplates'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { useHistory } from 'react-router-dom'
import { useGeneratePrinter } from './hooks/useGeneratePrinter'
import { AddMoreTemplatesButton } from './components/AddMoreTemplatesButton'

export const TemplatesSection = () => {
  const history = useHistory()
  const {
    selectedTemplates,
    selectedTemplateType,
    setAllTemplatesContent,
    allTemplatesContent,
    currentPage,
  } = usePrinterContext()
  const { generateMultiPagePDF, printedUrl } = useGeneratePrinter()

  const [openTemplateModal, setOpenTemplateModal] = React.useState(false)
  const handleClose = () => setOpenTemplateModal(false)
  const handleShow = () => setOpenTemplateModal(true)
  const hasSquareTemplate = selectedTemplates?.some(
    template => template === 'square-1'
  )
  const hasDoubleSquareTemplate = selectedTemplates?.some(
    template => template === 'square-2'
  )
  const hasSquare3Template = selectedTemplates?.some(
    template => template === 'square-3'
  )
  const hasSquare4Template = selectedTemplates?.some(
    template => template === 'square-4'
  )

  const printed = printedUrl !== ''

  const isSpecial = selectedTemplateType?.key === 'SPECIAL_CMF'

  const templates = [
    {
      condition: hasSquareTemplate,
      type: 'square-1',
      image: images.printer.square,
      size: '11" x 8.5"',
      orientation: 'Horizontal',
    },
    {
      condition: hasDoubleSquareTemplate,
      type: 'square-2',
      image: images.printer.doubleSquare,
      size: '8.5" x 5.5"',
      orientation: 'Horizontal',
    },
    {
      condition: hasSquare3Template,
      type: 'square-3',
      image: images.printer.squares,
      size: '4" x 5"',
      orientation: 'Vertical',
      isSpecial,
    },
    {
      condition: hasSquare4Template,
      type: 'square-4',
      image: images.printer.squares,
      size: '5.5" x 8.5"',
      orientation: 'Vertical',
      isSpecial,
    },
  ]

  const visibleTemplates = templates.filter(template => template.condition)
  const lastVisibleIndex = visibleTemplates.length - 1

  const handleSelectTemplate = template => {
    setAllTemplatesContent(prev => [
      ...prev.map(content => {
        if (content.page === currentPage) {
          return {
            ...content,
            content: { products: [] },
            template: [template],
          }
        }
        return content
      }),
    ])
  }

  useEffect(() => {
    if (!selectedTemplateType?.key) {
      history.push('/printer')
    }
    const hasAllTemplatesContent = allTemplatesContent?.find(
      content => content?.page === currentPage
    )?.template?.length
    if (!hasAllTemplatesContent) {
      handleSelectTemplate(selectedTemplates?.[0])
    }
  }, [selectedTemplateType])

  return (
    <>
      <div className="printer-preview-right-templates-wrapper">
        {visibleTemplates.map((template, index) => (
          <div key={template.type} style={{ position: 'relative' }}>
            <button
              disabled={template.isSpecial || printed}
              className="printer-modal-content-option"
              onClick={() => handleSelectTemplate(template.type)}>
              <div
                className="printer-modal-content-image-wrapper"
                style={{
                  ...(allTemplatesContent
                    .find(content => content.page === currentPage)
                    ?.template?.includes(template.type) && {
                    border: '2px solid #022767',
                  }),
                }}>
                <img
                  src={template.image}
                  alt=""
                  className="printer-modal-content-image"
                />
              </div>
              <p className="printer-modal-content-text">
                Frente Góndola
                <br />
                {template.size}
                <br />
                {template.orientation}
              </p>
            </button>
            {/* Mostrar el botón solo si es el último elemento visible */}
            {index === lastVisibleIndex && (
              <AddMoreTemplatesButton handleShow={handleShow} />
            )}
          </div>
        ))}
        <div className="printer-finish-button-wrapper">
          <button
            onClick={() => {
              printed && window.open(printedUrl, '_blank')
              !printed && generateMultiPagePDF()
            }}
            className="printer-finish-button">
            {printed ? 'Ver PDF' : 'Imprimir etiqueta de precio'}
          </button>
        </div>
      </div>
      <ModalPrinterTemplates
        show={openTemplateModal}
        onClose={handleClose}
        withNavigation={false}
      />
    </>
  )
}
