import React from 'react'
import { images } from 'Assets/Images'
import { usePrinterContext } from 'state/context/PrinterTemplatesContext'
import { FormatMoney } from 'Utils/functions'
import { TermsTextArea } from '../../TermsTextArea'
import { formatStartEndDate } from 'Utils/Helpers/formatDate'

const defTermValue = (startDate, endDate) =>
  `Promoción válida del ${formatStartEndDate(
    startDate,
    endDate
  )}. Precio no incluye ITBMS`

export const Square1NOffer = ({ products, i }) => {
  const { terms } = usePrinterContext()

  const prod = products?.content?.products?.[0]

  const terms1 =
    terms?.find(term => term?.prodId === prod?.ProductId)?.value ||
    defTermValue(prod?.StartDate, prod?.FinishDate)
  const prodBasePrice = FormatMoney(prod?.BasePrice || 0)
  const prodPrice = FormatMoney(prod?.Price || 0)

  return (
    <div
      id="printer-preview-left-canvas"
      data-testid="printer-preview-left-canvas"
      className="printer-preview-left-canvas">
      <div className="printer-preview-left-content-body">
        <p className="printer-preview-left-content-body-title">
          {prod?.ProductName || ''}
        </p>
        <p className="printer-preview-left-content-body-sku">
          {prod?.Sku || ''}
        </p>
        {prod?.Price && (
          <div className="printer-preview-left-content-body-price-wrapper">
            <p className="printer-preview-mini-text">$</p>
            <p
              className="printer-preview-left-content-body-price"
              style={{ fontSize: '160px', marginTop: '60px' }}>
              {prodPrice?.toString()?.split('.')?.[0] || ''}.
            </p>
            <p className="printer-preview-mini-text">
              {prodPrice?.toString()?.split('.')?.[1] || '00'}
            </p>
          </div>
        )}
        {prod?.BasePrice && (
          <div
            style={{
              position: 'absolute',
              bottom: '-84px',
              right: 0,
              width: '170px',
              height: '1px',
              background: 'red',
              transform: 'rotate(-6deg)',
            }}
          />
        )}
        {prod?.BasePrice && (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
              position: 'absolute',
              bottom: '-100px',
              right: '0',
            }}>
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                marginBottom: '-5px',
              }}>
              Antes
            </p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <p
                className="printer-preview-mini-text"
                style={{ color: '#000', fontSize: '20px', marginTop: '-12px' }}>
                $
              </p>
              <p
                className="printer-preview-left-content-body-price"
                style={{ color: '#000', fontSize: '45px', marginTop: 0 }}>
                {prodBasePrice?.toString()?.split('.')?.[0] || ''}.
              </p>
              <p
                className="printer-preview-mini-text"
                style={{ color: '#000', fontSize: '20px', marginTop: '-12px' }}>
                {prodBasePrice?.toString()?.split('.')?.[1] || '00'}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="printer-preview-left-content-footer">
        <div className="printer-preview-content-footer-input-wrapper template-terms-wrapper-to-remove">
          <TermsTextArea
            rows={1}
            prod={prod}
            defaultValue={terms1}
            className="printer-preview-left-content-footer-legal-1"
            styles={{ textAlign: 'end' }}
            size={16}
          />
        </div>
      </div>
      <img
        className="printer-preview-left-image"
        src={images.printer.templates.novey.offer1}
        alt="template-preview"
      />
    </div>
  )
}
